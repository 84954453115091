<!-- https://0.0.0.0:8443/#/clients/new -->
<script>
  import { onMount } from "svelte";
  import axios from "axios";
  import liff from "@line/liff";
  import { link, push } from "svelte-spa-router";
  import Button from "./../../components/Input/Button.svelte";
  export let params = {};

  let clients = { name: '', gender: '男', area: '', mobile: '', id: '' };
  let clientLineId = "";
  let logoSrc = "./image/logo.png";
  let newMember = false;
  let message = "";

  $: clients.mobile !== '' && clients.mobile.length > 4  ? addDash() : addDash()

  onMount(async () => {
    clientLineId = getCookie("clientLineId");
    if (clientLineId == null || clientLineId == "") {
      const loginPromise = (async () => {
        let profile = {};
        try {
          await liff.init({
            liffId: "1656736039-RxGp3xE8",
          });
          if (!liff.isLoggedIn()) {
            liff.login({ redirectUri: location.href });
          }
          profile = await liff.getProfile();
          clientLineId = profile.userId; // 取得客戶Line ID

          //取得id想打api看看註冊過了嗎
          axios({
            method: "get",
            url: env.BASE_URL + `api/v1/clients/${clientLineId}`,
          })
            .then(function (res) {
              if (res.data.id == null) {
                newMember = true;
              } else {
                newMember = false;
                clients = res.data;
                cookieSetup("clientLineId", clientLineId);
                axios.defaults.headers.common["Client-Id"] = clientLineId;
                axios.defaults.headers.common["stage"] = "forestage";
              }
            })
            .catch(function (err) {
              console.dir(err);
              // push('/error')
            });
        } catch (err) {
          console.log(err);
        }
        return profile;
      })();
    } else {
      //取得id想打api看看註冊過了嗎
      axios({
        method: "get",
        url: env.BASE_URL + `api/v1/clients/${clientLineId}`,
      })
        .then(function (res) {
          if (res.data.id == null) {
            newMember = true;
          } else {
            newMember = false;
            clients = res.data;
            cookieSetup("clientLineId", clientLineId);
            axios.defaults.headers.common["Client-Id"] = clientLineId;
            axios.defaults.headers.common["stage"] = "forestage";
          }
        })
        .catch(function (err) {
          console.dir(err);
          push('/error')
        });
    }
  });

  function register() {
    if (clients.name.replaceAll(" ", "") == "") {
      alert("姓名為必填！");
    } else if (clients.area.replaceAll(" ", "") == "") {
      alert("居住地區為必填！");
    } else if (clients.mobile.replaceAll(" ", "") == "") {
      alert("手機號碼為必填！");
    } else if (!/09\d{2}-\d{3}\d{3}/.test(clients.mobile)) {
      alert("手機號碼格式不對！");
    } else {
      axios({
        method: "post",
        url: env.BASE_URL + `api/v1/clients/${clientLineId}`,
        data: {
          id: clientLineId,
          name: clients.name.replaceAll(' ',''),
          gender: clients.gender,
          area: clients.area.replaceAll(' ',''),
          mobile: clients.mobile.replaceAll(' ',''),
        },
      })
        .then(function (res) {
          if (res.data.status == "successful") {
            message = res.data.message;
            document.querySelector(".message__con").style.background =
              "#58b957";
            setTimeout(function () {
              document.querySelector(".message").style.display = "flex";
            }, 500);
            setTimeout(function () {
              liff.closeWindow();
            }, 1500);
          } else {
            message = res.data.message;
            document.querySelector(".message__con").style.background =
              "#f66354";
            setTimeout(function () {
              document.querySelector(".message").style.display = "flex";
            }, 500);
            setTimeout(function () {
              document.querySelector(".message").style.display = "none";
            }, 3000);
          }
        })
        .catch(function (err) {
          console.dir(err);
          push('/error')
        });
    }
  }

  function edit() {
    if (clients.name.replaceAll(" ", "") == "") {
      alert("姓名為必填！");
    } else if (clients.area.replaceAll(" ", "") == "") {
      alert("居住地區為必填！");
    } else if (clients.mobile.replaceAll(" ", "") == "") {
      alert("手機號碼為必填！");
    } else if (!/09\d{2}-\d{3}\d{3}/.test(clients.mobile)) {
      alert("手機號碼格式不對！");
    } else {
      axios({
        method: "put",
        url: env.BASE_URL + `api/v1/clients/${clientLineId}`,
        data: {
          id: clientLineId,
          name: clients.name,
          gender: clients.gender,
          area: clients.area,
          mobile: clients.mobile,
        },
      })
        .then(function (res) {
          if (res.data.status == "successful") {
            message = res.data.message;
            axios.defaults.headers.common["Client-Id"] = clientLineId;
            axios.defaults.headers.common["stage"] = "forestage";
            document.querySelector(".message__con").style.background =
              "#58b957";
            document.querySelector('.black').style.display = 'block'
            setTimeout(function () {
              document.querySelector(".message").style.display = "flex";
            }, 500);
            setTimeout(function () {
              liff.closeWindow();
            }, 1500);
          } else {
            message = res.data.message;
            document.querySelector('.black').style.display = 'block'
            document.querySelector(".message__con").style.background =
              "#f66354";
            setTimeout(function () {
              document.querySelector(".message").style.display = "flex";
            }, 500);
          }
        })
        .catch(function (err) {
          console.dir(err);
          push('/error')
        });
    }
  }

  function getCookie(name) {
    var cookieArr = document.cookie.split(";");
    for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");
      if (name == cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
  }

  function cookieSetup(name, value) {
    var expires = new Date();
    expires.setTime(expires.getTime() + 1 * 60 * 60 * 1000);
    document.cookie =
      name + "=" + escape(value) + ";path=/;expires=" + expires.toGMTString();
  }

  function addDash() {
    if (
      clients.mobile.replaceAll(" ", "") !== "" &&
      clients.mobile.replaceAll(" ", "").length > 5
    ) {
      if (clients.mobile.indexOf("-") == -1) {
        let mobile2 = clients.mobile.substring(4, clients.mobile.length);
        clients.mobile =
          clients.mobile.replaceAll(" ", "").substring(0, 4) + "-" + mobile2;
      }
    } else {
      clients.mobile.replaceAll("-", "");
    }
  }

  function closeLightbox(){
      document.querySelector(".message").style.display = "none";
      document.querySelector('.black').style.display = 'none'
  }
</script>

<div class="container">
  <div class="logo">
    <img src={logoSrc} alt="" />
    <p>協禾詠春傳統國術館</p>
  </div>
  <div class="form">
    <div class="form__con">
      <div class="form__item">
        <p><span>*</span> 姓名：</p>
        <input type="text" bind:value={clients.name} />
      </div>
      <div class="form__item form__item--gender">
        <p><span>*</span> 性別：</p>
        <label for="man"
          >男
          <input
            type="radio"
            value="男"
            bind:group={clients.gender}
            id="man"
            name="gender"
          />
        </label>
        &nbsp; &nbsp; &nbsp;
        <label for="woman"
          >女
          <input
            type="radio"
            value="女"
            bind:group={clients.gender}
            id="woman"
            name="gender"
          />
        </label>
      </div>
      <div class="form__item">
        <p><span>*</span> 居住地區：</p>
        <input
          type="text"
          placeholder="新北市新莊區"
          bind:value={clients.area}
        />
      </div>
      <div class="form__item">
        <p><span>*</span> 手機：</p>
        <input
          type="text"
          bind:value={clients.mobile}
          placeholder="0912-345678"
        />
      </div>
    </div>
  </div>

  <div class="btn">
    {#if newMember}
      <Button
        style="margin: 10px 0 10px 0;"
        hoverColor="#58b957"
        props=""
        bgColor="#58b957"
        color="#FFFFFF"
        hoverTextColor="#fff"
        on:click={register}
      >
        註冊
      </Button>
    {:else}
      <Button
        style="margin: 10px 0 10px 0;"
        hoverColor="#58b957"
        props=""
        bgColor="#58b957"
        color="#FFFFFF"
        hoverTextColor="#fff"
        on:click={edit}
      >
        儲存
      </Button>
    {/if}
  </div>
</div>

<div class="message">
  <div class="message__con">
    <img src="./image/close.png" alt="" class="close" on:click="{closeLightbox}">
    <p>{message}</p>
  </div>
</div>

<div class="black" on:click="{closeLightbox}"></div>
   

<style lang="scss">
  @import "./../../components/scss/_reset.scss";
  @import "./../../components/scss/_font.scss";

  ::placeholder {
    color: #aaa;
    font-size: 14px;
  }

  .logo {
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
    img {
      width: 40px;
    }
    p {
      font-size: 22px;
      margin-left: 15px;
      letter-spacing: 1px;
      color: #333;
    }
  }

  .title {
    display: inline-block;
    h2 {
      font-size: 25px;
      font-weight: 500;
      letter-spacing: 1px;
      color: #425271;
      position: relative;
      margin: auto 0 15px 0;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #960e1e;
        bottom: -3px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .form {
    max-width: 600px;
    margin: auto;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 2px 2px 5px #ddd;
    color: #333;
    &__con {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    &__item {
      width: 96%;
      display: flex;
      align-items: center;
      margin: 5px 1%;
      position: relative;
      input[type="text"],
      select {
        width: calc(100% - 100px);
        margin: 10px 0 10px 10px;
      }
      select {
        padding: 10px 0;
      }
      p {
        margin: 0;
        width: 100px;
      }
      span {
        color: #f00;
      }
      textarea {
        resize: none;
        width: calc(100% - 100px);
      }
      &--textarea {
        width: 100%;
        p {
          width: 100px;
        }
      }
      &--serve {
        width: calc(100%);
        > div {
          margin-left: 30px;
          display: inline-flex;
          flex-wrap: wrap;
        }
      }
      &--status {
        width: 96%;
      }
      &--pic {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        img {
          max-width: 200px;
          width: auto;
          height: auto;
          max-height: 270px;
          border-radius: 50%;
          margin: auto;
          border: 8px solid #425271;
        }
      }
      &--email {
        width: 96%;
        p {
          width: 80px;
        }
      }
      &--gender {
        label {
          margin: 0 5px 0 10px;
        }
        input {
          margin-left: 5px;
        }
      }
    }
  }

  .btn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }

  .message {
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: none;
    top: 0;
    left: 0;
    z-index: 101;
    align-items: center;
    justify-content: center;
    &__con {
      width: 90%;
      max-width: 500px;
      height: auto;
      text-align: left;
      padding: 50px;
      word-break: break-all;
      p {
        font-size: 30px;
        font-weight: 900;
        color: #fff;
      }
      .close{
          position: absolute;
          top: 10px;
          right: 10px;
          width: 30px;
          cursor: pointer;
          height: 30px;
          transition: 0.3s;
          &:hover{
              opacity: 0.6;
          }
      }
    }
  }
  .black{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0,0.4);
    z-index: 100;
    display: none;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
</style>
