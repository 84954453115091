<!-- 後台：帳號管理列表 /backstage/users -->
<script>
    import axios from 'axios';
	import { onMount } from 'svelte';
    import { Datatable, ColumnFilterInputs } from 'svelte-simple-datatables'
    import Button from './../../components/Input/Button.svelte';
	import { link, push } from "svelte-spa-router";

    let userLineId = "";
    let userPermission = {
        orders: "",
        business_records: "",
        shifts: "",
        clients: "",
        holidays: "",
        dayoff: "",
        roles: "",
        users: "",
    };
    //表單
    let users = [];
    let rows;
    const settings = { 
        columnFilter: false,  
        rowsPerPage: 20,
        scrollY: false,
        sortable: true,
        css: true,
        blocks: {
            searchInput: false, 
            paginationButtons: true,
            paginationRowCount: false,
        },
        labels: {
            previous: '上一頁',
            next: '下一頁',       
        } 
    }
    

	onMount(async () => {
        userLineId = getCookie("userLineId");
        if( userLineId == null || userLineId == '' ){
            push('/backstage/orders/calendar')
        }else{
            if(getCookie("userPermission") !== null && getCookie("userPermission") !== ''){
                userPermission = JSON.parse(getCookie("userPermission"));
                if(userPermission.users == 0){
                    push('/backstage/orders/calendar')  
                }
                
                //帳號列表
                await axios({
                    method: 'get',
                    url: env.BASE_URL + 'api/v1/users',
                    }).then(function (res) {
                        users = res.data;
                        //拿到資料可以讓我再塞class，但必須要如果是0也要吐資料
                        // console.log(res);
                    }).catch(function (err) {
                        console.dir(err);
                        // push('/error')
                    }
                );

                //調整table樣式
                    window.setTimeout(function(){
                    document.querySelector('.dt-pagination-buttons').style.margin = '0px';
                    document.querySelector('.dt-pagination').style.paddingTop = '20px';
                    document.querySelector('.dt-table > table').style.marginBottom = '20px';
                },200)
                //調整table樣式 end
            }else{
                push('/backstage/orders/calendar')  
            }
        }
    });

    function getCookie(name) {
		var cookieArr = document.cookie.split(";");
		for(var i = 0; i < cookieArr.length; i++) {
			var cookiePair = cookieArr[i].split("=");
			if(name == cookiePair[0].trim()) {
				return decodeURIComponent(cookiePair[1]);
			}
		}
		return null;
	}

    function changeStatus(id) {
        if (userPermission !== null && userPermission.users == 2){
            if(getCookie("userRole") == 'admin'){
                axios({
                    method: 'patch', 
                    url: env.BASE_URL + `api/v1/users/${id}/toggle`,
                    }).then(function (res) {
                        axios({
                            method: 'get',
                            url: env.BASE_URL + 'api/v1/users',
                            }).then(function (res) {
                                users = res.data;
                            }).catch(function (err) {
                                console.dir(err);
                                // push('/error')
                            }
                        );
                    }).catch(function (err) {
                        console.dir(err);
                        // push('/error')
                    }
                );
            }
        }
	}
</script>

<div class="title">
    <h2>帳號管理</h2>
</div>

{#if userPermission !== null && userPermission.users == 2}
    <div class="btn">
        <div class="btn__con">
            <div class="btn__item">
                <a use:link={{href: `/backstage/users/new/`, disabled: false}}>
                    <Button
                        style="padding: 10px; margin: 0"
                        hoverColor="#58b957"
                        props=''
                        bgColor = '#58b957'
                        color= "#fff"
                        hoverTextColor="#fff"
                        >
                        新增帳號
                    </Button>
                </a>
            </div>
        </div>
    </div>
{/if}

<div class="table">
    <div class="table__con">
        <div class="table__item">
            <Datatable settings={settings} data={users} bind:dataRows={rows}>
                <thead>
                    <th class="sortable asc desc" data-key="name">姓名<span/></th>
                    <th class="sortable asc desc" data-key="role">角色<span/></th>
                    <th class="sortable asc desc" data-key="day">報到日期<span/></th>
                    <th class="sortable asc desc" data-key="">銀行帳戶<span/></th>
                    <th class="sortable asc desc" data-key="">備註<span/></th>
                    <th class="sortable asc desc" data-key="">狀態</th>
                </thead>
                <tbody>
                {#if rows}
                    {#each $rows as row}
                    <tr>
                        <td>
                            <a use:link={{href: `/backstage/users/${row.id}`, disabled: false}}>
                                {row.name}
                            </a>
                        </td>
                        <td>
                            <a use:link={{href: `/backstage/users/${row.id}`, disabled: false}}>
                                {row.role_name}
                            </a>
                        </td>
                        <td>
                            <a use:link={{href: `/backstage/users/${row.id}`, disabled: false}}>
                                {row.registered_at}
                            </a>
                        </td>
                        <td> 
                            <a use:link={{href: `/backstage/users/${row.id}`, disabled: false}}>
                                {row.bank_account}
                            </a>
                        </td>
                        <td> 
                            <a use:link={{href: `/backstage/users/${row.id}`, disabled: false}}>
                                {row.note}
                            </a>
                        </td>
                        <td>
                            {#if row.active == "True"}
                            <Button
                                style="padding: 10px;"
                                hoverColor="#58b957"
                                props= ''
                                bgColor = '#58b957'
                                color= "#FFFFFF"
                                hoverTextColor="#fff"
                                on:click={changeStatus(row.id)}
                                >
                                啟用
                            </Button>
                            {:else}
                            <Button
                                style="padding: 10px;"
                                hoverColor="#f66354"
                                props=''
                                bgColor = '#f66354'
                                color= "#FFFFFF"
                                hoverTextColor="#fff"
                                on:click={changeStatus(row.id)}   
                                >
                                停用
                            </Button>
                            {/if}
                        </td>
                    </tr>
                    {/each}
                {/if}
                </tbody>
            </Datatable>
        </div>
    </div>
</div> 
 

<style lang="scss">
	@import './../../components/scss/_font.scss';
	@import './../../components/scss/_reset.scss';
    @import './../../components/scss/_datatablesCss.scss';

    .title{
        display: inline-block;
        padding: 0.5rem 0.5rem;
        h2{
            font-size: 25px;
            font-weight: 500;
            letter-spacing: 1px;
            color: #425271;
            position: relative;
            margin: auto 0 15px 0;
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: #960E1E;
                bottom: -3px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    .btn{
        padding: 0.5rem;
        width: calc(100% - 130px);
        display: inline-flex;
        justify-content: flex-end;
        &__item{
            width: 100%;
            display: flex;
            margin: 20px 0;
            > div{
                display: flex;
                flex-wrap: wrap;
            }
            > p{
               white-space: nowrap;
            }
        }
    }

    //表單
    .table{
        padding: 0;
    }
    table, th, td {
        border-collapse: collapse;
        border-bottom: 1px solid #ece9e9;
    }
    th {
        padding: 20px 10px 20px 10px;
        text-align: center;
        border-bottom: 1px solid #b9b3b3;
        background: #fff;
    }
    th.sortable {
        cursor: pointer;
    }
    th.sortable span {
        padding-right: 16px;
        position: relative;
    }
    th.sortable span:before,
    th.sortable span:after {
        border: 4px solid transparent;
        content: '';
        display: block;
        height: 0;
        right: 0;
        top: 50%;
        position: absolute;
        width: 0;
    }
    th.sortable span:before {
        border-bottom-color: #e0e0e0;
        margin-top: -9px;
    }
    th.sortable span:after {
        border-top-color: #e0e0e0;
        margin-top: 1px;
    }
    th.sortable.asc:not(.desc) span:before {
        border-bottom-color: #9e9e9e;
    }
    th.sortable.desc:not(.asc) span:after {
        border-top-color: #9e9e9e;
    }

    td{text-align:center;padding:4px 0; word-break: break-all; }

    td a{
        color: #000;
        transition: 0.3s;
        display: inline-flex;
        width: 100%;
        height: 100%;
        line-height: 70px;
        justify-content: center;
        &:hover{
            color: rgb(143, 142, 142);
        }
    }
</style>