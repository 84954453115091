<!-- 營業紀錄月曆 /backstage/orders/revenue -->
<script>
  import FullCalendar from "svelte-fullcalendar";
  import dayGridPlugin from "@fullcalendar/daygrid";
  import timeGridPlugin from "@fullcalendar/timegrid";
  import interactionPlugin from "@fullcalendar/interaction";
  import Button from "./../../components/Input/Button.svelte";
  import { onMount } from "svelte";
  import axios from "axios";
  import {
    chooseCalenderDate_r,
    chooseCalenderMaster_r,
    chooseCalenderStore_r,
  } from "./../../components/store";
  import { link, push } from "svelte-spa-router";
  import { dayjs } from "svelte-time";

  let userLineId;
  let userPermission = {
    orders: "",
    business_records: "",
    shifts: "",
    clients: "",
    holidays: "",
    dayoff: "",
    roles: "",
    users: "",
  };

  // 分店
  let storeItems = [];
  let storeSelect = [];
  let storeSelectAll = false;

  // 師傅
  let masterItems = [];
  let masterSelect = [];
  let masterSelectAll = false;

  let eventJson = [];
  let chooseYear = [];
  let chooseMonth = [];
  let startStr = "";
  let gross_revenue = 0;
  let gross_balance = 0;

  onMount(async () => {
    userLineId = getCookie("userLineId");
    if( userLineId == null || userLineId == '' ){
        push('/backstage/orders/calendar')
    }else{
        if(getCookie("userPermission") !== null && getCookie("userPermission") !== ''){
            userPermission = JSON.parse(getCookie("userPermission"));
            if(userPermission.business_records == 0){
                push('/backstage/orders/calendar')  
            }
        }else{
            push('/backstage/orders/calendar')  
        }
    }

    // 初始化年度為當年
    let date = new Date();
    chooseYear = `${date.getFullYear().toString()}-0${(
      date.getMonth() + 1
    ).toString()}`;
    chooseMonth = `0${(date.getMonth() + 1).toString()}`;
    masterSelectAll = true;
    document.querySelector("#masterSelectAll").checked = masterSelectAll;
    storeSelectAll = true;
    document.querySelector("#storeSelectAll").checked = storeSelectAll;
  });

  function getCookie(name) {
    var cookieArr = document.cookie.split(";");
    for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");
      if (name == cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
  }

  function search() {
    chooseCalenderMaster_r.set(masterSelect);
    chooseCalenderStore_r.set(storeSelect);
    options = {
      ...options,
      events: "",
    };
    if (masterSelect.length !== 0) {
      if (storeSelect.length !== 0) {
        axios({
          method: "get",
          url: env.BASE_URL + `api/v1/orders/revenue/login_user/${userLineId}`,
          params: {
            y: chooseYear,
            m: chooseMonth,
            u: masterSelect.join(","),
            b: storeSelect.join(","),
          },
        })
          .then(function (res) {
            // console.log(res)
            for (let d = 0; d < res.data.events.length; d++) {
              res.data.events[d].title = `${separator(
                res.data.events[d].title
              )}元`;
              res.data.events[d].textColor = "#3E8BCC";
              res.data.events[d].color = "transparent";
              res.data.events[d].backgroundColor = "transparent";
            }
            gross_revenue = res.data.gross_revenue;
            gross_balance = res.data.gross_balance;
            options = {
              ...options,
              events: res.data.events,
            };
          })
          .catch(function (err) {
            console.dir(err);
            // push('/error')
          });
      } else {
        alert("至少選擇一分店！");
      }
    } else {
      alert("至少選擇一師傅！");
    }
  }

  let options = {
    initialView: "dayGridMonth",
    plugins: [dayGridPlugin, interactionPlugin],

    eventClick: function (info) {
      chooseCalenderDate_r.set(info.event.start);
      push("/backstage/revenue");
    },

    events: eventJson,

    // 讓文字斷行
    eventContent: function (arg) {
      return {
        html: arg.event.title.replace(/\n/g, "<br>"),
      };
    },

    // 日曆換月觸發
    datesSet: (event) => {
      options = {
        ...options,
        events: "",
      };
      chooseYear = dayjs(event.view.currentStart).format("YYYY");
      chooseMonth = dayjs(event.view.currentStart).format("MM");

      const promises = [
        //師傅列表
        axios({
          method: "get",
          url: env.BASE_URL + "api/v1/users",
        })
          .then(function (res) {
            masterItems = res.data;
            masterSelect = [];
            if (getCookie("userRole") == "admin") {
              for (let u = 0; u < masterItems.length; u++) {
                masterSelect.push(masterItems[u].id);
              }
            } else if (getCookie("userRole") == "therapist") {
              for (let u = 0; u < masterItems.length; u++) {
                if (masterItems[u].id == getCookie("userLineId")) {
                  masterSelect.push(masterItems[u].id);
                }
              }
            }
            // console.log( masterSelect.join(','));
          })
          .catch(function (err) {
            console.dir(err);
            // push('/error')
          }),
        //店家列表
        axios({
          method: "get",
          url: env.BASE_URL + "api/v1/branches",
        })
          .then(function (res) {
            storeItems = res.data;
            storeSelect = [];
            for (let s = 0; s < storeItems.length; s++) {
              storeSelect.push(storeItems[s].branch_id);
            }
            // console.log(res);
          })
          .catch(function (err) {
            console.dir(err);
            // push('/error')
          }),
      ];
      const result = Promise.all(promises);
      result
        .then((results) => {
          axios({
            method: "get",
            url: env.BASE_URL + `api/v1/orders/revenue/login_user/${userLineId}`,
            params: {
              y: dayjs(event.view.currentStart).format("YYYY"),
              m: dayjs(event.view.currentStart).format("MM"),
              u: masterSelect.join(","),
              b: storeSelect.join(","),
            },
          })
            .then(function (res) {
              // console.log(res)
              for (let d = 0; d < res.data.events.length; d++) {
                res.data.events[d].title = `${separator(
                  res.data.events[d].title
                )}元`;
                res.data.events[d].textColor = "#3E8BCC";
                res.data.events[d].color = "transparent";
                res.data.events[d].backgroundColor = "transparent";
              }
              options = {
                ...options,
                events: res.data.events,
              };
              gross_revenue = res.data.gross_revenue;
              gross_balance = res.data.gross_balance;
            })
            .catch(function (err) {
              console.dir(err);
              // push('/error')
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  };

  function separator(numb) {
    var str = numb.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return str.join(".");
  }

  function storeSelectAllClick(event) {
    if (!storeSelectAll) {
      storeSelect = [];
      for (let s = 0; s < storeItems.length; s++) {
        storeSelect.push(storeItems[s].branch_id);
      }
    } else {
      storeSelect = [];
    }
    // console.log(storeSelect)
    storeSelectAll = !storeSelectAll;
    setTimeout(() => (event.target.checked = storeSelectAll), 0);
  }

  function masterSelectAllClick(event) {
    if (!masterSelectAll) {
      masterSelect = [];
      if (getCookie("userRole") == "admin") {
        for (let u = 0; u < masterItems.length; u++) {
          masterSelect.push(masterItems[u].id);
        }
      } else if (getCookie("userRole") == "therapist") {
        for (let u = 0; u < masterItems.length; u++) {
          if (masterItems[u].id == getCookie("userLineId")) {
            masterSelect.push(masterItems[u].id);
          }
        }
      }
    } else {
      masterSelect = [];
    }
    masterSelectAll = !masterSelectAll;
    setTimeout(() => (event.target.checked = masterSelectAll), 0);
  }

  function masterSelectchange() {
    setTimeout(function () {
      if (masterSelect.length == masterItems.length) {
        masterSelectAll = true;
        document.querySelector("#masterSelectAll").checked = masterSelectAll;
      } else {
        masterSelectAll = false;
        document.querySelector("#masterSelectAll").checked = masterSelectAll;
      }
    }, 0);
  }

  function storeSelectchange() {
    setTimeout(function () {
      if (storeSelect.length == storeItems.length) {
        storeSelectAll = true;
        document.querySelector("#storeSelectAll").checked = storeSelectAll;
      } else {
        storeSelectAll = false;
        document.querySelector("#storeSelectAll").checked = storeSelectAll;
      }
    }, 0);
  }
</script>

<div class="title">
  <h2>營業紀錄月曆</h2>
</div>

<div class="search">
  <div class="search__con">
    <div class="search__item search--store">
      <p>分店：</p>
      <div>
        <div>
          <input
            type="checkbox"
            name="storeSelectAll"
            on:click={storeSelectAllClick}
            id="storeSelectAll"
          /><label for="storeSelectAll">&nbsp;全選</label
          >&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        {#each storeItems as storeItem, i}
          <div>
            <input
              type="checkbox"
              name="shop"
              value={storeItem.branch_id}
              id={storeItem.branch}
              bind:group={storeSelect}
            /><label for={storeItem.branch} on:click={storeSelectchange}
              >&nbsp;{storeItem.branch}</label
            >&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        {/each}
      </div>
    </div>
    <div class="search__item search--people">
      <p>師傅：</p>
      <div>
        <div>
          <input
            type="checkbox"
            name="masterSelectAll"
            on:click={masterSelectAllClick}
            id="masterSelectAll"
          /><label for="masterSelectAll">&nbsp;全選</label
          >&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        {#if getCookie("userRole") == "admin"}
          {#each masterItems as masterItem, i}
            <div>
              <input
                type="checkbox"
                name="master"
                value={masterItem.id}
                id={masterItem.name}
                bind:group={masterSelect}
                on:click={masterSelectchange}
              /><label for={masterItem.name}>&nbsp;{masterItem.name}</label
              >&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          {/each}
        {:else if getCookie("userRole") == "therapist"}
          {#each masterItems as masterItem, i}
            {#if masterItem.id == getCookie("userLineId")}
              <div>
                <input
                  type="checkbox"
                  name="master"
                  value={masterItem.id}
                  id={masterItem.name}
                  bind:group={masterSelect}
                  on:click={masterSelectchange}
                /><label for={masterItem.name}>&nbsp;{masterItem.name}</label
                >&nbsp;&nbsp;&nbsp;&nbsp;
              </div>
            {/if}
          {/each}
        {/if}
      </div>
    </div>
    <div class="search__item search--button">
      <Button
        color="#fff"
        style="margin: 10px 0px;"
        hoverColor="#7386ed"
        props=""
        bgColor="#7386ed"
        hoverTextColor="#fff"
        on:click={search}
      >
        查詢
      </Button>
    </div>
    <hr />
    <div class="search__item search__item--revenue">
      <div>
        <div>
          <p>完成未付清合計：<span>{separator(gross_balance)}</span></p>
        </div>
        <div><p>總計：<span>{separator(gross_revenue)}</span></p></div>
      </div>
    </div>
    <!-- <hr> -->
  </div>
</div>

<FullCalendar bind:options />

<style lang="scss">
  @import "./../../components/scss/_font.scss";
  @import "./../../components/scss/_reset.scss";

  .title {
    display: inline-block;
    padding: 0.5rem;
    h2 {
      font-size: 25px;
      font-weight: 500;
      letter-spacing: 1px;
      color: #425271;
      position: relative;
      margin: auto 0 15px 0;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #960e1e;
        bottom: -3px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .search {
    padding: 0.5rem;
    &__item {
      width: 100%;
      display: flex;
      margin: 20px 0;
      > div {
        display: flex;
        flex-wrap: wrap;
        > div {
          margin: 5px 0;
          display: flex;
          align-items: center;
        }
      }
      > p {
        white-space: nowrap;
        margin: 5px 0;
      }
      &--revenue {
        display: flex;
        justify-content: flex-end;
        div {
          font-size: 20px;
          p {
            margin-left: 20px;
            span {
              color: #960e1e;
            }
          }
        }
      }
    }
    &--button {
      display: flex;
      justify-content: flex-end;
    }
  }
</style>
