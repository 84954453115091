import { writable, readable } from "svelte/store";

//orders 預約管理月曆
export const chooseCalenderDate_o = writable("");
export const chooseCalenderMaster_o = writable("");
export const chooseCalenderStore_o = writable("");

//revenue 營業紀錄月曆
export const chooseCalenderDate_r = writable("");
export const chooseCalenderMaster_r = writable("");
export const chooseCalenderStore_r = writable("");

export const personImg = writable("");
