<script>
    import { onMount,afterUpdate,tick } from 'svelte';
    import axios from 'axios';
    import Button from './../Input/Button.svelte';
    import { createEventDispatcher } from "svelte";
    const dispatch = createEventDispatcher()
    
    export let params = {}
    export let message = {}

    
    function clickBlack(){
        dispatch('close')
    }
    </script>
    
    <div class="lightbox lightbox--messageSuccess">
        <div class="lightbox__con">
            <!-- <img src="./image/close.png" alt="" class="close" on:click="{clickBlack}"> -->
            <div class="lightbox__item">
               <p>{message}</p>
            </div>
        </div>
    </div>
    
    
    <style lang="scss">
        
        @import './../../components/scss/_font.scss';
        @import './../../components/scss/_reset.scss';
    
        .lightbox{
            &--messageSuccess{
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                z-index: 111;
                display: none;
                position: fixed;
            }
            &__con{
                background-color: #58b957;
                padding: 50px 50px 50px;
                display: inline-flex;
                align-items: center;
                justify-content: flex-end;
                flex-wrap: wrap;
            }
            &__item{
                width: calc(100%);
                margin: 10px;
                input{
                    width: 100%;
                    padding: 5px 10px;
                    margin: 5px 0 0;
                    line-height: 30px;
                }
                p{
                    color: #fff;
                    font-size: 30px;
                    font-weight: 600;
                }
                &--button{
                    margin-top: 10px;
                    text-align: right;
                }
            }
            .close{
                position: absolute;
                top: 10px;
                right: 10px;
                width: 30px;
                cursor: pointer;
                transition: 0.3s;
                &:hover{
                    opacity: 0.6;
                }
            }
        }
    
    </style>