<script>
import { onMount,afterUpdate,tick } from 'svelte';
import axios from 'axios';
import Button from './../Input/Button.svelte';
import { createEventDispatcher } from "svelte";
const dispatch = createEventDispatcher()

export let params = {}
export let btnParams;

//只要 btnParams值有變化就會觸發到這的判斷式
$ : btnParams ? init() : init()

let addRole =
    {
        name:'',
        note:'',
    }


    function save(){
        if(addRole.note.replaceAll(' ','') !== ''){
            if(btnParams !== 0){
                axios({
                    method: 'put',
                    url: env.BASE_URL + `api/v1/roles/${btnParams}`,
                    data:{
                        name: addRole.name,
                        note: addRole.note,
                    }
                    }).then(function (res) {
                        //成功再回傳
                        //if(res)
                        dispatch('save')
                        // console.log(res);
                    }).catch(function (err) {
                        // console.dir(err);
                    }
            
                );
            }else{
                axios({
                    method: 'post',
                    url: env.BASE_URL + `api/v1/roles/`,
                    data:{
                        name: addRole.name,
                        note: addRole.note,
                    }

                    }).then(function (res) {
                        dispatch('save')
                        // console.log(res);
                    }).catch(function (err) {
                        console.dir(err);
                    }
                );
            }
        }else{
            alert('角色英文名稱為必填！')
        }
    }
 

    function init(){
        if(btnParams !== 0){
            // console.log(btnParams)
             axios({
                method: 'get',
                url: env.BASE_URL + `api/v1/roles/${btnParams}`,
                }).then(function (res) {
                    addRole = res.data;
                    addRole = addRole[0]
                }).catch(function (err) {
                    // console.dir(err);
                }
            );
        }else{
            addRole.name = '';
            addRole.note = '';
        }
    }

    function clickBlack(){
        dispatch('close')
    }
</script>

<div class="lightbox lightbox--member">
    <div class="lightbox__con">
        <img src="./image/close.png" alt="" class="close" on:click="{clickBlack}">
        <div class="lightbox__item">
            名稱：<br><input type="text" name="name" bind:value="{addRole.name}" placeholder="">
        </div>
        <div class="lightbox__item">
            <span>*</span> 備註（角色英文名稱）：<br><input type="text" name="note" bind:value="{addRole.note}" placeholder="">
        </div>
        <div class="lightbox__item lightbox__item--button">
            <Button
                style="padding: 10px; margin: 0"
                hoverColor="#44abf1"
                props=''
                color= "#fff"
                bgColor = '#44abf1'
                hoverTextColor="#fff"
                on:click={save}   
                >
                儲存
            </Button>
        </div>
    </div>
</div>


<style lang="scss">
	
	@import './../../components/scss/_font.scss';
	@import './../../components/scss/_reset.scss';

    .lightbox{
        &--member{
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: 111;
            display: none;
            position: fixed;
        }
        &__con{
            width: 700px;
            background-color: #fff;
            padding: 50px 30px 30px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-wrap: wrap;
        }
        &__item{
            width: calc(50% - 20px);
            margin: 10px;
            input{
                width: 100%;
                padding: 5px 10px;
                margin: 5px 0 0;
                line-height: 30px;
            }
            span{
                color: #f00;
            }
            &--button{
                margin-top: 10px;
                text-align: right;
            }
        }
        .close{
            position: absolute;
            top: 10px;
            right: 10px;
            width: 30px;
            cursor: pointer;
            transition: 0.3s;
            &:hover{
                opacity: 0.6;
            }
        }
    }

</style>