<!-- 後台：角色管理成員明細  /backstage/roles/<role_id>/members -->

    <script>
        import axios from 'axios';
        import { onMount } from 'svelte'; 
        import { link, push } from "svelte-spa-router";

        //透過params取得參數
        export let params = {};
        let people = [];
        let userLineId = "";
        let userPermission = {
            orders: "",
            business_records: "",
            shifts: "",
            clients: "",
            holidays: "",
            dayoff: "",
            roles: "",
            users: "",
        };
        
    
        onMount(async () => {
            userLineId = getCookie("userLineId");
            if( userLineId == null || userLineId == '' ){
                push('/backstage/orders/calendar')
            }else{
                if(getCookie("userPermission") !== null && getCookie("userPermission") !== ''){
                    userPermission = JSON.parse(getCookie("userPermission"));
                    if(userPermission.roles == 0){
                        push('/backstage/orders/calendar')  
                    }
                }else{
                    push('/backstage/orders/calendar')  
                }
            }

            //店家列表
            await axios({
                method: 'get',
                url: env.BASE_URL + `api/v1/roles/${params.role_id}/members`,
                // headers:{},
                }).then(function (res) {
                    people = res.data;
                    // console.log(res);
                }).catch(function (err) {
                    console.dir(err);
                    // push('/error')
                }
            );
        });
    
        function getCookie(name) {
		var cookieArr = document.cookie.split(";");
		for(var i = 0; i < cookieArr.length; i++) {
			var cookiePair = cookieArr[i].split("=");
			if(name == cookiePair[0].trim()) {
				return decodeURIComponent(cookiePair[1]);
			}
		}
		return null;
	}


    
    </script>

    <div class="title">
        <h2>查看成員</h2>
    </div>

    <div class="table">
        <div class="table__con">
            <div class="table__item">
                <thead>
                    <th>編號</th>
                    <th>姓名</th>
                </thead>
                <tbody>
                {#if people}
                    {#each people as person,i}
                    <tr>
                        <td>{i+1}</td>
                        <td>{person.name}</td>
                    </tr>
                    {/each}
                {/if}
                </tbody>
            </div>
        </div>
    </div> 
    
    
    <style lang="scss">
        @import './../../components/scss/_font.scss';
        @import './../../components/scss/_reset.scss';

        
        .title{
            display: inline-block;
            h2{
                font-size: 25px;
                font-weight: 500;
                letter-spacing: 1px;
                color: #425271;
                position: relative;
                margin: auto 0 15px 0;
                &::after{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background-color: #960E1E;
                    bottom: -3px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    
        //表單
        table{
            max-width: 1200px;
            margin: auto;
            width: 100%;
        }
        th {
            padding: 20px 10px 20px 10px;
            text-align: center;
            border-bottom: 1px solid #b9b3b3;
            background: #fff;
            width: 50%;
        }
        table, th, td {
            border-collapse: collapse;
            border-bottom: 1px solid #ece9e9;
        }
        thead,tbody{
            display: flex;
            flex-wrap: wrap;
        }
        th.sortable {
            cursor: pointer;
        }
        th.sortable span {
            padding-right: 16px;
            position: relative;
        }
        th.sortable span:before,
        th.sortable span:after {
            border: 4px solid transparent;
            content: '';
            display: block;
            height: 0;
            right: 0;
            top: 50%;
            position: absolute;
            width: 0;
        }
        th.sortable span:before {
            border-bottom-color: #e0e0e0;
            margin-top: -9px;
        }
        th.sortable span:after {
            border-top-color: #e0e0e0;
            margin-top: 1px;
        }
        th.sortable.asc:not(.desc) span:before {
            border-bottom-color: #9e9e9e;
        }
        th.sortable.desc:not(.asc) span:after {
            border-top-color: #9e9e9e;
        }
    
        td{
            text-align:center;
            padding: 20px 10px 20px 10px;
            word-break: break-all;
            width: 50%; 
        }

        tr{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
        }
    
    </style>