<script>
  import axios from 'axios'
  import { link, push } from "svelte-spa-router";
  import { onMount } from "svelte";
  import liff from "@line/liff";
  import Button from './../components/Input/Button.svelte';
  export let params = {}

  let userLineId = '';

  function loginBtn() {
    var cookies = document.cookie.split(";");
		for (var i = 0; i < cookies.length; i++) {
			var cookie = cookies[i];
			var eqPos = cookie.indexOf("=");
			var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
		}
	axios.defaults.headers.common["User-Id"] = '';
    push('/backstage/orders/calendar')
  }


</script>

<div>
	<h1>您並無權限瀏覽此頁面</h1>
	<Button
		style="margin: 10px 0 10px 0;"
		hoverColor="#58b957"
		props=''
		bgColor = '#58b957'
		color= "#fff"
		hoverTextColor="#fff"
		on:click={loginBtn}   
		>
		登入
	</Button>
</div>

<style>
  h1 {
    color: #000;
    text-transform: uppercase;
    font-size: 4em;
    font-weight: 100;
	width: 100%;
	display: block;
	margin: 0;
	padding: 0;
  }
</style>
