<script>
    import { link, push } from "svelte-spa-router";
    import axios from "axios";
    import { onMount } from "svelte";
    import { Datatable, ColumnFilterInputs } from "svelte-simple-datatables";
    import Button from "./../../components/Input/Button.svelte";
    import { dayjs } from "svelte-time";
    import MessageError from "./../../components/Lightbox/_messageError.svelte";
    import MessageSuccess from "./../../components/Lightbox/_messageSuccess.svelte";
    import Black from "./../../components/Lightbox/_black.svelte";

    export let params = {};
    let clients = {
        name: "",
        gender: "",
        area: "",
        mobile: "",
        email: "",
        created_at: "",
        active: "",
        note: "",
    };
    let userRole;
    let userPermission = {
        orders: "",
        business_records: "",
        shifts: "",
        clients: "",
        holidays: "",
        dayoff: "",
        roles: "",
        users: "",
    };
    let userLineId;

    let orders = [];
    let message = "";

    $: clients.mobile !== "" && clients.mobile.length > 4
        ? addDash()
        : addDash();

    onMount(async () => {
        userLineId = getCookie("userLineId");
        if (userLineId == null || userLineId == "") {
            push("/backstage/orders/calendar");
        } else {
            if (
                getCookie("userPermission") !== null &&
                getCookie("userPermission") !== ""
            ) {
                userPermission = JSON.parse(getCookie("userPermission"));
                if (userPermission.clients == 0) {
                    push("/backstage/orders/calendar");
                }
            } else {
                push("/backstage/orders/calendar");
            }
        }

        //帳號列表
        await axios({
            method: "get",
            url: env.BASE_URL + `api/v1/clients/${params.client_id}`,
        })
            .then(function (res) {
                clients = res.data;
                // console.log(res);
            })
            .catch(function (err) {
                // console.dir(err);
            });

        await axios({
            method: "get",
            url: env.BASE_URL + `api/v1/orders/?c=${params.client_id}`,
        })
            .then(function (res) {
                // orders = res.data.results
                // console.log(res);
                orders = Object.keys(res.data.results).map(function (_) {
                    return res.data.results[_];
                });
                // console.log(orders)
            })
            .catch(function (err) {
                // console.dir(err);
            });

        userRole = getCookie("userRole");

        //調整table樣式
        window.setTimeout(function () {
            document.querySelector(".dt-pagination-buttons").style.margin =
                "0px";
            document.querySelector(".dt-pagination").style.paddingTop = "20px";
            document.querySelector(".dt-table > table").style.marginBottom =
                "20px";
        }, 200);
        //調整table樣式 end
    });

    //表單
    let rows;
    const settings = {
        columnFilter: false,
        rowsPerPage: 20,
        scrollY: false,
        sortable: true,
        css: true,
        blocks: {
            searchInput: false,
            paginationButtons: true,
            paginationRowCount: false,
        },
        labels: {
            previous: "上一頁",
            next: "下一頁",
        },
    };

    function getCookie(name) {
        var cookieArr = document.cookie.split(";");
        for (var i = 0; i < cookieArr.length; i++) {
            var cookiePair = cookieArr[i].split("=");
            if (name == cookiePair[0].trim()) {
                return decodeURIComponent(cookiePair[1]);
            }
        }
        return null;
    }

    function changeStatus(id) {
        if (userPermission !== null && userPermission.orders == 2) {
            axios({
                method: "patch",
                url: env.BASE_URL + `api/v1/clients/${id}/toggle`,
            })
                .then(function (res) {
                    // console.log(res);
                    axios({
                        method: "get",
                        url:
                            env.BASE_URL + `api/v1/clients/${params.client_id}`,
                    })
                        .then(function (res) {
                            clients = res.data;
                            // console.log(res);
                        })
                        .catch(function (err) {
                            // console.dir(err);
                        });
                })
                .catch(function (err) {
                    // console.dir(err);
                });
        }
    }

    function save() {
        if (clients.name.replaceAll(" ", "") == "") {
            alert("姓名為必填！");
        } else if (clients.area.replaceAll(" ", "") == "") {
            alert("居住地區為必填！");
        } else if (clients.mobile.replaceAll(" ", "") == "") {
            alert("手機號碼為必填！");
        } else if (!/09\d{2}-\d{3}\d{3}/.test(clients.mobile)) {
            alert("手機號碼格式不對！");
        } else {
            axios({
                method: "put",
                url: env.BASE_URL + `api/v1/clients/${params.client_id}`,
                data: {
                    name: clients.name.replaceAll(" ", ""),
                    gender: clients.gender,
                    area: clients.area.replaceAll(" ", ""),
                    mobile: clients.mobile.replaceAll(" ", ""),
                    note: clients.note.replaceAll(" ", ""),
                },
            })
                .then(function (res) {
                    if (res.data.status == "successful") {
                        message = "修改成功！";
                        success();
                    } else {
                        message = res.data.message;
                        fail();
                    }
                })
                .catch(function (err) {
                    // console.dir(err);
                });
        }
    }

    function separator(numb) {
        var str = numb.toString().split(".");
        str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return str.join(".");
    }

    function success() {
        document.querySelector(".black").style.display = "block";
        document.querySelector(".lightbox--messageSuccess").style.display =
            "inline-block";
        setTimeout(function () {
            document.querySelector(".lightbox--messageSuccess").style.display =
                "none";
            document.querySelector(".black").style.display = "none";
            // push('/backstage/clients')
        }, 2000);
    }

    function fail() {
        document.querySelector(".lightbox--messageError").style.display =
            "inline-block";
        document.querySelector(".black").style.display = "block";
    }

    function closeLightbox() {
        document.querySelector(".lightbox--messageError").style.display =
            "none";
        document.querySelector(".black").style.display = "none";
    }

    function addDash() {
        if (
            clients.mobile.replaceAll(" ", "") !== "" &&
            clients.mobile.replaceAll(" ", "").length > 5
        ) {
            if (clients.mobile.indexOf("-") == -1) {
                let mobile2 = clients.mobile.substring(
                    4,
                    clients.mobile.length
                );
                clients.mobile =
                    clients.mobile.replaceAll(" ", "").substring(0, 4) +
                    "-" +
                    mobile2;
            }
        } else {
            clients.mobile.replaceAll("-", "");
        }
    }
</script>

<div class="title">
    <h2>客戶明細</h2>
</div>

<div class="form">
    <div class="form__con">
        <div class="form__item">
            <p><span>*</span> 姓名：</p>
            <input type="text" bind:value={clients.name} />
        </div>
        <div class="form__item form__item--gender">
            <p><span>*</span> 性別：</p>
            <label for="man"
                >男
                <input
                    type="radio"
                    value="男"
                    bind:group={clients.gender}
                    id="man"
                    name="gender"
                />
            </label>
            &nbsp; &nbsp; &nbsp;
            <label for="woman"
                >女
                <input
                    type="radio"
                    value="女"
                    bind:group={clients.gender}
                    id="woman"
                    name="gender"
                />
            </label>
        </div>
        <div class="form__item">
            <p><span>*</span> 地區：</p>
            <input type="text" bind:value={clients.area} />
        </div>

        {#if userPermission !== null && userPermission.clients == 2}
            <div class="form__item form__item--status">
                <p>狀態：</p>
                {#if !clients.active}
                    <Button
                        style=""
                        hoverColor="#58b957"
                        props=""
                        bgColor="#58b957"
                        color="#FFFFFF"
                        hoverTextColor="#fff"
                        on:click={changeStatus(clients.id)}
                    >
                        啟用
                    </Button>
                {:else}
                    <Button
                        style=""
                        hoverColor="#f66354"
                        props=""
                        bgColor="#f66354"
                        color="#FFFFFF"
                        hoverTextColor="#fff"
                        on:click={changeStatus(clients.id)}
                    >
                        停用
                    </Button>
                {/if}
            </div>
        {/if}
        <div class="form__item">
            <p><span>*</span> 電話：</p>
            <input type="text" bind:value={clients.mobile} />
        </div>
        <div class="form__item form__item--textarea">
            <p>備註：</p>
            <textarea rows="5" cols="50" bind:value={clients.note} />
        </div>
    </div>
</div>

{#if userRole == 'admin'}
    <div class="btn">
        <div class="btn__con">
            <Button
                style="margin: 10px 0 10px 0;"
                hoverColor="#44abf1"
                props=""
                color="#fff"
                bgColor="#44abf1"
                hoverTextColor="#fff"
                on:click={save}
            >
                儲存
            </Button>
        </div>
    </div>
{/if}

<br />
<div class="table">
    <div class="table__con">
        <div class="table__item">
            <Datatable {settings} data={orders} bind:dataRows={rows}>
                <thead>
                    <th class="sortable asc desc" data-key="">分店<span /></th>
                    <th class="sortable asc desc" data-key="">師傅<span /></th>
                    <th class="sortable asc desc" data-key="">時間<span /></th>
                    <th class="sortable asc desc" data-key="">服務<span /></th>
                    <th class="sortable asc desc" data-key="">總金額<span /></th
                    >
                    <th class="sortable asc desc" data-key="">備註<span /></th>
                    <th class="sortable asc desc" data-key="">狀態<span /></th>
                </thead>
                <tbody>
                    {#if $rows}
                        {#each $rows as row, i}
                            {#if row.client_id == params.client_id}
                                <tr>
                                    <td>{row.branch}</td>
                                    <td>{row.user}</td>
                                    <td>{row.ui_day} {row.ui_time}</td>
                                    <td>
                                        <ul>
                                            {#each row.details as detail, i}
                                                <li>
                                                    {#if row.details.length == i + 1}
                                                        {detail.item + " "}
                                                    {:else}
                                                        {detail.item +
                                                            " " +
                                                            " , "}
                                                    {/if}
                                                </li>
                                            {/each}
                                        </ul>
                                    </td>
                                    <td
                                        style="text-align: right; padding-right: 40px"
                                    >
                                        {separator(row.total_price)}
                                    </td>
                                    <td>{row.note}</td>
                                    <td>{row.status}</td>
                                </tr>
                            {/if}
                        {/each}
                    {/if}
                </tbody>
            </Datatable>
        </div>
    </div>
</div>

<MessageSuccess {message} />
<MessageError {message} on:close={closeLightbox} />
<Black on:black={closeLightbox} />

<style lang="scss">
    @import "./../../components/scss/_reset.scss";
    @import "./../../components/scss/_font.scss";

    .title {
        max-width: 1000px;
        margin: auto;
        h2 {
            font-size: 25px;
            font-weight: 500;
            letter-spacing: 1px;
            color: #425271;
            position: relative;
            margin: auto 0 15px 0px;
            display: inline-block;
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: #960e1e;
                bottom: -3px;
                left: 52px;
                transform: translateX(-50%);
            }
        }
    }

    .form {
        max-width: 1000px;
        margin: auto;
        &__con {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
        &__item {
            width: 50%;
            display: flex;
            align-items: center;
            margin: 5px 0;
            position: relative;
            input {
                width: calc(100% - 110px);
                margin: 10px;
            }
            p {
                margin: 0;
                width: 60px;
                span {
                    color: red;
                }
            }
            textarea {
                resize: none;
                width: calc(100% - 110px);
            }
            &--textarea {
                width: 100%;
                p {
                    width: 70px;
                }
            }
            &--status {
                // padding-left: 30px;
                width: calc(50% + 0px);
            }
            &--email {
                width: calc(50% - 30px);
                p {
                    width: 90px;
                }
            }
            &--gender {
                label {
                    margin: 0 5px 0 10px;
                    display: inline-flex;
                    align-items: center;
                    width: 100px;
                }
                input {
                    width: 30px;
                    margin: 5px;
                }
            }
        }
    }

    .btn {
        max-width: 1000px;
        padding: 0 40px 0 0;
        margin: 10px auto 30px;
        width: 100%;
        display: block;
        &__con {
            display: flex;
            justify-content: flex-end;
        }
    }

    //表單
    .table {
        // border-top: 1px solid #e0e0e0;
        max-width: 1000px;
        padding: 0 30px 0 0;
        margin: auto;
    }
    table,
    th,
    td {
        // border: 1px solid #b9b3b3;
        border-collapse: collapse;
        border-bottom: 1px solid #ece9e9;
    }
    th {
        padding: 8px 0px 8px 0px;
        text-align: center;
        border-bottom: 1px solid #b9b3b3;
        background: #fff;
    }
    th.sortable {
        cursor: pointer;
    }
    th.sortable span {
        padding-right: 16px;
        position: relative;
    }
    th.sortable span:before,
    th.sortable span:after {
        border: 4px solid transparent;
        content: "";
        display: block;
        height: 0;
        right: 0;
        top: 50%;
        position: absolute;
        width: 0;
    }
    th.sortable span:before {
        border-bottom-color: #e0e0e0;
        margin-top: -9px;
    }
    th.sortable span:after {
        border-top-color: #e0e0e0;
        margin-top: 1px;
    }
    th.sortable.asc:not(.desc) span:before {
        border-bottom-color: #9e9e9e;
    }
    th.sortable.desc:not(.asc) span:after {
        border-top-color: #9e9e9e;
    }

    td {
        text-align: center;
        padding: 20px 0;
        word-break: break-all;
    }
</style>
