<script>
    export let color = '#d60c18';
    export let hoverColor;
    export let props;
    export let style = '';
    export let bgColor;
    export let hoverTextColor;
  
    // $: bgColor  = '#000'
    // $: hoverTextColor  = '#000'
    
  </script>
  
  <style>
     button {
      border: solid 2px var(--bgColor);
      background-color: var(--bg-color);
      color: var(--color);
      min-width: 120px;
      transition: all 0.2s;
      padding: 10px 20px;
      margin: 10px;
      font-size: 16px;
      cursor: pointer;
      border-radius: 20px;
      transition: 0.3s;
    }
  
    button:hover {
      border-color: var(--hover-color);
      background-color: var(--hover-color);
      color: var(--hover-text-color);
      opacity: 0.8;
    } 
  
    button:disabled {
      color: #abb3b7;
      border-color: #abb3b7;
      background-color: white;
    }
  
    button:focus {
      outline:none;
    }
  </style>
  
  <!-- （--color）放進:root裡面的顏色，因為是封閉css，故可以這樣用 -->
  <button
    style="
      --color: {color};
      --hover-color: {hoverColor || color};
      --bg-color: {bgColor || 'white'};
      --hover-text-color: {hoverTextColor || 'white'};
      {style}
    "
    {...props}
    on:click
  >
    <slot />
  </button>
  