<!-- 預約管理月曆 /backstage/orders/calendar -->
<script>
  import axios from "axios";
  import liff from "@line/liff";
  import FullCalendar from "svelte-fullcalendar";
  import dayGridPlugin from "@fullcalendar/daygrid";
  import timeGridPlugin from "@fullcalendar/timegrid";
  import interactionPlugin from "@fullcalendar/interaction";
  import Button from "./../../components/Input/Button.svelte";
  import { onMount } from "svelte";
  import {
    chooseCalenderDate_o,
    chooseCalenderMaster_o,
    chooseCalenderStore_o,
  } from "./../../components/store";
  import { push, link } from "svelte-spa-router";
  import { dayjs } from "svelte-time";

  let role;
  let userLineId = "";
  let userPermission = {
    orders: "",
    business_records: "",
    shifts: "",
    clients: "",
    holidays: "",
    dayoff: "",
    roles: "",
    users: "",
  };

  // 分店
  let storeItems = [];
  let storeSelect = [];
  let storeSelectAll = false;

  // 師傅
  let masterItems = [];
  let masterSelect = [];
  let masterSelectAll = false;

  let eventJson = [];
  let chooseYear = [];
  let chooseMonth = [];
  let chooseYearMonth;
  let showCalender;
  let getData = false;
  let getRole = false;

  $: userLineId !== "" && userLineId !== null
    ? (showCalender = true)
    : (showCalender = false);

  onMount(async () => {
    const loginPromise = (async () => {
      let profile = {};
      try {
        await liff.init({
          liffId: "1656736039-RxGp3xE8",
        });
        if (!liff.isLoggedIn()) {
          liff.login({ redirectUri: location.href });
        }
        profile = await liff.getProfile();
        userLineId = profile.userId; // 取得系統管理者/師傅 Line ID
        // userLineId = "Uc317c5f1531a848f4f854ded597b9923";
        // userLineId = 'U02322f3c25e40f3b7983b9960cc18f33';

        axios.defaults.headers.common["User-Id"] = userLineId;
        axios.defaults.headers.common["stage"] = "backstage";

        // 打權限知道身份 ＃18 get /api/v1/users/permissions
        axios({
          method: "get",
          url: env.BASE_URL + "api/v1/users/permissions",
        })
          .then(function (res) {
            if(res.data.role == null) {
              push("/error");
            } else {
              cookieSetup("userName", res.data.name);
              cookieSetup("userPermission", JSON.stringify(res.data.func));
              cookieSetup("userRole", res.data.role);
              cookieSetup("userLineId", res.data.user_id);
              axios.defaults.headers.common["User-Id"] = res.data.user_id;
              userLineId = res.data.user_id
              role = res.data.role;
              getRole = true;
              afterCheckRoleStatus();
              userPermission = JSON.parse(getCookie("userPermission"));
              if(userPermission.orders == 0){
                push("/error");
              }
            }
          })
          .catch(function (err) {
            console.dir(err);
            push("/error");
          });
      } catch (err) {
        console.log(err);
      }
      return profile;
    })();

    let date = new Date();
    chooseYear = `${date.getFullYear().toString()}-0${(date.getMonth() + 1).toString()}`;
    chooseMonth = `0${(date.getMonth() + 1).toString()}`;
    chooseYearMonth = ``;
    masterSelectAll = true;
    document.querySelector("#masterSelectAll").checked = masterSelectAll;
    storeSelectAll = true;
    document.querySelector("#storeSelectAll").checked = storeSelectAll;
  });

  function afterCheckRoleStatus() {
    if (getRole) {
      axios({
        method: "get",
        url: env.BASE_URL + "api/v1/users",
      })
        .then(function (res) {
          masterItems = res.data;
          getData = false;
          masterSelect = [];
          if (role == "admin") {
            for (let u = 0; u < masterItems.length; u++) {
              masterSelect.push(masterItems[u].id);
              if (u == masterItems.length - 1) {
                getData = true;
              }
            }
          } else if (role == "therapist") {
            masterSelect.push(userLineId);
            getData = true;
          }
        })
        .catch(function (err) {
          console.dir(err);
          push('/error')
        });

      axios({
        method: "get",
        url: env.BASE_URL + "api/v1/branches",
      })
        .then(function (res) {
          storeItems = res.data;
          storeSelect = [];
          for (let s = 0; s < storeItems.length; s++) {
            storeSelect.push(storeItems[s].branch_id);
          }
        })
        .catch(function (err) {
          console.dir(err);
          push('/error')
        });
    }
  }

  function getCookie(name) {
    var cookieArr = document.cookie.split(";");
    for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");
      if (name == cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
  }

  function cookieSetup(name, value) {
    var expires = new Date();
    expires.setTime(expires.getTime() + 1 * 60 * 60 * 1000);
    document.cookie =
      name + "=" + escape(value) + ";path=/;expires=" + expires.toGMTString();
  }

  function search() {
    chooseCalenderMaster_o.set(masterSelect);
    chooseCalenderStore_o.set(storeSelect);
    options = {
      ...options,
      events: "",
    };
    if (masterSelect.length !== 0) {
      if (storeSelect.length !== 0) {
        axios({
          method: "get",
          url: env.BASE_URL + `api/v1/orders/calendar/login_user/${userLineId}`,
          params: {
            y: chooseYear,
            m: chooseMonth,
            u: masterSelect.join(","),
            b: storeSelect.join(","),
          },
        })
          .then(function (res) {
            for (let d = 0; d < res.data.events.length; d++) {
              res.data.events[d].title = `${separator(
                res.data.events[d].title
              )}`;
              res.data.events[d].color = "transparent";
              res.data.events[d].backgroundColor = "transparent";
              if (d % 3 == 0) {
                res.data.events[d].textColor = "#3E8BCC";
              }
              if (d % 3 == 1) {
                res.data.events[d].textColor = "#57B956";
              }
              if (d % 3 == 2) {
                res.data.events[d].textColor = "#DB524C";
              }
            }
            options = {
              ...options,
              events: res.data.events,
            };
          })
          .catch(function (err) {
            // console.dir(err);
          });
      } else {
        alert("至少選擇一分店！");
      }
    } else {
      alert("至少選擇一師傅！");
    }
  }

  let options = {
    initialView: "dayGridMonth",
    plugins: [dayGridPlugin, interactionPlugin],
    eventClick: function (info) {
      chooseCalenderDate_o.set(info.event.start);
      push("/backstage/orders");
    },
    events: eventJson,
    eventContent: function (arg) {
      return {
        html: arg.event.title.replace(/\n/g, "<br>"),
      };
    },
    // 日曆換月觸發 viewDisplay datesSet
    datesSet: async (event) => {
      options = {
        ...options,
        events: "",
      };
      axios({
        method: "get",
        url: env.BASE_URL + `api/v1/orders/calendar/login_user/${userLineId}`,
        params: {
          y: dayjs(event.view.currentStart).format("YYYY"),
          m: dayjs(event.view.currentStart).format("MM"),
          u: masterSelect.join(","),
          b: storeSelect.join(","),
        },
      })
        .then(function (res) {
          // console.log(res);
          for (let d = 0; d < res.data.events.length; d++) {
            res.data.events[d].title = `${separator(res.data.events[d].title)}`;
            res.data.events[d].color = "transparent";
            res.data.events[d].backgroundColor = "transparent";
            if (d % 3 == 0) {
              res.data.events[d].textColor = "#3E8BCC";
            }
            if (d % 3 == 1) {
              res.data.events[d].textColor = "#57B956";
            }
            if (d % 3 == 2) {
              res.data.events[d].textColor = "#DB524C";
            }
          }
          options = {
            ...options,
            events: res.data.events,
          };
        })
        .catch(function (err) {
          console.dir(err);
          // //push('/error')
        });
      chooseYearMonth = dayjs(event.currentStart).format("YYYY-MM");
      chooseYear = dayjs(event.view.currentStart).format("YYYY");
      chooseMonth = dayjs(event.view.currentStart).format("MM");
    },
  };

  function separator(numb) {
    var str = numb.toString().split(".");
    str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return str.join(".");
  }

  function storeSelectAllClick(event) {
    if (!storeSelectAll) {
      storeSelect = [];
      for (let s = 0; s < storeItems.length; s++) {
        storeSelect.push(storeItems[s].branch_id);
      }
    } else {
      storeSelect = [];
    }
    storeSelectAll = !storeSelectAll;
    setTimeout(() => (event.target.checked = storeSelectAll), 0);
  }

  function masterSelectAllClick(event) {
    if (!masterSelectAll) {
      masterSelect = [];
      if (role == "admin") {
        for (let u = 0; u < masterItems.length; u++) {
          masterSelect.push(masterItems[u].id);
        }
      } else if (role == "therapist") {
        for (let u = 0; u < masterItems.length; u++) {
          if (masterItems[u].id == getCookie("userLineId")) {
            masterSelect.push(masterItems[u].id);
          }
        }
      }
    } else {
      masterSelect = [];
    }
    masterSelectAll = !masterSelectAll;
    setTimeout(() => (event.target.checked = masterSelectAll), 0);
  }

  function masterSelectchange() {
    setTimeout(function () {
      if (masterSelect.length == masterItems.length) {
        masterSelectAll = true;
        document.querySelector("#masterSelectAll").checked = masterSelectAll;
      } else {
        masterSelectAll = false;
        document.querySelector("#masterSelectAll").checked = masterSelectAll;
      }
    }, 0);
  }

  function storeSelectchange() {
    setTimeout(function () {
      if (storeSelect.length == storeItems.length) {
        storeSelectAll = true;
        document.querySelector("#storeSelectAll").checked = storeSelectAll;
      } else {
        storeSelectAll = false;
        document.querySelector("#storeSelectAll").checked = storeSelectAll;
      }
    }, 0);
  }
</script>

<div class="title">
  <h2>預約管理月曆</h2>
</div>

<div class="new">
  {#if userPermission !== null && userPermission.orders == 2}
    <a use:link={{ href: `/backstage/orders/new/`, disabled: false }}>
      <Button
        style="margin: 10px 0;"
        hoverColor="#58b957"
        props=""
        bgColor="#58b957"
        color="#fff"
        hoverTextColor="#fff"
      >
        新增預約
      </Button>
    </a>
  {/if}
</div>

<div class="search">
  <div class="search__con">
    <div class="search__item search--store">
      <p>分店：</p>
      <div>
        <div>
          <input
            type="checkbox"
            name="storeSelectAll"
            on:click={storeSelectAllClick}
            id="storeSelectAll"
          /><label for="storeSelectAll">&nbsp;全選</label
          >&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        {#each storeItems as storeItem, i}
          <div>
            <input
              type="checkbox"
              name="shop"
              value={storeItem.branch_id}
              id={storeItem.branch}
              bind:group={storeSelect}
              on:click={storeSelectchange}
            /><label for={storeItem.branch}>&nbsp;{storeItem.branch}</label
            >&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        {/each}
      </div>
    </div>
    <div class="search__item search--people">
      <p>師傅：</p>
      <div>
        <div>
          <input
            type="checkbox"
            name="masterSelectAll"
            on:click={masterSelectAllClick}
            id="masterSelectAll"
          /><label for="masterSelectAll">&nbsp;全選</label
          >&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        {#if role == "admin"}
          {#each masterItems as masterItem, i}
            <div>
              <input
                type="checkbox"
                name="master"
                value={masterItem.id}
                id={masterItem.name}
                bind:group={masterSelect}
                on:click={masterSelectchange}
              /><label for={masterItem.name}>&nbsp;{masterItem.name}</label
              >&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          {/each}
        {:else if role == "therapist"}
          {#each masterItems as masterItem, i}
            {#if masterItem.id == getCookie("userLineId")}
              <div>
                <input
                  type="checkbox"
                  name="master"
                  value={masterItem.id}
                  id={masterItem.name}
                  bind:group={masterSelect}
                  on:click={masterSelectchange}
                /><label for={masterItem.name}>&nbsp;{masterItem.name}</label
                >&nbsp;&nbsp;&nbsp;&nbsp;
              </div>
            {/if}
          {/each}
        {/if}
      </div>
    </div>
    <div class="search__item search--button">
      <Button
        color="#fff"
        style="margin: 10px 0px;"
        hoverColor="#7386ed"
        props=""
        bgColor="#7386ed"
        hoverTextColor="#fff"
        on:click={search}
      >
        查詢
      </Button>
    </div>
    <hr />
  </div>
</div>

{#if showCalender && getData}
  <FullCalendar bind:options />
{/if}

<style lang="scss">
  @import "./../../components/scss/_font.scss";
  @import "./../../components/scss/_reset.scss";

  .title {
    display: inline-block;
    padding: 0.5rem;
    h2 {
      font-size: 25px;
      font-weight: 500;
      letter-spacing: 1px;
      color: #425271;
      position: relative;
      margin: auto 0 15px 0;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #960e1e;
        bottom: -3px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .new {
    display: inline-flex;
    justify-content: flex-end;
    width: calc(100% - 180px);
    padding: 0.5rem;
  }

  .search {
    padding: 0.5rem;
    &__item {
      width: 100%;
      display: flex;
      margin: 20px 0;
      > div {
        display: flex;
        flex-wrap: wrap;
        > div {
          margin: 5px 0;
          display: flex;
          align-items: center;
        }
      }
      > p {
        white-space: nowrap;
        margin: 5px 0;
      }
    }
    &--button {
      display: flex;
      justify-content: flex-end;
    }
  }
</style>
