<h2>Line 自動登入中</h2>

<script>
    import { onMount } from 'svelte';
    import liff from "@line/liff";
    
    const loginPromise = (async () => {
        await liff.init({ 
            liffId: '1656736039-RxGp3xE8',
            withLoginOnExternalBrowser: true
        })
        if (!liff.isLoggedIn()) {
            sessionStorage.setItem('liffLoginRedirect', location.href)
            liff.login()
        }
    })()
</script>