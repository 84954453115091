<!--  後台：角色管理權限明細  /backstage/roles/<role_id>/permissions -->

<script>
    import Button from './../../components/Input/Button.svelte';
    import axios from 'axios';
	import { onMount } from 'svelte';
    import SveltyPicker from 'svelty-picker'
    import { link , push } from 'svelte-spa-router'
    import { dayjs } from "svelte-time";
    import MessageError from "./../../components/Lightbox/_messageError.svelte";
    import MessageSuccess from "./../../components/Lightbox/_messageSuccess.svelte";
    import Black from "./../../components/Lightbox/_black.svelte";


    export let params = {}
    
    let permissions=[];
    let userLineId = "";
    let userPermission = {
        orders: "",
        business_records: "",
        shifts: "",
        clients: "",
        holidays: "",
        dayoff: "",
        roles: "",
        users: "",
    };
        
    let message ='';

    onMount(async () => {
        userLineId = getCookie("userLineId");
        if( userLineId == null || userLineId == '' ){
            push('/backstage/orders/calendar')
        }else{
            if(getCookie("userPermission") !== null && getCookie("userPermission") !== ''){
                userPermission = JSON.parse(getCookie("userPermission"));
                if(userPermission.roles == 0){
                    push('/backstage/orders/calendar')  
                }
            }else{
                push('/backstage/orders/calendar')  
            }
        }
        
        //店家列表
        await axios({
            method: 'get',
            url: env.BASE_URL + `api/v1/roles/${params.role_id}/permissions`,
            // headers:{},
            }).then(function (res) {
                // permissions = `[{${res.data}]`;
                permissions = Object.keys(res.data).map(function(_) { return res.data[_]; });

                // console.log(res);
            }).catch(function (err) {
                // console.dir(err);
            }
        );
    });

    function getCookie(name) {
		var cookieArr = document.cookie.split(";");
		for(var i = 0; i < cookieArr.length; i++) {
			var cookiePair = cookieArr[i].split("=");
			if(name == cookiePair[0].trim()) {
				return decodeURIComponent(cookiePair[1]);
			}
		}
		return null;
	}

    function closeLightbox(){
        document.querySelector('.lightbox--messageError').style.display = 'none'
        document.querySelector('.black').style.display = 'none'
    }


    function save(){
        axios({
            method: 'put',
            url: env.BASE_URL + `api/v1/roles/${params.role_id}/permissions`,
            // headers:{},
            data: {
                permissions: permissions
            }
            }).then(function (res) {
                // console.log(res);
                if(res.data.status == 'successful'){
                    message = '修改成功！'
                    success();
                }else{
                    message = res.data.message
                    fail();
                }
            }).catch(function (err) {
                // console.dir(err);
            }
        );
    }

    function success(){
        document.querySelector('.black').style.display = 'block'
        document.querySelector('.lightbox--messageSuccess').style.display = 'inline-block'
        setTimeout( function(){
            document.querySelector('.lightbox--messageSuccess').style.display = 'none'
            document.querySelector('.black').style.display = 'none'
            push('/backstage/roles')
        },2000)
    }

    function fail(){
        document.querySelector('.lightbox--messageError').style.display = 'inline-block'
        document.querySelector('.black').style.display = 'block'
    }
    
  
    

</script>

<div class="title">
    <h2>權限設定</h2>
</div>

<div class="table">
    <div class="table__con">
        <div class="table__item">
            <thead>
                <th class="table--function">功能</th>
                <th class="table--permission">權限層級</th>
            </thead>
            <tbody>
            <!-- {#if permissions} -->
                {#each permissions as permission,i}
                <tr>
                    <!-- {permission.level} -->
                    <td class="table--function">{permission.func_name}</td>
                    <td class="table--permission">
                        
                        <label for={permission.func_name + '無權限' + i}>
                            <input
                                type="radio"
                                bind:group={permission.level}
                                value={0}
                                id={permission.func_name + '無權限' + i}
                                name={permission.func_name}
                            />
                            無權限
                          </label>
                            <label for={permission.func_name + '讀取' + i}>
                              <input
                                type="radio"
                                bind:group={permission.level}
                                value={1}
                                id={permission.func_name + '讀取' + i}
                                name={permission.func_name}
                              />
                              讀取</label
                            >
                            <label for={permission.func_name + '讀取/修改' + i}>
                                <input 
                                type="radio"
                                bind:group={permission.level}
                                value={2}
                                id={permission.func_name + '讀取/修改' + i}
                                name={permission.func_name}
                            >
                            讀取 / 修改
                        </label>

                    </td>
                </tr>
                {/each}
            <!-- {/if} -->
            </tbody>
        </div>
    </div>

    {#if userPermission !== null && userPermission.roles == 2}
        <div class="btn">
            <div class="btn__con">
                <div class="btn__item">
                    <Button
                        style="margin: 10px 10px;"
                        hoverColor="#58b957"
                        props=''
                        bgColor = '#58b957'
                        color= "#fff"
                        hoverTextColor="#fff"
                        on:click={save}
                        >
                        儲存
                    </Button>
                </div>
            </div>
        </div>
    {/if}
</div> 

<MessageSuccess {message} />
<MessageError {message} on:close={closeLightbox}/>
<Black on:black={closeLightbox} />


<style lang="scss">
	@import './../../components/scss/_font.scss';
	@import './../../components/scss/_reset.scss';
    @import './../../components/scss/_datatablesCss.scss';


    .title{
        display: inline-block;
        h2{
            font-size: 25px;
            font-weight: 500;
            letter-spacing: 1px;
            color: #425271;
            position: relative;
            margin: auto 0 15px 0;
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: #960E1E;
                bottom: -3px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    .btn{
        padding: 0.5rem;
        width: calc(100%);
        display: inline-flex;
        justify-content: flex-end;
        &__item{
            width: 100%;
            display: flex;
            margin: 20px 0;
            > div{
                display: flex;
                flex-wrap: wrap;
            }
            > p{
               white-space: nowrap;
            }
        }
    }
    


     //表單
        table{
            max-width: 1200px;
            margin: 0px auto;
            width: 100%;
        }
        .table__con{
            margin-top: 30px;
        }
        th {
            padding: 8px 16px 8px 16px;
            text-align: center;
            border-bottom: 1px solid #eee;
            background: #fff;
        }
        thead,tbody{
            display: flex;
            flex-wrap: wrap;
        }
        th,td {
            // border: 1px solid #dcd7d7;
            border-collapse: collapse;
            border-bottom: 1px solid #ece9e9;
        }
        th {
            padding: 20px 10px 20px 10px;
            text-align: center;
            background: #fff;
        }
        th.sortable {
            cursor: pointer;
        }
        th.sortable span {
            padding-right: 16px;
            position: relative;
        }
        th.sortable span:before,
        th.sortable span:after {
            border: 4px solid transparent;
            content: '';
            display: block;
            height: 0;
            right: 0;
            top: 50%;
            position: absolute;
            width: 0;
        }
        th.sortable span:before {
            border-bottom-color: #e0e0e0;
            margin-top: -9px;
        }
        th.sortable span:after {
            border-top-color: #e0e0e0;
            margin-top: 1px;
        }
        th.sortable.asc:not(.desc) span:before {
            border-bottom-color: #9e9e9e;
        }
        th.sortable.desc:not(.asc) span:after {
            border-top-color: #9e9e9e;
        }
    
        td{
            // text-align: left;
            text-align: center;
            padding: 20px 10px 20px 10px;
            word-break: break-all;
        }

        td label{
            width: 32%;
        }

        td input{
            margin-left: 5px;
        }

        th.table--permission{
            width: 70%;
        }

        th.table--function{
            width: 30%;
        }

        td.table--permission{
            width: 70%;
            display: flex;
            align-items: center;
        }

        td.table--function{
            width: 30%;
        }

        tr{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
        }
</style>