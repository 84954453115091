<!-- https://0.0.0.0:8443/#/orders/U1b5ec9ca7cbf64c64b57d468bd0c8148/new -->
<script>
  import { onMount } from "svelte";
  import liff from "@line/liff";
  import { link } from "svelte-spa-router";
  import Button from "./../../components/Input/Button.svelte";
  import axios from "axios";
  import { Splide, SplideSlide, SplideTrack } from "@splidejs/svelte-splide";
  import "@splidejs/splide/dist/css/themes/splide-default.min.css";
  import { dayjs } from "svelte-time";

  export let params = {};
  let logoSrc = "./image/logo.png";
  let storeAddress = "";
  let addressHref = "";
  let morning = "";
  let evening = "";
  let afternoon = "";
  let storeSelectShow = "";
  let masterSelectShow = "";
  let phoneSelectShow = "";
  let totalPriceShow = "";
  let itemSelectShow = "";
  let chooseTimeShow = "";
  let chooseTimePost = "";
  let dateSlider;
  let note = "";
  let message = "";
  let clients = { name: "", gender: "男", area: "", mobile: "", id: "" };
  let newMember = false;

  const options = {
    type: "loop",
    snap: true,
    rewindSpeed: 400,
    perPage: 1,
    padding: "10px",
    arrows: true,
    pagination: false,
    focus: "center",
  };

  let orders = {
    client_id: "",
    user_id: "",
    branch_id: 1,
    ordered_at: "",
    total_price: "",
    item_id: "",
    note: "",
  };

  let orderAvailableTimes = [];

  // 時間
  let dateStart = "";
  let date = new Date();

  // 分店
  let storeItems = [];
  let storeSelect = 1;

  // 師傅
  let masterItems = [];
  let masterSelect = [];

  //服務
  let items = [];
  let itemSelect = [];

  //搜尋
  let names = [];
  let nameSelect = [];
  let tel = "";

  //時間
  let timeSelect = [];
  let time__hour, time__min, time__period;

  let clientLineId = "";

  $: addressHref = `https://www.google.com.tw/maps/search/${storeAddress}`;

  onMount(async () => {
    clientLineId = getCookie("clientLineId");

    if (clientLineId == null || clientLineId == "") {
      const loginPromise = (async () => {
        let profile = {};
        try {
          await liff.init({
            liffId: "1656736039-RxGp3xE8",
          });
          if (!liff.isLoggedIn()) {
            liff.login({ redirectUri: location.href });
          }
          profile = await liff.getProfile();
          clientLineId = profile.userId; // 取得客戶Line ID

          //取得id想打api看看註冊過了嗎
          axios({
            method: "get",
            url: env.BASE_URL + `api/v1/clients/${clientLineId}`,
          })
            .then(function (res) {
              if (res.data.id == null) {
                newMember = true;
              } else {
                newMember = false;
                clients = res.data;
                cookieSetup("clientLineId", clientLineId);
                axios.defaults.headers.common["Client-Id"] = clientLineId;
                axios.defaults.headers.common["stage"] = "forestage";
              }
            })
            .catch(function (err) {
              console.dir(err);
              // push('/error')
            });
        } catch (err) {
          console.log(err);
        }
        return profile;
      })();
    } else {
      //取得id想打api看看註冊過了嗎
      axios({
        method: "get",
        url: env.BASE_URL + `api/v1/clients/${clientLineId}`,
      })
        .then(function (res) {
          if (res.data.id == null) {
            newMember = true;
          } else {
            newMember = false;
            clients = res.data;
            cookieSetup("clientLineId", clientLineId);
            axios.defaults.headers.common["Client-Id"] = clientLineId;
            axios.defaults.headers.common["stage"] = "forestage";
          }
        })
        .catch(function (err) {
          console.dir(err);
          // push('/error')
        });
    }

    //店家列表
    await axios({
      method: "get",
      url: env.BASE_URL + "api/v1/branches",
    })
      .then(function (res) {
        storeItems = res.data;
        for (let s = 0; s < storeItems.length; s++) {
          if (storeItems[s].branch_id == storeSelect) {
            storeAddress = storeItems[s].branch_address;
          }
        }
      })
      .catch(function (err) {
        console.dir(err);
        alert(err);
      });

    //服務列表
    await axios({
      method: "get",
      url: env.BASE_URL + "api/v1/items",
    })
      .then(function (res) {
        items = res.data;
      })
      .catch(function (err) {
        console.dir(err);
      });

    document.querySelector(".splide__arrows").style.display = "none";
  });

  function save() {
    axios({
      method: "post",
      url: env.BASE_URL + `api/v1/orders/f`,
      // url: env.BASE_URL + `api/v1/orders/`,
      data: {
        client_id: getCookie("clientLineId"),
        user_id: masterSelect,
        branch_id: storeSelect,
        item_id: itemSelect,
        amount: 1,
        ordered_at: chooseTimePost,
        note: note.replaceAll(' ',''),
      },
    })
      .then(function (res) {
        // console.log(res);
        if (res.data.status == "successful") {
          message = res.data.message;
          document.querySelector('.black').style.display = 'block'
          document.querySelector(".message__con").style.background = "#58b957";
          document.querySelector(".message").style.display = "flex";
          setTimeout(function () {
            liff.closeWindow();
          }, 2000);
        } else {
          message = res.data.message;
          document.querySelector('.black').style.display = 'block'
          document.querySelector(".message__con").style.background = "#f66354";
          setTimeout(function () {
            document.querySelector(".message").style.display = "flex";
          }, 500);
          // setTimeout(function () {
          //   document.querySelector(".message").style.display = "none";
          // }, 3000);
        }
      })
      .catch(function (err) {
        console.dir(err);
      });
  }

  function changeStoreServe() {
    for (let s = 0; s < storeItems.length; s++) {
      if (storeItems[s].branch_id == storeSelect) {
        storeAddress = storeItems[s].branch_address;
      }
    }
    if (storeSelect !== [] && itemSelect !== []) {
      for (let s = 0; s < storeItems.length; s++) {
        if (storeItems[s].branch_id == storeSelect) {
          storeSelectShow = storeItems[s].branch;
          phoneSelectShow = storeItems[s].branch_tel;
        }
      }

      for (let i = 0; i < items.length; i++) {
        if (items[i].item_id == itemSelect) {
          itemSelectShow = `${items[i].item} ( ${items[i].item_duration}分鐘 )`;
          totalPriceShow = `${items[i].item_price}元`;
        }
      }

      axios({
        method: "get",
        url: env.BASE_URL + "api/v1/users/available",
        params: {
          b: storeSelect,
          i: itemSelect,
        },
      })
        .then(function (res) {
          masterSelect = [];
          orderAvailableTimes = [];
          masterItems = res.data;
          // console.log(res.data)
          document.querySelector(".splide__arrows").style.display = "none";
          orderAvailableTimes = "";
          morning = "";
          evening = "";
          afternoon = "";
        })
        .catch(function (err) {
          // console.dir(err);
        });
    }
  }

  function changeMaster() {
    if (masterItems.length !== 0) {
      for (let m = 0; m < masterItems.length; m++) {
        if (masterItems[m].id == masterSelect) {
          masterSelectShow = `${masterItems[m].name}`;
        }
      }
    }
    axios({
      method: "get",
      // url: env.BASE_URL + `api/v1/orders/periods/${storeSelect}/${itemSelect}/${masterSelect}/f`,
      url:
        env.BASE_URL +
        `api/v1/orders/periods/${storeSelect}/${itemSelect}/${masterSelect}/f`,
    })
      .then(function (res) {
        // console.log(res);
        orderAvailableTimes = res.data;
        document.querySelector(".splide__arrows").style.display = "block";
        morning = orderAvailableTimes[0].periods.morning;
        morning = Object.keys(morning).map(function (_) {
          return morning[_];
        });
        morning.length == 0 ? (morning = "") : morning;
        afternoon = orderAvailableTimes[0].periods.afternoon;
        afternoon = Object.keys(afternoon).map(function (_) {
          return afternoon[_];
        });
        afternoon.length == 0 ? (afternoon = "") : afternoon;
        evening = orderAvailableTimes[0].periods.evening;
        evening = Object.keys(evening).map(function (_) {
          return evening[_];
        });
        evening.length == 0 ? (evening = "") : evening;
        dateSlider.go(0);
      })
      .catch(function (err) {
        // console.dir(err);
      });
  }

  function slideChange(e) {
    // console.log(e.detail.index)
    // console.log(orderAvailableTimes[e.detail.index])
    document.querySelector(".splide__arrows").style.display = "block";
    morning = orderAvailableTimes[e.detail.index].periods.morning;
    morning = Object.keys(morning).map(function (_) {
      return morning[_];
    });
    morning.length == 0 ? (morning = "") : morning;
    afternoon = orderAvailableTimes[e.detail.index].periods.afternoon;
    afternoon = Object.keys(afternoon).map(function (_) {
      return afternoon[_];
    });
    afternoon.length == 0 ? (afternoon = "") : afternoon;
    evening = orderAvailableTimes[e.detail.index].periods.evening;
    evening = Object.keys(evening).map(function (_) {
      return evening[_];
    });
    evening.length == 0 ? (evening = "") : evening;
    // console.log(evening)
  }

  function chooseTime(time) {
    chooseTimeShow =
      document
        .querySelector(".splide__slide.is-active div")
        .innerHTML.split("(")[0] +
      " " +
      time;
    document.querySelector(".checkOrder").style.opacity = "1";
    document.querySelector(".checkOrder").style.zIndex = "1";
    time.replaceAll(" ", "");
    if (time.includes("AM")) {
      time = time.replace("AM", "");
      chooseTimePost =
        document
          .querySelector(".splide__slide.is-active div")
          .innerHTML.split("(")[0]
          .replaceAll("/", "-") +
        " " +
        time.replaceAll(" ", "") +
        ":00";
    } else {
      time = time.replace("PM", "");
      chooseTimePost =
        document
          .querySelector(".splide__slide.is-active div")
          .innerHTML.split("(")[0]
          .replaceAll("/", "-") +
        " " +
        (parseInt(time.split(":")[0]) + 12) +
        ":" +
        time.split(":")[1].replaceAll(" ", "") +
        ":00";
    }
  }

  function goBack() {
    document.querySelector(".checkOrder").style.opacity = "0";
    document.querySelector(".checkOrder").style.zIndex = "-1";
  }

  function getCookie(name) {
    var cookieArr = document.cookie.split(";");
    for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");
      if (name == cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
  }

  function cookieSetup(name, value) {
    var expires = new Date();
    expires.setTime(expires.getTime() + 1 * 60 * 60 * 1000);
    document.cookie =
      name + "=" + escape(value) + ";path=/;expires=" + expires.toGMTString();
  }

  function closeLightbox(){
      document.querySelector(".message").style.display = "none";
      document.querySelector('.black').style.display = 'none'
  }
</script>

<div class="header--fixed">
  <div class="header--logo">
    <img src={logoSrc} alt="" class="header--logo" />
    <p>協禾詠春傳統國術館</p>
  </div>
</div>

<div class={newMember ? "new blur" : "new"}>
  <div class="new__con">
    <div class="new__item">
      <h2>預約</h2>
    </div>
    <div class="new__item new--store">
      <p>分店：</p>
      <div>
        <select
          name="store"
          id="store"
          bind:value={storeSelect}
          on:change={changeStoreServe}
        >
          {#each storeItems as storeItem, i}
            <option value={storeItem.branch_id} id={storeItem.branch}
              >&nbsp;{storeItem.branch}</option
            >
          {/each}
        </select>
      </div>
    </div>
    <div class="new__item">
      <p>地址：</p>
      <div>
        <a href={addressHref} target="_blank">{storeAddress}</a>
      </div>
    </div>
    <div class="new__item new--items">
      <p>服務：</p>
      <div>
        {#each items as item, i}
          {#if item.item_type == "服務"}
            <div>
              <input
                type="radio"
                name="item"
                value={item.item_id}
                id={item.item}
                bind:group={itemSelect}
                on:change={changeStoreServe}
              /><label for={item.item}>&nbsp;{item.item}&nbsp;&nbsp;{item.item_price}元</label>
            </div>
          {/if}
        {/each}
      </div>
    </div>

    <hr />

    <div class="new__item new--people">
      <p>師傅：</p>
      <div>
        {#if masterItems.length !== 0}
          {#each masterItems as masterItem, i}
            <div>
              <div>
                <input
                  type="radio"
                  name="master"
                  value={masterItem.id}
                  id={masterItem.name}
                  bind:group={masterSelect}
                  on:change={changeMaster}
                /><label for={masterItem.name}>
                  <p>{masterItem.name}</p>
                  <img
                    src={`${env.BASE_URL}/${masterItem.photo}`}
                    alt=""
                  /></label
                >&nbsp;&nbsp;&nbsp;&nbsp;
              </div>
            </div>
          {/each}
        {:else}
          <div>目前無符合人選</div>
        {/if}
      </div>
    </div>
    <div class="new__item new--time">
      <p>可預約時段：</p>
      <div>
        <Splide
          {options}
          class="splide--dynamic"
          aria-labelledby="dynamic-slides-example-heading"
          hasTrack={false}
          bind:this={dateSlider}
          on:move={(e) => slideChange(e)}
        >
          <!-- options={ options } -->
          <div style="position: relative">
            <SplideTrack style="min-height: 15rem">
              {#each orderAvailableTimes as orderAvailableTime, i}
                <SplideSlide>
                  <div>{orderAvailableTime.date}</div>
                </SplideSlide>
              {/each}
            </SplideTrack>
          </div>
        </Splide>
      </div>
    </div>

    <div class="new__item new--chooseTime">
      {#if morning !== ""}
        <div>
          <div class="time--head"><p>早上</p></div>
          <div>
            {#each morning as time, i}
              {#if i % 3 == 0}
                <div class="color1" on:click={(e) => chooseTime(time)}>
                  {time}
                </div>
              {/if}
              {#if i % 3 == 1}
                <div class="color2" on:click={(e) => chooseTime(time)}>
                  {time}
                </div>
              {/if}
              {#if i % 3 == 2}
                <div class="color3" on:click={(e) => chooseTime(time)}>
                  {time}
                </div>
              {/if}
            {/each}
          </div>
        </div>
      {/if}
      {#if afternoon !== ""}
        <div>
          <div class="time--head"><p>下午</p></div>
          <div>
            {#each afternoon as time, i}
              {#if i % 3 == 0}
                <div class="color4" on:click={(e) => chooseTime(time)}>
                  {time}
                </div>
              {/if}
              {#if i % 3 == 1}
                <div class="color5" on:click={(e) => chooseTime(time)}>
                  {time}
                </div>
              {/if}
              {#if i % 3 == 2}
                <div class="color6" on:click={(e) => chooseTime(time)}>
                  {time}
                </div>
              {/if}
            {/each}
          </div>
        </div>
      {/if}
      {#if evening !== ""}
        <div>
          <div class="time--head"><p>晚上</p></div>
          <div>
            {#each evening as time, i}
              {#if i % 3 == 0}
                <div class="color7" on:click={(e) => chooseTime(time)}>
                  {time}
                </div>
              {/if}
              {#if i % 3 == 1}
                <div class="color8" on:click={(e) => chooseTime(time)}>
                  {time}
                </div>
              {/if}
              {#if i % 3 == 2}
                <div class="color9" on:click={(e) => chooseTime(time)}>
                  {time}
                </div>
              {/if}
            {/each}
          </div>
        </div>
      {/if}
    </div>
  </div>
</div>

<div class="checkOrder">
  <div class="checkOrder__con">
    <div class="checkOrder__item">
      <h2>預約資訊確認</h2>
    </div>
    <div class="checkOrder__item checkOrder__item--content">
      <p>日期：{chooseTimeShow}</p>
      <p>地點：{storeSelectShow}</p>
      <p>地址：<a href={addressHref} target="_blank">{storeAddress}</a></p>
      <p>電話：{phoneSelectShow}</p>
      <p>師傅：{masterSelectShow}</p>
      <p>服務：{itemSelectShow}</p>
      <p>費用：{totalPriceShow}</p>
      <p>
        備註：<textarea name="" id="" cols="20" rows="5" bind:value={note} />
      </p>
    </div>
    <div class="checkOrder__item checkOrder__item--button">
      <Button
        color="#333"
        style="margin: 10px 10px;"
        hoverColor="#E6E6E6"
        props=""
        hoverTextColor="#333"
        bgColor="#E6E6E6"
        on:click={goBack}
      >
        返回
      </Button>
      <Button
        color="#333"
        style="margin: 10px 10px;"
        hoverColor="#E6E6E6"
        props=""
        bgColor="#E6E6E6"
        hoverTextColor="#333"
        on:click={save}
      >
        確定預約
      </Button>
    </div>
  </div>
</div>

<div class="message">
  <div class="message__con">
    <img src="./image/close.png" alt="" class="close" on:click="{closeLightbox}">
    <p>{message}</p>
  </div>
</div>

{#if newMember}
  <div class="cover">
    <p>請先註冊！</p>
  </div>
{/if}

<div class="black" on:click="{closeLightbox}"></div>
   

<style lang="scss">
  @import "./../../components/scss/_font.scss";
  @import "./../../components/scss/_reset.scss";

  .header {
    &--fixed {
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      padding: 15px 10px;
      background-color: #425271;
      box-shadow: 0px 0px 8px #b4b3b3;
      z-index: 2;
    }
    &--logo {
      margin: auto;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px 0 10px;
      width: 100%;
      img {
        width: 40px;
      }
      p {
        font-size: 22px;
        margin-left: 5px;
        letter-spacing: 1px;
        color: #f2efef;
      }
    }
  }

  .new {
    margin: 100px 20px 0;
    width: 100%;
    &__item {
      width: 100%;
      display: flex;
      margin: 20px 0;
      > div {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: flex-start;
        select {
          width: 100%;
        }
        > div {
          margin: 5px 0;
          width: 100%;
        }
      }
      > p {
        white-space: nowrap;
        margin: 5px 0;
      }
      a {
        display: flex;
        align-items: center;
      }
      h2 {
        font-size: 25px;
        font-weight: 500;
        letter-spacing: 1px;
        color: #425271;
        position: relative;
        margin: auto 0 15px 0;
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: #960e1e;
          bottom: -3px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &.new--time {
        margin: 0;
      }
    }

    &--people {
      label {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        > p {
          width: 100%;
          text-align: center;
          margin-top: 10px;
        }
        > img {
          width: 100%;
          text-align: center;
          margin-left: 0 !important;
        }
      }
      > div {
        > div {
          margin: 5px 0 10px 0 !important;
        }
      }
    }
    &--items {
      > div {
        > div {
          display: flex;
          align-items: center;
        }
      }
    }

    &--button {
      display: flex;
      justify-content: flex-end;
    }
    &--time {
      display: block;
      // display: flex;
      // align-items: flex-start;
      > div {
        // width: 100%;
        display: block;
        text-align: center;
      }
    }
    &--chooseTime {
      // display: block;
      margin: 0px 0;
      display: flex;
      width: 100vw;
      margin-left: -15px;
      // align-items: flex-start;
      > div {
        display: block;
        margin: 0px 0 0px;
        flex-wrap: nowrap;
        text-align: center;
        align-items: center;
        background-color: rgb(246, 246, 246);
        .time--head {
          margin: 0;
          padding: 10px;
          p {
            margin: 0;
          }
        }
      }
      .color {
        &1 {
          background-color: rgba(250, 212, 160, 0.4);
          padding: 15px;
        }
        &2 {
          background-color: rgba(250, 212, 160, 0.6);
          padding: 15px;
        }
        &3 {
          background-color: rgba(250, 212, 160, 0.8);
          padding: 15px;
        }
        &4 {
          background-color: rgba(172, 199, 173, 0.4);
          padding: 15px;
        }
        &5 {
          background-color: rgba(172, 199, 173, 0.6);
          padding: 15px;
        }
        &6 {
          background-color: rgba(172, 199, 173, 0.8);
          padding: 15px;
        }
        &7 {
          background-color: rgba(160, 221, 250, 0.4);
          padding: 15px;
        }
        &8 {
          background-color: rgba(160, 221, 250, 0.7);
          padding: 15px;
        }
        &9 {
          background-color: rgba(160, 221, 250, 0.9);
          padding: 15px;
        }
      }
    }
    &--title {
      width: 100%;
      margin: 0;
    }
    &--people {
      > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        > div {
          width: 100%;
          display: flex;
          > div {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
          }
          label {
            display: flex;
            align-items: center;
            margin-left: 5px;
          }
          img {
            width: 100%;
            max-height: 200px;
            max-width: 50%;
            border-radius: 50%;
            margin-left: 15px;
          }
        }
      }
    }
    &--note {
      textarea {
        width: 100%;
        resize: none;
      }
    }
  }

  :global(.splide__slide) {
    background-color: rgb(246, 246, 246);
    padding: 20px 0;
    margin: 10px 0;
  }

  :global(.splide) {
    margin: 20px 0 0;
    width: 100vw;
    margin-left: -15px;
  }

  .checkOrder {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    // top: 100%;
    left: 0;
    background-color: #fff;
    z-index: 1;
    padding: 100px 20px 0;
    // display: none;
    z-index: -1;
    opacity: 0;
    transition: 0.5s;
    overflow: auto;
    &__item {
      width: 100%;
      display: flex;
      margin: 20px 0;
      flex-wrap: wrap;
      h2 {
        font-size: 25px;
        font-weight: 500;
        letter-spacing: 1px;
        color: #425271;
        position: relative;
        margin: auto 0 15px 0;
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: #960e1e;
          bottom: -3px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      p {
        width: 100%;
        margin: 5px 0;
        display: flex;
        white-space: nowrap;
      }
      textarea {
        resize: none;
        width: 100%;
      }
      &--button {
        justify-content: center;
      }
    }
  }

  .message {
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: none;
    top: 0;
    left: 0;
    z-index: 101;
    align-items: center;
    justify-content: center;
    &__con {
      width: 90%;
      height: auto;
      text-align: left;
      padding: 50px;
      p {
        font-size: 30px;
        font-weight: 900;
        color: #fff;
      }
      .close{
          position: absolute;
          top: 10px;
          right: 10px;
          width: 30px;
          cursor: pointer;
          height: 30px;
          transition: 0.3s;
          &:hover{
              opacity: 0.6;
          }
      }
    }
  }

  .cover {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    p {
      color: #000;
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 1px;
    }
  }

  .blur {
    filter: blur(3px);
  }
</style>
