<!-- https://svelte.dev/repl/b17c13d4f1bb40799ccf09e0841ddd90?version=3.46.4 -->
<script>
    import { onMount,afterUpdate,tick } from 'svelte';
    import axios from 'axios';
    import Button from './../Input/Button.svelte';
    import { createEventDispatcher } from "svelte";
    import { personImg } from './../store';
    const dispatch = createEventDispatcher()
    
    export let params = {};
    export let user_id;
    let picNum = 0;
    let saveParams;
	let avatar, fileinput, newFile, initPic;
    let postforms = new FormData();
    postforms.append('file','1')

    personImg.subscribe(value => {
        initPic = value 
    });

    function save(){
        if(location.href.indexOf('?self=true') !== -1){
            saveParams = getCookie("userLineId")
        }else{
            saveParams = user_id
        }

        axios({
            method: 'put',
            headers: {'Content-Type': 'multipart/form-data'},
            url: env.BASE_URL + `api/v1/users/${saveParams}/photo`,
            data: postforms,
            }).then(function (res) {
                // console.log(res)
                if(res.data.status == "successful"){
                    // console.log(res)
                    personImg.set(res.data.photo_url)
                    dispatch('save')
                }
            }).catch(function (err) {
                console.dir(err);
                // push('/error')
            }
        );
    }

    function getCookie(name) {
		var cookieArr = document.cookie.split(";");
		for(var i = 0; i < cookieArr.length; i++) {
			var cookiePair = cookieArr[i].split("=");
			if(name == cookiePair[0].trim()) {
				return decodeURIComponent(cookiePair[1]);
			}
		}
		return null;
	}

    function onFileSelected(e){
        picNum++;
        postforms.delete('file');
        let image = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = e => {
            avatar = e.target.result
            // console.log(e)
            newFile = dataURLtoFile( e.target.result,`people${picNum}.jpg`)
            postforms.append('file', newFile);
        };
    }

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }


    function clickBlack(){
        dispatch('close')
    }
    </script>
    
    <div class="lightbox lightbox--image">
        <div class="lightbox__con">
            <img src="./image/close.png" alt="" class="close" on:click="{clickBlack}">
            <h2 class="lightbox--title">更換照片</h2>
            <div>
                <div class="lightbox__item">
                    {#if avatar}
                        <img class="avatar" src="{avatar}" alt="d" />
                    {:else}
                        <img class="avatar" src="{initPic}" alt="" /> 
                    {/if}
                    <label for="file"><input type="file" id="file" name="file"  on:change={(e)=>onFileSelected(e)} bind:value={fileinput}></label>
                </div>
                <div class="lightbox__item lightbox__item--button">
                    <Button
                        style="padding: 10px; margin: 0"
                        hoverColor="#44abf1"
                        props=''
                        color= "#fff"
                        bgColor = '#44abf1'
                        hoverTextColor="#fff"
                        on:click={save}   
                        >
                        確定更換
                    </Button>
                </div>
            </div>
        </div>
    </div>
    
    
    <style lang="scss">
        
        @import './../../components/scss/_font.scss';
        @import './../../components/scss/_reset.scss';
    

        .lightbox{
            &--image{
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                z-index: 111;
                display: none;
                position: fixed;
            }
            &__con{
                max-width: 600px;
                min-width: 600px;
                background-color: #fff;
                padding: 20px 20px 20px;
                h2{
                    font-size: 25px;
                    font-weight: 500;
                    letter-spacing: 1px;
                    display: inline-block;
                    color: #425271;
                    position: relative;
                    margin: auto 0 15px 0;
                    &::after{
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        background-color: #960E1E;
                        bottom: -3px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
              > div{
                padding: 0px 0px 0px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                flex-wrap: wrap;
              }
            }
            &__item{
                width: calc(100% - 20px);
                margin: 10px;
                text-align: center;
                input{
                    width: 100%;
                    padding: 20px 15px;
                    margin: 30px 0 0;
                    line-height: 30px;
                }
                .upload{
                    display:flex;
                    height:50px;
                    width:50px;
                    cursor:pointer;
                }
                .avatar{
                    display: flex;
                    height: 100%;
                    width: auto;
                    max-width: 200px;
                    max-height: 270px;
                    border-radius: 50%;
                    margin: auto;
                    border: 8px solid #425271;
                }
                span{
                    color: #f00;
                }
                &--button{
                    margin-top: 30px;
                    text-align: right;
                    margin-left: auto;
                }
            }
            .close{
                position: absolute;
                top: 10px;
                right: 10px;
                width: 30px;
                cursor: pointer;
                transition: 0.3s;
                &:hover{
                    opacity: 0.6;
                }
            }
        }
    </style>