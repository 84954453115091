<!-- 薪資表 https://0.0.0.0:8443/#/backstage/orders/salary -->
<script>
    import Button from "./../../components/Input/Button.svelte";
    import axios from "axios";
    import { onMount } from "svelte";
    import { link, push } from "svelte-spa-router";

    export let params = {};

    let userPermission = {
        orders: "",
        business_records: "",
        shifts: "",
        clients: "",
        holidays: "",
        dayoff: "",
        roles: "",
        users: "",
    };
    let noData = true;
    let loadData = false;
    let yearSelect = "";
    let monthSelect = "";
    let masterSelect = "";
    let masterItems = [];
    let userLineId;
    let yearItems = [
        {
            id: 1,
            year: "2022",
        },
        {
            id: 2,
            year: "2023",
        },
        {
            id: 3,
            year: "2024",
        },
        {
            id: 4,
            year: "2025",
        },
        {
            id: 5,
            year: "2026",
        },
        {
            id: 6,
            year: "2027",
        },
        {
            id: 7,
            year: "2028",
        },
        {
            id: 8,
            year: "2029",
        },
        {
            id: 9,
            year: "2030",
        },
        {
            id: 10,
            year: "2031",
        },
        {
            id: 11,
            year: "2032",
        },
        {
            id: 12,
            year: "2033",
        },
        {
            id: 13,
            year: "2034",
        },
        {
            id: 14,
            year: "2035",
        },
        {
            id: 15,
            year: "2036",
        },
        {
            id: 16,
            year: "2037",
        },
        {
            id: 17,
            year: "2038",
        },
        {
            id: 18,
            year: "2039",
        },
        {
            id: 19,
            year: "2040",
        },
        {
            id: 20,
            year: "2041",
        },
    ];
    let storeItems = [];
    let storeSelect = [];
    let storeSelectAll = false;
    let items = [];
    let key1 = [];
    let key2 = [];
    let newArr = [];
    let type1 = 0;
    let type2 = 0;

    let storeDone = false;
    let masterDone = false;

    $: if (storeDone && masterDone) {
        search();
    }

    $: if (loadData) {
        setTimeout(function () {
            SumByItem();
            SumByType();
        }, 1000);
    }

    let result;

    let servicePercentage = "40";
    let itemPercentage = "10";

    onMount(async () => {
        userLineId = getCookie("userLineId");
        if (userLineId == null || userLineId == "") {
            push("/backstage/orders/calendar");
        } else {
            if (
                getCookie("userPermission") !== null &&
                getCookie("userPermission") !== ""
            ) {
                userPermission = JSON.parse(getCookie("userPermission"));
            } else {
                push("/backstage/orders/calendar");
            }

            let date = new Date();
            yearSelect = `${date.getFullYear().toString()}`;
            monthSelect = `${(date.getMonth() + 1).toString()}`;

            //師傅列表
            await axios({
                method: "get",
                url: env.BASE_URL + "api/v1/users",
            })
                .then(function (res) {
                    masterItems = res.data;
                    for (let u = 0; u < masterItems.length; u++) {
                        if (masterItems[u].id == getCookie("userLineId")) {
                            masterSelect = masterItems[u].id;
                            masterDone = true;
                        }
                    }
                    // console.log(res);
                })
                .catch(function (err) {
                    // console.dir(err);
                });

            //店家列表
            await axios({
                method: "get",
                url: env.BASE_URL + "api/v1/branches",
            })
                .then(function (res) {
                    storeItems = res.data;
                    storeSelect = [];
                    for (let s = 0; s < storeItems.length; s++) {
                        storeSelect.push(storeItems[s].branch_id);
                    }
                    storeSelectAll = true;
                    document.querySelector("#storeSelectAll").checked =
                        storeSelectAll;
                    storeDone = true;
                })
                .catch(function (err) {
                    // console.dir(err);
                });

            //服務列表
            await axios({
                method: "get",
                url: env.BASE_URL + `api/v1/items`,
            })
                .then(function (res) {
                    // console.log(res);
                    items = res.data;
                    for (var i = 0; i < items.length; i++) {
                        if (items[i].item_type == "服務") {
                            type1++;
                        } else {
                            type2++;
                        }
                    }
                    // console.log(type1)
                    // console.log(type2)
                })
                .catch(function (err) {
                    console.dir(err);
                    // push('/error')
                });
        }
    });

    function getCookie(name) {
        var cookieArr = document.cookie.split(";");
        for (var i = 0; i < cookieArr.length; i++) {
            var cookiePair = cookieArr[i].split("=");
            if (name == cookiePair[0].trim()) {
                return decodeURIComponent(cookiePair[1]);
            }
        }
        return null;
    }

    function cookieSetup(name, value) {
        var expires = new Date();
        expires.setTime(expires.getTime() + 1 * 60 * 60 * 1000);
        document.cookie =
            name +
            "=" +
            escape(value) +
            ";path=/;expires=" +
            expires.toGMTString();
    }

    function search() {
        if (masterSelect == "") {
            alert("請選擇師傅！");
        } else if (storeSelect == "") {
            alert("請選擇店家！");
        } else {
            loadData = true;
            //薪資表
            axios({
                method: "get",
                url:
                    env.BASE_URL +
                    `api/v1/orders/salary/login_user/${userLineId}?y=${yearSelect}&m=${monthSelect}&u=${masterSelect}&b=${storeSelect}`,
            })
                .then(function (res) {
                    if (Object.keys(res.data).length !== 0) {
                        loadData = false;
                        noData = false;
                        result = res.data;
                        key1 = Object.keys(result);
                        key2 = Object.entries(result).sort((a, b) => {
                            return new Date(a[0].substring(0, 10)) - new Date(b[0].substring(0, 10))
                        });;
                        for (let o = 0; o < key2.length; o++) {
                            newArr = Object.entries(key2[o][1]);
                            key2[o][1] = newArr;
                        }
                    } else {
                        loadData = false;
                        noData = true;
                        result = {};
                    }
                })
                .catch(function (err) {
                    console.dir(err);
                    // push('/error')
                });
        }
    }

    function SumByDay(num) {
        let arr = key2[num][1];
        let sum = 0;
        for (let i = 0; i < arr.length; i++) {
            sum += parseInt(arr[i][1]);
        }
        return separator(sum);
    }

    function SumByItem() {
        if (!noData) {
            let td = document.querySelectorAll("td");
            let itemTotal = document.querySelectorAll(".itemTotal");
            // console.log(td.length)
            for (let i = 0; i < itemTotal.length; i++) {
                let s = 0;
                for (let t = 0; t < td.length; t++) {
                    if (td[t].hasAttribute("data-place")) {
                        let num_ = td[t].getAttribute("data-place").split(",");
                        if (
                            num_[1] ==
                            itemTotal[i].getAttribute("data-num") - 1
                        ) {
                            if (!td[t].innerHTML == "") {
                                s =
                                    parseInt(
                                        td[t].innerHTML.replaceAll(",", "")
                                    ) + s;
                            }
                        }
                    }
                    // if (s !== 0) {
                    itemTotal[i].innerHTML = separator(s);
                    // }
                }
            }
        }
    }

    function SumByType() {
        if (!noData) {
            let itemTotal = document.querySelectorAll(".itemTotal");
            let s1 = 0;
            let s2 = 0;
            for (let i = 0; i < itemTotal.length; i++) {
                // if (itemTotal[i].innerHTML !== "") {
                if (i < type1) {
                    s1 =
                        parseInt(itemTotal[i].innerHTML.replaceAll(",", "")) +
                        s1;
                } else {
                    s2 =
                        parseInt(itemTotal[i].innerHTML.replaceAll(",", "")) +
                        s2;
                }
                // }
            }
            document.querySelector(".typeTotal1").innerHTML = separator(s1);
            document.querySelector(".typeTotal2").innerHTML = separator(s2);
            document.querySelector(".getTotal1").innerHTML = separator(
                (s1 * parseInt(servicePercentage)) / 100
            );
            document.querySelector(".getTotal2").innerHTML = separator(
                (s2 * parseInt(itemPercentage)) / 100
            );
            document.querySelector(".getTotal").innerHTML = separator(
                (s1 * parseInt(servicePercentage)) / 100 +
                    (s2 * parseInt(itemPercentage)) / 100
            );
            document.querySelector(".total").innerHTML = separator(s1 + s2);
        }
    }

    function separator(numb) {
        var str = numb.toString().split(".");
        str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return str.join(".");
    }

    function storeSelectchange() {
        setTimeout(function () {
            if (storeSelect.length == storeItems.length) {
                storeSelectAll = true;
                document.querySelector("#storeSelectAll").checked =
                    storeSelectAll;
            } else {
                storeSelectAll = false;
                document.querySelector("#storeSelectAll").checked =
                    storeSelectAll;
            }
        }, 0);
    }

    function storeSelectAllClick(event) {
        if (!storeSelectAll) {
            storeSelect = [];
            for (let s = 0; s < storeItems.length; s++) {
                storeSelect.push(storeItems[s].branch_id);
            }
        } else {
            storeSelect = [];
        }
        storeSelectAll = !storeSelectAll;
        setTimeout(() => (event.target.checked = storeSelectAll), 0);
    }
</script>

<div class="title">
    <h2>薪資報表</h2>
</div>

<div class="search">
    <div class="search__con">
        <div class="search__item search--time">
            <p>年份：</p>
            <div class="time--item">
                <div>
                    <select name="year" id="year" bind:value={yearSelect}>
                        {#each yearItems as yearItem, i}
                            {#if yearItem.year <= new Date().getFullYear()}
                                <option value={yearItem.year} id={yearItem.id}
                                    >{yearItem.year}</option
                                >
                            {/if}
                        {/each}
                    </select>
                </div>
            </div>
        </div>
        <div class="search__item search--time">
            <p>月份：</p>
            <div class="time--item">
                <div>
                    <select name="month" id="month" bind:value={monthSelect}>
                        <option value="1" id="1">1</option>
                        <option value="2" id="2">2</option>
                        <option value="3" id="3">3</option>
                        <option value="4" id="4">4</option>
                        <option value="5" id="5">5</option>
                        <option value="6" id="6">6</option>
                        <option value="7" id="7">7</option>
                        <option value="8" id="8">8</option>
                        <option value="9" id="9">9</option>
                        <option value="10" id="10">10</option>
                        <option value="11" id="11">11</option>
                        <option value="12" id="12">12</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="search__item search--people">
            <p>師傅：</p>
            <div>
                {#if getCookie("userRole") == "admin"}
                    {#each masterItems as masterItem, i}
                        <div>
                            <input
                                type="radio"
                                name="master"
                                value={masterItem.id}
                                id={masterItem.name}
                                bind:group={masterSelect}
                            /><label for={masterItem.name}
                                >&nbsp;{masterItem.name}</label
                            >&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                    {/each}
                {:else if getCookie("userRole") == "therapist"}
                    {#each masterItems as masterItem, i}
                        {#if masterItem.id == getCookie("userLineId")}
                            <div>
                                <input
                                    type="radio"
                                    name="master"
                                    value={masterItem.id}
                                    id={masterItem.name}
                                    bind:group={masterSelect}
                                /><label for={masterItem.name}
                                    >&nbsp;{masterItem.name}</label
                                >&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                        {/if}
                    {/each}
                {/if}
            </div>
        </div>
        <div class="search__item search--store">
            <p>分店：</p>
            <div>
                <div>
                    <input
                        type="checkbox"
                        name="storeSelectAll"
                        on:click={storeSelectAllClick}
                        id="storeSelectAll"
                    /><label for="storeSelectAll">&nbsp;全選</label
                    >&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                {#each storeItems as storeItem, i}
                    <div>
                        <input
                            type="checkbox"
                            name="store"
                            value={storeItem.branch_id}
                            id={storeItem.branch}
                            bind:group={storeSelect}
                            on:click={storeSelectchange}
                        /><label for={storeItem.branch}
                            >&nbsp;{storeItem.branch}</label
                        >&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                {/each}
            </div>
        </div>
        <div class="search__item search--button">
            <Button
                color="#fff"
                style="margin: 10px 0px;"
                hoverColor="#7386ed"
                props=""
                bgColor="#7386ed"
                hoverTextColor="#fff"
                on:click={search}
            >
                查詢
            </Button>
        </div>
        <hr />
        <br />
        <br />

        {#if !loadData && !noData}
            <table cellspacing="0" cellpadding="0">
                <tbody>
                    <tr>
                        <td valign="middle"><p>日期</p></td>
                        {#each items as item, i}
                            <td
                                valign="middle"
                                class={item.item_type == "服務"
                                    ? "typeA"
                                    : "typeB"}><p>{items[i].item}</p></td
                            >
                        {/each}
                        <td valign="middle"><p>金額</p></td>
                    </tr>

                    {#each key2 as item, i}
                        <tr>
                            <td>{key2[i][0]}</td>
                            {#each { length: items.length } as _, j}
                                {#if key2[i][1].length > j}
                                    {#if j + 1 == parseInt(key2[i][1][j][0])}
                                        {#if key2[i][1][j][1] == 0}
                                            <td
                                                data-place="{i + 1},{j}"
                                                class="item{j}"
                                            />
                                        {:else}
                                            <td
                                                data-place="{i + 1},{j}"
                                                class="item{j} left"
                                                >{separator(
                                                    key2[i][1][j][1]
                                                )}</td
                                            >
                                        {/if}
                                    {:else}
                                        <td />
                                    {/if}
                                {/if}
                            {/each}
                            <td data-td={i + 1} class="SumByDay left"
                                >{SumByDay(i)}</td
                            >
                        </tr>
                    {/each}

                    <tr>
                        <td valign="bottom"><p>小計</p></td>
                        {#each items as item, i}
                            <td
                                valign="bottom"
                                class="itemTotal left"
                                data-num={i + 1}><p /></td
                            >
                        {/each}
                        <td
                            rowspan="2"
                            valign="bottom"
                            class="total"
                            style="vertical-align: bottom; padding-bottom: 17px;"
                            ><p /></td
                        >
                    </tr>

                    <tr>
                        <td valign="bottom"><p>分類合計</p></td>
                        <td
                            colspan={type1}
                            valign="bottom"
                            class="typeTotal typeTotal1 left"
                            data-num="1"><p /></td
                        >
                        <td
                            colspan={type2}
                            valign="bottom"
                            class="typeTotal typeTotal2 left"
                            data-num="2"><p /></td
                        >
                    </tr>

                    <tr>
                        <td valign="bottom"><p>抽成比例</p></td>
                        <td colspan={type1} valign="bottom"
                            ><p>{servicePercentage}%</p></td
                        >
                        <td colspan={type2} valign="bottom"
                            ><p>{itemPercentage}%</p></td
                        >
                        <td valign="bottom"><p><br /></p></td>
                    </tr>

                    <tr>
                        <td valign="bottom"><p>金額</p></td>
                        <td
                            colspan={type1}
                            valign="bottom"
                            class="getTotal1 left"><p /></td
                        >
                        <td
                            colspan={type2}
                            valign="bottom"
                            class="getTotal2 left"><p /></td
                        >
                        <td valign="bottom" class="getTotal left"><p /></td>
                    </tr>
                </tbody>
            </table>
        {:else if noData}
            <p>無查詢資料</p>
        {:else}{/if}
    </div>
</div>

<style lang="scss">
    @import "./../../components/scss/_font.scss";
    @import "./../../components/scss/_reset.scss";
    .title {
        display: inline-block;
        padding: 0.5rem;
        h2 {
            font-size: 25px;
            font-weight: 500;
            letter-spacing: 1px;
            color: #425271;
            position: relative;
            margin: auto 0 15px 0;
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: #960e1e;
                bottom: -3px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    .search {
        padding: 0.5rem;
        &__item {
            width: 100%;
            display: inline-flex;
            margin: 20px 0;
            > div {
                display: flex;
                flex-wrap: wrap;
                > div {
                    margin: 5px 0;
                    display: flex;
                    align-items: center;
                }
            }
            > p {
                white-space: nowrap;
                margin: 5px 0;
            }
            .time {
                &--item {
                    display: flex;
                    align-items: center;
                    margin: 10px 20px 10px 0;
                }
            }
            &.search--time {
                margin: 0;
                width: 300px;
                align-items: center;
            }
        }
        &--button {
            display: flex;
            justify-content: flex-end;
        }
    }

    table,
    td,
    tr {
        border: 1px solid #ece9e9;
        padding: 15px 3px;
        text-align: center;
        font-size: 15px;
    }

    table {
        width: 100%;
    }

    select {
        width: 200px;
    }

    .typeA {
        background-color: rgb(255 241 121);
    }

    .typeB {
        background-color: rgb(255 177 106);
    }

    .left {
        text-align: right;
    }
</style>
