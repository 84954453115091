<!-- 後台：角色管理列表 /backstage/roles -->

<script>
    import Button from './../../components/Input/Button.svelte';
    import Member from "./../../components/Lightbox/_member.svelte";
    import Black from "./../../components/Lightbox/_black.svelte";
    import axios from 'axios';
	import { onMount } from 'svelte';
    import SveltyPicker from 'svelty-picker'
    import { Datatable, ColumnFilterInputs } from 'svelte-simple-datatables'
    import { link,push } from 'svelte-spa-router'
    import { dayjs } from "svelte-time";
    

	// 表單
    let roles = [];
    let rows;
    let btnParams = 0;
    let userLineId;
    let userPermission = {
		orders: "",
		business_records: "",
		shifts: "",
		clients: "",
		holidays: "",
		dayoff: "",
		roles: "",
		users: "",
	};



    const settings = { 
        columnFilter: false,  
        rowsPerPage: 20,
        scrollY: false,
        sortable: true,
        css: true,
        blocks: {
            searchInput: false, 
            paginationButtons: false,
            paginationRowCount: false,
        },
        labels: {
            previous: '上一頁',
            next: '下一頁',       
        } 
    }
    

    onMount(async () => {
        userLineId = getCookie("userLineId");
        if( userLineId == null || userLineId == '' ){
            push('/backstage/orders/calendar')
        }else{
            if(getCookie("userPermission") !== null && getCookie("userPermission") !== ''){
                userPermission = JSON.parse(getCookie("userPermission"));
                if(userPermission.roles == 0){
                    push('/backstage/orders/calendar')  
                }
            }else{
                push('/backstage/orders/calendar')  
            }
        }

        //店家列表
        await axios({
            method: 'get',
            url: env.BASE_URL + 'api/v1/roles',
            // headers:{},
            }).then(function (res) {
                roles = res.data;
                // console.log(res);
            }).catch(function (err) {
                // console.dir(err);
            }
        );
    });
    
    function getCookie(name) {
		var cookieArr = document.cookie.split(";");
		for(var i = 0; i < cookieArr.length; i++) {
			var cookiePair = cookieArr[i].split("=");
			if(name == cookiePair[0].trim()) {
				return decodeURIComponent(cookiePair[1]);
			}
		}
		return null;
	}

    function changeStatus(id) {
        if (userPermission !== null && userPermission.roles == 2){
            axios({
                method: 'patch', 
                url: env.BASE_URL + `api/v1/roles/${id}/toggle`,
                }).then(function (res) {
                    axios({
                        method: 'get',
                        url: env.BASE_URL + 'api/v1/roles',
                        }).then(function (res) {
                            roles = res.data;
                        }).catch(function (err) {
                            // console.dir(err);
                        }
                    );
                }).catch(function (err) {
                    // console.dir(err);
                }
            );
        }
	}

    function addNewRole(){
        btnParams = 0
        document.querySelector('.black').style.display = 'block'
        document.querySelector('.lightbox--member').style.display = 'inline-block'
    }

    function modifyContent(id){
        btnParams = id
        document.querySelector('.black').style.display = 'block'
        document.querySelector('.lightbox--member').style.display = 'inline-block'
    }

    function afterSaveData(){
        document.querySelector('.lightbox--member').style.display = 'none'
        document.querySelector('.black').style.display = 'none'
        axios({
            method: 'get',
            url: env.BASE_URL + 'api/v1/roles',
            }).then(function (res) {
                roles = res.data;
                // console.log(res);
            }).catch(function (err) {
                // console.dir(err);
            }
        );
    }

    function closeLightbox(){
        // document.querySelector('.lightbox--messageError').style.display = 'none'
        document.querySelector('.black').style.display = 'none'
        document.querySelector('.lightbox--member').style.display = 'none'
    }

    function memberPage(id){
        push(`/backstage/roles/${id}/members`)
    }

    function permissionPage(id){
        push(`/backstage/roles/${id}/permissions`)
    }

</script>

<div class="title">
    <h2>角色管理</h2>
</div>

{#if userPermission !== null && userPermission.roles == 2}
    <div class="btn">
        <div class="btn__con">
            <div class="btn__item">
                <Button
                    style="padding: 10px; margin: 0"
                    hoverColor="#58b957"
                    props=''
                    bgColor = '#58b957'
                    color= "#fff"
                    hoverTextColor="#fff"
                    on:click={addNewRole}   
                    >
                    新增角色
                </Button>
            </div>
        </div>
    </div>
 {/if}

<div class="table">
    <div class="table__con">
        <div class="table__item">
            <Datatable settings={settings} data={roles} bind:dataRows={rows}>
                <thead>
                    <th class="sortable asc desc" data-key="name" style="min-width: 120px;">角色<span/></th>
                    <th class="sortable asc desc" data-key="created_at" style="min-width: 140px;">建立日期<span/></th>
                    <th class="sortable asc desc" data-key="last_modified_at" style="min-width: 140px;">最後修改日期<span/></th>
                    <th class="" style="">狀態<span/></th>
                    <th class="" style="min-width: 300px;">功能<span/></th>
                    <th class="" style="min-width: 90px;">備註<span/></th>
                </thead>
                <tbody>
                {#if rows}
                    {#each $rows as row}
                    <tr>
                        <td on:click={modifyContent(row.id)}>{row.name}</td>
                        <td on:click={modifyContent(row.id)}>{dayjs(row.created_at).format('YYYY-MM-DD')}</td>
                        <td on:click={modifyContent(row.id)}>{dayjs(row.last_modified_at).format('YYYY-MM-DD')}</td>
                        <td>
                            {#if row.active}
                            <Button
                                style="padding: 10px;"
                                hoverColor="#58b957"
                                props= ''
                                bgColor = '#58b957'
                                color= "#FFFFFF"
                                hoverTextColor="#fff"
                                on:click={changeStatus(row.id)}
                                >
                                啟用
                            </Button>
                            {:else}
                            <Button
                                style="padding: 10px;"
                                hoverColor="#f66354"
                                props=''
                                bgColor = '#f66354'
                                color= "#FFFFFF"
                                hoverTextColor="#fff"
                                on:click={changeStatus(row.id)}   
                                >
                                停用
                            </Button>
                            {/if}
                        </td>
                        <td>
                            <!-- <Button
                                style="padding: 10px;"
                                hoverColor="#faa83b"
                                props=''
                                bgColor = '#faa83b'
                                color= "#fff"
                                hoverTextColor="#fff"
                                on:click="{permissionPage(row.id)}"
                                >
                                    權限設定
                            </Button> -->
                            <Button
                                style="padding: 10px;"
                                hoverColor="#faa83b"
                                props=''
                                bgColor = '#faa83b'
                                color= "#fff"
                                hoverTextColor="#fff"
                                on:click="{memberPage(row.id)}"
                                >
                                    查看成員
                            </Button>
                        </td>
                        <td>
                            {row.note}
                        </td>
                    </tr>
                    {/each}
                {/if}
                </tbody>
            </Datatable>
        </div>
    </div>
</div> 

<!-- {#if btnParams !==0 } -->
<Member btnParams={btnParams} on:save={afterSaveData} on:close={closeLightbox}/>
<Black on:black={closeLightbox}/>

<!-- {/if} -->


<style lang="scss">
	@import './../../components/scss/_font.scss';
	@import './../../components/scss/_reset.scss';
    @import './../../components/scss/_datatablesCss.scss';


    .title{
        display: inline-block;
        padding: 0.5rem 0.5rem;
        h2{
            font-size: 25px;
            font-weight: 500;
            letter-spacing: 1px;
            color: #425271;
            position: relative;
            margin: auto 0 15px 0;
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: #960E1E;
                bottom: -3px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    .btn{
        padding: 0.5rem;
        width: calc(100% - 130px);
        display: inline-flex;
        justify-content: flex-end;
        &__item{
            width: 100%;
            display: flex;
            margin: 20px 0;
            > div{
                display: flex;
                flex-wrap: wrap;
            }
            > p{
               white-space: nowrap;
            }
        }
    }

    
    //表單
    table{
        max-width: 1200px;
        margin: auto;
        width: 100%;
    }
    td a{
        color: #fff;
        display: inline-block;
    }
    th {
        padding: 20px 10px 20px 10px;
        text-align: center;
        border-bottom: 1px solid #eee;
        background: #fff;
    }
    th.sortable {
        cursor: pointer;
    }
    th.sortable span {
        padding-right: 16px;
        position: relative;
    }
    th.sortable span:before,
    th.sortable span:after {
        border: 4px solid transparent;
        content: '';
        display: block;
        height: 0;
        right: 0;
        top: 50%;
        position: absolute;
        width: 0;
    }
    th.sortable span:before {
        border-bottom-color: #e0e0e0;
        margin-top: -9px;
    }
    th.sortable span:after {
        border-top-color: #e0e0e0;
        margin-top: 1px;
    }
    th.sortable.asc:not(.desc) span:before {
        border-bottom-color: #9e9e9e;
    }
    th.sortable.desc:not(.asc) span:after {
        border-top-color: #9e9e9e;
    }

    td{text-align:center; padding:4px 0; word-break: break-all; cursor: pointer;}

</style>