
<script>
    import { link , push } from 'svelte-spa-router'
    import axios from 'axios';
	import { onMount } from 'svelte';
    import Button from './../../components/Input/Button.svelte';
    import MessageError from "./../../components/Lightbox/_messageError.svelte";
    import MessageSuccess from "./../../components/Lightbox/_messageSuccess.svelte";
    import Black from "./../../components/Lightbox/_black.svelte";

    export let params = {}
    let userLineId = "";
    let userPermission = {
        orders: "",
        business_records: "",
        shifts: "",
        clients: "",
        holidays: "",
        dayoff: "",
        roles: "",
        users: "",
    };
    let clients = 
        {
            name: '',    
            gender: '男',    
            area: '',
            mobile: '',
            email: '',
            note: '',
        };
        
    let orders = [];
    let message = '';

    $: clients.mobile !== '' && clients.mobile.length > 4  ? addDash() : addDash()

    onMount(async () => {
        userLineId = getCookie("userLineId");
        if( userLineId == null || userLineId == '' ){
            push('/backstage/orders/calendar')
        }else{
            if(getCookie("userPermission") !== null && getCookie("userPermission") !== ''){
                userPermission = JSON.parse(getCookie("userPermission"));
                if(userPermission.clients !== 2){
                    push('/backstage/orders/calendar')  
                }
            }else{
                push('/backstage/orders/calendar')  
            }
        }
    })
        
    
    function save(){
        if(clients.name.replaceAll(' ','') == ''){
            alert('姓名為必填！')
        }else if(clients.area.replaceAll(' ','') == ''){
            alert('居住地區為必填！')
        }else if(clients.mobile.replaceAll(' ','') == ''){
            alert('手機號碼為必填！')
        }else if(!(/09\d{2}-\d{3}\d{3}/.test(clients.mobile))){
            alert('手機號碼格式不對！')
        }else{
            axios({
                method: 'post',
                url: env.BASE_URL + `api/v1/clients/new`,
                data:{
                    name: clients.name.replaceAll(' ',''),    
                    gender: clients.gender,    
                    area: clients.area.replaceAll(' ',''),
                    mobile: clients.mobile.replaceAll(' ',''),
                    note: clients.note.replaceAll(' ',''),
                }
                }).then(function (res) {
                    if(res.data.status == 'successful'){
                        message = '修改成功！'
                        success();
                    }else{
                        message = res.data.message
                        fail();
                    }
                    // console.log(res);
                }).catch(function (err) {
                    // console.dir(err);
                }
            );
        }
    }

    function addDash(){
        if(clients.mobile.replaceAll(' ','') !== '' && clients.mobile.replaceAll(' ','').length > 5){
            if(clients.mobile.indexOf('-')==-1){
                let mobile2 = clients.mobile.substring(4,clients.mobile.length)
                clients.mobile = clients.mobile.replaceAll(' ','').substring(0,4) + '-' + mobile2
            }
        }else{
            clients.mobile.replaceAll('-','')
        }
    }

    function success(){
        document.querySelector('.black').style.display = 'block'
        document.querySelector('.lightbox--messageSuccess').style.display = 'inline-block'
        setTimeout( function(){
            document.querySelector('.lightbox--messageSuccess').style.display = 'none'
            document.querySelector('.black').style.display = 'none'
            push('/backstage/clients')
        },2000)
    }

    function fail(){
        document.querySelector('.lightbox--messageError').style.display = 'inline-block'
        document.querySelector('.black').style.display = 'block'
    }

    function closeLightbox(){
        document.querySelector('.lightbox--messageError').style.display = 'none'
        document.querySelector('.black').style.display = 'none'
    }

    function getCookie(name) {
		var cookieArr = document.cookie.split(";");
		for(var i = 0; i < cookieArr.length; i++) {
			var cookiePair = cookieArr[i].split("=");
			if(name == cookiePair[0].trim()) {
				return decodeURIComponent(cookiePair[1]);
			}
		}
		return null;
	}

</script>

<div class="title">
    <h2>客戶明細</h2>
</div>


<div class="form">
    <div class="form__con">
        <div class="form__item">
            <p><span>*</span> 姓名：</p><input type="text" bind:value="{clients.name}" >
        </div>
        <div class="form__item form__item--gender">
            <p><span>*</span> 性別：</p>
            <label for="man">男
                <input type="radio" value='男' bind:group={clients.gender} id="man" name="gender" >
            </label>
            &nbsp;  &nbsp;  &nbsp;
            <label for="woman">女
                <input type="radio"  value='女' bind:group={clients.gender} id="woman" name="gender" >
            </label>
        </div>
        <div class="form__item">
            <p><span>*</span> 地區：</p><input type="text" bind:value="{clients.area}" >
        </div>
        <div class="form__item">
            <p><span>*</span> 電話：</p><input type="text" bind:value="{clients.mobile}" >
        </div>
        <div class="form__item form__item--textarea">
            <p>備註：</p><textarea rows="5" cols="50"  bind:value="{clients.note}" />
        </div>
    </div>
</div>

<div class="btn">
    <div class="btn__con">
        <Button
        style="margin: 10px 0 10px 0;"
        hoverColor="#44abf1"
        props=''
        color= "#fff"
        bgColor = '#44abf1'
        hoverTextColor="#fff"
        on:click={save} 
    >
    儲存
    </Button>
    </div>
</div>

<MessageSuccess {message} />
<MessageError {message} on:close={closeLightbox}/>
<Black on:black={closeLightbox} />



<style lang="scss">
    @import './../../components/scss/_reset.scss';
    @import './../../components/scss/_font.scss';


    .title{
        // display: inline-block;
        max-width: 1000px;
        margin: auto;
        h2{
            font-size: 25px;
            font-weight: 500;
            letter-spacing: 1px;
            color: #425271;
            position: relative;
            margin: auto 0 15px 0px;
            display: inline-block;
            &::after{
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: #960E1E;
                bottom: -3px;
                left: 52px;
                transform: translateX(-50%);
            }
        }
    }
    .form{
        max-width: 1000px;
        margin: auto;
        &__con{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
        &__item{
            width: 50%;
            display: flex;
            align-items: center;
            margin: 5px 0;
            position: relative;
            input{
                width: calc(100% - 110px);
                margin: 10px;
            }
            p{
                margin: 0;
                width: 60px;
                span{
                    color: red;
                }
            }
            textarea{
                resize: none;
                width: calc(100% - 110px);
            }
            &--gender{
                label{
                    margin: 0 5px 0 10px;
                    display: inline-flex;
                    align-items: center;
                    width: 110px;
                }
                input{
                    width: 30px;
                    margin: 5px;
                }
            }
            &--textarea{
                width: 100%;
                p{
                    width: 70px;
                }
            }
            &--status{
                padding-left: 30px;
                width: calc(50% + 30px);
            }
            &--email{
                width: calc(50% - 30px);
                p{
                    width: 90px;
                }
            }
        }
    }

    .btn{
        max-width: 1000px;
        padding: 0 40px 0 0;
        margin: 10px auto 30px;
        width: 100%;
        display: block;
        &__con{
            display: flex;
            justify-content: flex-end;
        }
    }

</style>