<script>
	import Navbar from "./components/Navigation/index.svelte";
	import Footer from "./components/Footer/index.svelte";
  
	import Router from "svelte-spa-router";
	import routes from "./routes";
	
	let back;
	let href;
  
	function routeLoading(event) {
	  // 後台才會有header/footer的判斷
	  // 後台才需要的layout
	  if (location.href.includes("backstage")) {
		back = true;
	  } else {
		back = false;
	  }
	  // end
  
	  href = event.detail.route;
  
	  // console.log('routeLoading event')
	  // console.log('Route', event.detail.route)
	  // console.log('Location', event.detail.location)
	  // console.log('Querystring', event.detail.querystring)
	  // console.log('User data', event.detail.userData)
	}
  
  </script>
  
  <main class:backstage={back} class:front={!back}>
	<Router {routes} on:routeLoading={routeLoading} />
  
	<!-- 屬於後台才需要的layout -->
	{#if back}
	  <Navbar pageLocation={href} />
	  <Footer />
	{/if}
	<!-- 屬於後台才需要的layout end -->
  </main>
  
  <style lang="scss">
	@import "./components/scss/_reset.scss";
  
	:global(body) {
	  position: relative;
	  padding: 0;
	}
  
	:global(.fc .fc-button) {
	  color: #fff;
	  background-color: #4582ec !important;
	  border-color: #4582ec !important;
	  border-radius: 1.078em !important;
	  padding: 0.5rem 1.1rem !important;
	}
  
	:global(.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child)) {
	  border-top-right-radius: 0 !important;
	  border-bottom-right-radius: 0 !important;
	}
  
	:global(.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child)) {
	  border-top-left-radius: 0 !important;
	  border-bottom-left-radius: 0 !important;
	}
  
	:global(.fc .fc-daygrid-day.fc-day-today) {
	  background-color: rgba(115, 134, 237, 0.565) !important;
	}
  
	:global(.fc-theme-standard th) {
	  background: rgb(88 185 87 / 56%) !important;
	  line-height: 30px !important;
	}
  
	:global(th.fc-day-sat, th.fc-day-sun) {
	  background: rgb(250 168 59 / 56%) !important;
	}
  
	:global(td.fc-day-sun) {
	  background: rgba(254, 211, 50, 0.565) !important;
	}
  
	:global(td.fc-day-sat) {
	  background: rgba(254, 211, 50, 0.565) !important;
	}
  
	:global(a:hover) {
	  color: inherit !important;
	  opacity: 0.8 !important;
	}
  
	:global(.fc-col-header-cell a, .fc-daygrid-day-number) {
	  color: #555;
	}
  
	:global(.fc-h-event .fc-event-main) {
	  padding: 0px 15px;
	  cursor: pointer;
	  font-weight: 600;
	  font-size: 15px;
	}
  
	:global(.button--cancel) {
	  border: solid 2px #f66354;
	  background-color: #f66354;
	  border-radius: 20px;
	  color: #fff;
	  min-width: 120px;
	  transition: all 0.2s;
	  padding: 10px 20px;
	  margin: 10px auto;
	  font-size: 16px;
	  cursor: pointer;
	  display: block;
	}
  
	:global(.button--cancel:hover) {
	  border: solid 2px #f66354;
	  background-color: #f66354;
	  border-radius: 20px;
	  color: #fff;
	}
  
	:global(.button--doneButNoPay) {
	  border: solid 2px #7386ed;
	  background-color: #7386ed;
	  border-radius: 20px;
	  color: #fff;
	  min-width: 120px;
	  transition: all 0.2s;
	  padding: 10px 20px;
	  margin: 10px auto;
	  font-size: 16px;
	  cursor: pointer;
	  display: block;
	}
  
	:global(.button--doneButNoPay:hover) {
	  border: solid 2px #7386ed;
	  background-color: #7386ed;
	  border-radius: 20px;
	  color: #fff;
	}
  
	:global(.button--done) {
	  border: solid 2px #58b957;
	  background-color: #58b957;
	  border-radius: 20px;
	  color: #fff;
	  min-width: 120px;
	  transition: all 0.2s;
	  padding: 10px 20px;
	  margin: 10px auto;
	  font-size: 16px;
	  cursor: pointer;
	  display: block;
	}
  
	:global(.button--done:hover) {
	  border: solid 2px #58b957;
	  background-color: #58b957;
	  border-radius: 20px;
	  color: #fff;
	}
  
	:global(.button--recover) {
	  border: solid 2px #faa83b;
	  background-color: #faa83b;
	  border-radius: 20px;
	  color: #fff;
	  min-width: 120px;
	  transition: all 0.2s;
	  padding: 10px 20px;
	  margin: 10px auto;
	  font-size: 16px;
	  cursor: pointer;
	  display: block;
	}
  
	:global(.button--recover:hover) {
	  border: solid 2px #faa83b;
	  background-color: #faa83b;
	  border-radius: 20px;
	  color: #fff;
	}
  
	main.front {
	  // padding: 15px;
	  padding: 0px 15px;
	  min-height: 100vh;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	}
  
	main.backstage {
	  padding: 100px 5% 200px;
	  min-height: 100vh;
	  position: relative;
	  margin: auto;
	  box-sizing: border-box;
	  @media screen and(min-width: 1100px) {
		padding: 100px 120px 200px;
	  }
	}
  
	:global(*) {
	  // outline: 1px solid #f00;
	}
  </style>
  