<!-- 後台：預約管理修改 /backstage/orders/<order_id> -->
<script>
    import Button from "./../../components/Input/Button.svelte";
    import axios from "axios";
    import { onMount } from "svelte";
    import SveltyPicker from "svelty-picker";
    import { link, push } from "svelte-spa-router";
    import MessageError from "./../../components/Lightbox/_messageError.svelte";
    import MessageSuccess from "./../../components/Lightbox/_messageSuccess.svelte";
    import Black from "./../../components/Lightbox/_black.svelte";

    export let params = {};
    let message = "";
    let userLineId;
    let userPermission = {
        orders: "",
        business_records: "",
        shifts: "",
        clients: "",
        holidays: "",
        dayoff: "",
        roles: "",
        users: "",
    };
    let orders = [{ balance: "", client: "", amounts: [], item_ids: [] }];
    let copyOthersDetails = [],
        copyFirstDetail,
        showTimeHour;

    $: orders.balance !== "" ? addDot() : addDot();

    // 時間
    let dateStart = "";
    let date = new Date();

    // 分店
    let storeItems = [];
    let storeSelect = [];

    // 師傅
    let masterItems = [];
    let masterSelect = [];

    //服務
    let items = [];
    let itemSelect = [];

    //搜尋
    let names = [];
    let nameSelect = [];
    let tel = "";

    //時間
    let timeSelect = [];
    let time__hour, time__min, time__period;

    //追加項目
    let details = [];

    // status
    let status = "";

    onMount(async () => {
        userLineId = getCookie("userLineId");
        if (userLineId == null || userLineId == "") {
            push("/backstage/orders/calendar");
        } else {
            if (
                getCookie("userPermission") !== null &&
                getCookie("userPermission") !== ""
            ) {
                userPermission = JSON.parse(getCookie("userPermission"));
                if (userPermission.orders == 0) {
                    push("/backstage/orders/calendar");
                }
            } else {
                push("/backstage/orders/calendar");
            }
        }

        //店家列表
        await axios({
            method: "get",
            url: env.BASE_URL + "api/v1/branches",
        })
            .then(function (res) {
                storeItems = res.data;
                // console.log(res);
            })
            .catch(function (err) {
                console.dir(err);
                // push('/error')
            });

        //師傅列表
        await axios({
            method: "get",
            url: env.BASE_URL + "api/v1/users",
        })
            .then(function (res) {
                masterItems = res.data;
                // console.log(res);
            })
            .catch(function (err) {
                console.dir(err);
                // push('/error')
            });

        //服務列表
        await axios({
            method: "get",
            url: env.BASE_URL + "api/v1/items",
        })
            .then(function (res) {
                items = res.data;
            })
            .catch(function (err) {
                console.dir(err);
                // push('/error')
            });

        await axios({
            method: "get",
            url: env.BASE_URL + `api/v1/orders/${params.order_id}/`,
        })
            .then(function (res) {
                orders = res.data;
                orders.ui_day = orders.ui_day.replaceAll("/", "-");
                copyFirstDetail = JSON.parse(
                    JSON.stringify(orders.details[0].item)
                );
                time__hour = orders.ui_time.substring(0, 2);
                time__min = orders.ui_time.substring(3, 5);
                if (orders.ui_time.indexOf("PM") !== -1) {
                    time__period = "PM";
                } else {
                    time__period = "AM";
                }
                status = orders.status;
                orders.balance = separator(orders.balance);
                //追加項目
                copyOthersDetails = orders.details;
                if (copyOthersDetails.length == 1) {
                    copyOthersDetails = [];
                } else {
                    copyOthersDetails.shift();
                }
            })
            .catch(function (err) {
                console.dir(err);
                // push('/error')
            });
    });

    function getCookie(name) {
        var cookieArr = document.cookie.split(";");
        for (var i = 0; i < cookieArr.length; i++) {
            var cookiePair = cookieArr[i].split("=");
            if (name == cookiePair[0].trim()) {
                return decodeURIComponent(cookiePair[1]);
            }
        }
        return null;
    }

    function save() {
        if (status !== "完成未付清") {
            orders.balance = 0;
        }

        orders.item_ids = [];

        for (let r = 0; r < items.length; r++) {
            if (items[r].item == copyFirstDetail) {
                orders.item_ids.push(items[r].item_id);
            }
        }

        for (let i = 0; i < copyOthersDetails.length; i++) {
            orders.item_ids.push(copyOthersDetails[i].item_id);
        }

        orders.amounts = [1];

        for (let i = 0; i < copyOthersDetails.length; i++) {
            orders.amounts.push(copyOthersDetails[i].amount);
        }

        for (let b = 0; b < storeItems.length; b++) {
            if (storeItems[b].branch == orders.branch) {
                orders.branch_id = storeItems[b].branch_id;
            }
        }

        showTimeHour = 0;

        if (time__period == "PM") {
            showTimeHour = parseInt(time__hour) + 12;
            orders.ordered_at = `${
                orders.ui_day + " " + showTimeHour + ":" + time__min + ":00"
            }`;
        } else {
            orders.ordered_at = `${
                orders.ui_day + " " + time__hour + ":" + time__min + ":00"
            }`;
        }

        if (
            status == "完成未付清" &&
            parseInt(orders.balance.replaceAll(",", "")) == 0
        ) {
            alert("未付清欄位不得為零");
        } else {
            if (orders.client_id == "") {
                alert("客戶為必填！");
            } else if (orders.branch_id == "") {
                alert("分店為必填！");
            } else if (orders.item_id == "") {
                alert("服務為必填！");
            } else if (orders.user_id == "") {
                alert("師傅為必填！");
            } else if (orders.ui_day == "") {
                alert("日期為必填！");
            } else if (orders.ui_day == null) {
                alert("日期為必填！");
            } else {
                axios({
                    method: "put",
                    url:
                        env.BASE_URL +
                        `api/v1/orders/${params.order_id}/login_user/${userLineId}`,
                    data: {
                        client_id: orders.client_id,
                        user_id: orders.user_id,
                        branch_id: orders.branch_id,
                        item_ids: orders.item_ids,
                        amounts: orders.amounts,
                        balance:
                            status == "完成未付清"
                                ? parseInt(orders.balance.replaceAll(",", ""))
                                : 0,
                        ordered_at: orders.ordered_at,
                        status: status,
                        note: orders.note.replaceAll(" ", ""),
                    },
                })
                    .then(function (res) {
                        if (res.data.status == "successful") {
                            message = res.data.message;
                            success();
                        } else {
                            message = res.data.message;
                            fail();
                        }
                    })
                    .catch(function (err) {
                        message = res.data.message;
                        console.dir(err);
                        // push('/error')
                    });
            }
        }
    }

    function separator(numb) {
        var str = numb.toString().split(".");
        str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return str.join(".");
    }

    function addDot() {
        let num;
        if (orders.balance !== undefined && orders.balance !== "") {
            if (orders.balance !== 0) {
                num = orders.balance.replaceAll(",", "");
                var result = [],
                    counter = 0;
                num = (num || 0).toString().split("");
                for (var i = num.length - 1; i >= 0; i--) {
                    counter++;
                    result.unshift(num[i]);
                    if (!(counter % 3) && i != 0) {
                        result.unshift(",");
                    }
                }
                orders.balance = result.join("");
            }
        } else {
        }
    }

    function success() {
        message = "修改成功！";
        document.querySelector(".black").style.display = "block";
        document.querySelector(".lightbox--messageSuccess").style.display =
            "inline-block";
        setTimeout(function () {
            document.querySelector(".lightbox--messageSuccess").style.display =
                "none";
            document.querySelector(".black").style.display = "none";
        }, 2000);
    }

    function fail() {
        document.querySelector(".lightbox--messageError").style.display =
            "inline-block";
        document.querySelector(".black").style.display = "block";
    }

    function itemDelete(n) {
        for (let i = 0; i < copyOthersDetails.length; i++) {
            if (copyOthersDetails[i].orders_detail_id == n) {
                copyOthersDetails = [
                    ...copyOthersDetails.slice(0, i),
                    ...copyOthersDetails.slice(i + 1),
                ];
            }
        }
    }

    function newItem() {
        if (copyOthersDetails.length == 0) {
            copyOthersDetails.push({
                orders_detail_id: 1,
                item_id: 1,
                amount: 1,
            });
        } else {
            copyOthersDetails.push({
                orders_detail_id:
                    copyOthersDetails[copyOthersDetails.length - 1]
                        .orders_detail_id + 1,
                item_id: 1,
                amount: 1,
            });
        }
        copyOthersDetails = [...copyOthersDetails];
    }

    function closeLightbox() {
        document.querySelector(".lightbox--messageError").style.display =
            "none";
        document.querySelector(".black").style.display = "none";
    }
</script>

<div class="order">
    <div class="order__con">
        <div class="order__item">
            <h2>預約資訊</h2>
        </div>
        <div class="order__item order--store">
            <p>客戶： {orders.client}</p>
        </div>
        <div class="order__item order--store">
            <p>分店：</p>
            <div>
                {#each storeItems as storeItem, i}
                    <div>
                        <input
                            type="radio"
                            name="shop"
                            value={storeItem.branch}
                            id={storeItem.branch}
                            bind:group={orders.branch}
                        /><label for={storeItem.branch}
                            >&nbsp;{storeItem.branch}</label
                        >&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                {/each}
            </div>
        </div>
        <div class="order__item order--items">
            <p>服務：</p>
            <div>
                {#each items as item, i}
                    <div>
                        <input
                            type="radio"
                            name="item"
                            value={item.item}
                            id={item.item}
                            bind:group={copyFirstDetail}
                            class={item.item_type == "服務" ? "" : "typeItem"}
                        /><label
                            for={item.item}
                            class={item.item_type == "服務" ? "" : "typeItem"}
                            >&nbsp;{item.item}</label
                        >&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                {/each}
            </div>
        </div>
        <span class="order--ps">* 選取商品不發送簡訊</span>
        <div class="order__item order--people">
            <p>師傅：</p>
            <div>
                {#each masterItems as masterItem, i}
                    <div>
                        <input
                            type="radio"
                            name="master"
                            value={masterItem.id}
                            id={masterItem.name}
                            bind:group={orders.user_id}
                        /><label for={masterItem.name}
                            >&nbsp;{masterItem.name}</label
                        >&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                {/each}
            </div>
        </div>
        <div class="order__item order--time">
            <p>預約時段：</p>
            <div class="time--item">
                <p>日期：</p>
                <SveltyPicker format="yyyy-mm-dd" bind:value={orders.ui_day} />
            </div>
            <div class="time--item time--time">
                <p>時間：</p>
                <select
                    name="time__hour"
                    id="time__hour"
                    bind:value={time__hour}
                >
                    <option value="01" id="1">&nbsp; 1</option>
                    <option value="02" id="2">&nbsp; 2</option>
                    <option value="03" id="3">&nbsp; 3</option>
                    <option value="04" id="4">&nbsp; 4</option>
                    <option value="05" id="5">&nbsp; 5</option>
                    <option value="06" id="6">&nbsp; 6</option>
                    <option value="07" id="7">&nbsp; 7</option>
                    <option value="08" id="8">&nbsp; 8</option>
                    <option value="09" id="9">&nbsp; 9</option>
                    <option value="10" id="10">&nbsp; 10</option>
                    <option value="11" id="11">&nbsp; 11</option>
                    <option value="12" id="12">&nbsp; 12</option>
                </select>

                <select name="time__min" id="time__min" bind:value={time__min}>
                    <option value="00" id="1">&nbsp; 00</option>
                    <option value="10" id="2">&nbsp; 10</option>
                    <option value="20" id="3">&nbsp; 20</option>
                    <option value="30" id="4">&nbsp; 30</option>
                    <option value="40" id="5">&nbsp; 40</option>
                    <option value="50" id="6">&nbsp; 50</option>
                </select>

                <select
                    name="time__period"
                    id="time__period"
                    bind:value={time__period}
                >
                    <option value="AM" id="1">&nbsp; AM</option>
                    <option value="PM" id="2">&nbsp; PM</option>
                </select>
            </div>
        </div>
        <div class="order__item order--option">
            <p>狀態：</p>
            {#if status == "完成未付清"}
                <select name="status" id="status" bind:value={status}>
                    <option value="預約中" id="1">預約中</option>
                    <option value="完成" id="2">完成</option>
                    <option value="完成未付清" id="3">完成未付清</option>
                    <option value="取消" id="4" disabled>取消</option>
                </select>
            {:else if status == "預約中"}
                <select name="status" id="status" bind:value={status}>
                    <option value="預約中" id="1">預約中</option>
                    <option value="完成" id="2">完成</option>
                    <option value="完成未付清" id="3">完成未付清</option>
                    <option value="取消" id="4">取消</option>
                </select>
            {:else if status == "完成"}
                <select name="status" id="status" bind:value={status}>
                    <option value="預約中" id="1">預約中</option>
                    <option value="完成" id="2">完成</option>
                    <option value="完成未付清" id="3">完成未付清</option>
                    <option value="取消" id="4" disabled>取消</option>
                </select>
            {:else if status == "取消"}
                <select name="status" id="status" bind:value={status}>
                    <option value="預約中" id="1">預約中</option>
                    <option value="完成" id="2" disabled>完成</option>
                    <option value="完成未付清" id="3" disabled
                        >完成未付清</option
                    >
                    <option value="取消" id="4">取消</option>
                </select>
            {/if}

            {#if status == "完成未付清"}
                <div class="order__item order--balance">
                    <p>&nbsp;&nbsp;&nbsp; 未付清欄位：</p>
                    <input
                        type="text"
                        bind:value={orders.balance}
                        style="text-align: right;"
                    />&nbsp;&nbsp;元
                </div>
            {/if}
        </div>
        <div class="order__item order--note">
            <p>備註：</p>
            <textarea
                name=""
                id=""
                rows="5"
                cols="50"
                bind:value={orders.note}
            />
        </div>
        <br />
        <hr />
        <br />
        <div class="order__item order--others">
            <h2>追加項目</h2>
            {#if copyOthersDetails.length !== 0}
                <div>
                    {#each copyOthersDetails as detail, i}
                        <div>
                            <p>項目：</p>
                            <select
                                name="time"
                                id="time"
                                bind:value={detail.item_id}
                            >
                                {#each items as item, i}
                                    <option
                                        value={item.item_id}
                                        id={item.item_id}
                                        >&nbsp;{item.item}</option
                                    >
                                {/each}
                            </select>
                            &nbsp; &nbsp; &nbsp;
                            <p>數量：</p>
                            <div>
                                <input
                                    type="number"
                                    bind:value={detail.amount}
                                />
                            </div>
                            &nbsp; &nbsp; &nbsp;
                            <div style="text-align: center;">
                                <Button
                                    style=""
                                    hoverColor="#f66354"
                                    props=""
                                    bgColor="#f66354"
                                    color="#FFFFFF"
                                    hoverTextColor="#fff"
                                    on:click={itemDelete(
                                        detail.orders_detail_id
                                    )}
                                >
                                    刪除
                                </Button>
                            </div>
                        </div>
                    {/each}
                </div>
            {/if}
            <div class="btn btn--add">
                <Button
                    style="margin: 10px 0px 10px"
                    hoverColor="#58b957"
                    props=""
                    bgColor="#58b957"
                    color="#FFFFFF"
                    hoverTextColor="#fff"
                    on:click={newItem}
                >
                    ＋
                </Button>
            </div>
        </div>
        <br />
        {#if userPermission !== null && userPermission.orders == 2}
            <hr />
            <br />
            <div class="order__item order--button">
                <button class="button1" on:click={save}> 儲存 </button>
            </div>
        {/if}
    </div>
</div>

<MessageSuccess {message} />
<MessageError {message} on:close={closeLightbox} />
<Black on:black={closeLightbox} />

<style lang="scss">
    @import "./../../components/scss/_font.scss";
    @import "./../../components/scss/_reset.scss";

    .search {
        padding: 0.5rem;
        &__con {
            display: flex;
            flex-wrap: wrap;
        }
        &__item {
            display: flex;
            align-items: center;
            margin: 20px 0;
            > div {
                display: flex;
                flex-wrap: wrap;
            }
            > p {
                white-space: nowrap;
            }
            input {
                width: 200px;
                margin-right: 20px;
            }
            h2 {
                font-size: 25px;
                font-weight: 500;
                letter-spacing: 1px;
                color: #425271;
                position: relative;
                margin: auto 0 15px 0;
                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background-color: #960e1e;
                    bottom: -3px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            select {
                width: 190px;
                height: 38.78px;
            }
        }
        &--title {
            width: 100%;
            margin: 0;
        }
        &--button {
            width: calc(100% - 660px);
            display: flex;
            justify-content: flex-end;
        }
    }

    .order {
        padding: 0.5rem;
        &--ps {
            color: #f00;
        }
        .typeItem {
            accent-color: #f00;
            color: #f00;
        }
        &__item {
            width: 100%;
            display: flex;
            margin: 20px 0;
            > div {
                display: flex;
                flex-wrap: wrap;
                > div {
                    margin: 5px 0;
                    display: flex;
                    align-items: center;
                    input {
                        margin: 0;
                    }
                }
            }
            > p {
                white-space: nowrap;
                margin: 5px 0;
            }
            h2 {
                font-size: 25px;
                font-weight: 500;
                letter-spacing: 1px;
                color: #425271;
                position: relative;
                margin: auto 0 15px 0;
                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background-color: #960e1e;
                    bottom: -3px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            .time {
                &--item {
                    justify-content: center;
                    display: flex;
                    align-items: center;
                    margin: 10px 0 10px 20px;
                    p {
                        color: #444;
                        font-size: 15px;
                    }
                    select {
                        width: 190px;
                        height: 40px;
                    }
                    input {
                        height: 40px;
                        margin-right: 20px;
                    }
                }
                &--time {
                    width: auto;
                    > select {
                        margin-right: 10px;
                        width: 100px;
                    }
                }
            }
            &.order--time {
                margin: 0;
            }
        }
        &--button {
            width: fit-content;
            display: flex;
            margin: 20px 0 20px auto;
        }
        &--option {
            align-items: center;
        }
        &--time {
            display: flex;
            align-items: center;
        }
        &--title {
            width: 100%;
            margin: 0;
        }
        &--note {
            textarea {
                width: 100%;
                resize: none;
            }
        }
        &--balance {
            width: 450px;
            margin: 0;
            margin-left: 20px;
            align-items: center;
        }
        &--others {
            display: block;
            h2 {
                display: inline-block;
            }
            > div {
                > div {
                    width: 100%;
                }
            }
            select {
                width: 200px;
            }
        }
    }

    .button1 {
        border: solid 2px #44abf1;
        background-color: #44abf1;
        border-radius: 20px;
        color: #fff;
        min-width: 120px;
        transition: all 0.2s;
        padding: 10px 20px;
        margin: 10px;
        margin-left: 10px;
        font-size: 16px;
        cursor: pointer;
        display: block;
    }

    input {
        margin: 0;
    }
</style>
