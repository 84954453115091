import Home from "./routes/Home.svelte";
import Logout from "./routes/Logout.svelte";
import NotFound from "./routes/NotFound.svelte";

import Branches from "./routes/branches/Branches.svelte"

import Orders_clientId from "./routes/orders/Orders_clientId.svelte";
import Orders_clientId_new from "./routes/orders/Orders_clientId_new.svelte";

import Backstage_Orders_calendar from "./routes/orders/Backstage_Orders_calendar.svelte";
import Backstage_Orders from "./routes/orders/Backstage_Orders.svelte";
import Backstage_Orders_salary from "./routes/orders/Backstage_Orders_salary.svelte";
import Backstage_Orders_new from "./routes/orders/Backstage_Orders_new.svelte";
import Backstage_Orders_orderId from "./routes/orders/Backstage_Orders_orderId.svelte";

import Backstage_Revenu_calendar from "./routes/revenue/Backstage_Revenu_calendar.svelte";
import Backstage_Revenue from "./routes/revenue/Backstage_Revenue.svelte";

import Backstage_Shifts from "./routes/shifts/Backstage_Shifts.svelte";
import Backstage_Shifts_year_month_userId from "./routes/shifts/Backstage_Shifts_year_month_userId.svelte";

import Backstage_Holidays from "./routes/holidays/Backstage_Holidays.svelte";

import Clients_new from "./routes/clients/Clients_new.svelte";
import Backstage_Clients from "./routes/clients/Backstage_Clients.svelte";
import Backstage_Clients_new from "./routes/clients/Backstage_Clients_new.svelte";
import Backstage_Clients_clientId from "./routes/clients/Backstage_Clients_clientId.svelte";

import Backstage_Roles from "./routes/roles/Backstage_Roles.svelte";
import Backstage_Roles_roleId_permissions from "./routes/roles/Backstage_Roles_roleId_permissions.svelte";
import Backstage_Roles_roleId_members from "./routes/roles/Backstage_Roles_roleId_members.svelte";

import Backstage_Users from "./routes/users/Backstage_Users.svelte";
import Backstage_Users_new from "./routes/users/Backstage_Users_new.svelte";
import Backstage_Users_userId from "./routes/users/Backstage_Users_userId.svelte";

export default {
  "/": Home,
  "/logout": Logout,
  "/error": NotFound,

  "/branches": Branches,  // 前台：顯示各分店資訊

  "/orders/:client_id": Orders_clientId, // 前台：預約查詢
  "/orders/:client_id/new": Orders_clientId_new, // 前台：預約新增

  "/backstage/orders/calendar": Backstage_Orders_calendar, // 後台：預約管理月曆
  "/backstage/orders": Backstage_Orders, // 後台：預約管理列表
  "/backstage/orders/salary": Backstage_Orders_salary, // 後台：薪資報表
  "/backstage/orders/new": Backstage_Orders_new, // 後台：預約管理新增
  "/backstage/orders/:order_id": Backstage_Orders_orderId, // 後台：預約管理修改
  
  "/backstage/revenue/calendar": Backstage_Revenu_calendar, // 後台：營業紀錄月曆
  "/backstage/revenue": Backstage_Revenue, // 後台：營業紀錄列表

  "/backstage/shifts": Backstage_Shifts, // 後台：排班管理列表
  "/backstage/shifts/:year/:month/:user_id": Backstage_Shifts_year_month_userId, //後台：排班管理明細

  "/backstage/holidays": Backstage_Holidays, //後台：假日/公休管理

  "/clients/new": Clients_new, // 前台：客戶註冊
  "/backstage/clients": Backstage_Clients, // 後台：客戶管理列表
  "/backstage/clients/new": Backstage_Clients_new, // 後台：客戶管理新增
  "/backstage/clients/:client_id": Backstage_Clients_clientId, // 後台：客戶管理修改

  "/backstage/roles": Backstage_Roles, // 後台：角色管理列表
  "/backstage/roles/:role_id/permissions": Backstage_Roles_roleId_permissions, // 後台：角色管理權限明細
  "/backstage/roles/:role_id/members": Backstage_Roles_roleId_members, // 後台：角色管理成員明細

  "/backstage/users": Backstage_Users, // 後台：帳號管理列表
  "/backstage/users/new": Backstage_Users_new, // 後台：帳號管理新增
  "/backstage/users/:user_id": Backstage_Users_userId, // 後台：帳號管理修改

  "*": NotFound,
};
