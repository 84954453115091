<footer>
  <p>
    地址：242新北市新莊區中正路516-14號之一<br>電話：0980-987-441
  </p>
</footer>

<style lang="scss">
  @import './../../components/scss/_font.scss';
	@import './../../components/scss/_reset.scss';

  footer{
    background-color: #F6F6F6;
    box-shadow: 0px 0px 5px #c5c5c5;
    text-align: center;
    position: absolute;
    width: 100vw;
    padding: 20px;
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    p{
      font-family: 'Noto Sans TC', sans-serif;
			letter-spacing: 1px;
      font-size: 13px;
      line-height: 20px;
    }
  }
</style>


