<script>
    import { createEventDispatcher } from "svelte";
    const dispatch = createEventDispatcher()
    function clickBlack(){
        dispatch('black')
    }
</script>

<div class="black" on:click="{clickBlack}">
   
</div>


<style lang="scss">
    .black{
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0,0.4);
            z-index: 100;
            display: none;
            align-items: center;
            justify-content: center;
            overflow: hidden;
    }
</style>