<script>
  import { link, push } from "svelte-spa-router";
  import { onMount } from "svelte";
  import liff from "@line/liff";
  import Button from "./../components/Input/Button.svelte";
  export let params = {}
  let user_id = '';

  function loginBtn() {
    const loginPromise = (async () => {
      let profile = {};
      try {
        await liff.init({
          liffId: "1656736039-RxGp3xE8",
        });
        if (!liff.isLoggedIn()) {
          liff.login({ redirectUri: 'https://siehe.com.tw/#/backstage/orders/calendar' });
        }
        profile = await liff.getProfile();
        user_id = profile.userId; //取得系統管理者/師傅 Line ID
        cookieSetup("userLineId", user_id);
      } catch (err) {
        console.log(err);
      }
      return profile;
    })();
  }


  function cookieSetup(name, value) {
    var expires = new Date();
    expires.setTime(expires.getTime() + 1 * 60 * 60 * 1000);
    document.cookie =
      name + "=" + escape(value) + ";path=/;expires=" + expires.toGMTString();
  }
</script>

<div>
  <h1>登出成功</h1>
  <Button
    style="margin: 10px 0 10px 0;"
    hoverColor="#58b957"
    props=""
    bgColor="#58b957"
    color="#fff"
    hoverTextColor="#fff"
    on:click={loginBtn}
  >
    登入
  </Button>
</div>

<style>
  h1 {
    color: #000;
    text-transform: uppercase;
    font-size: 4em;
    font-weight: 100;
    margin: 0;
    padding: 0;
  }
</style>
