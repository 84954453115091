<!-- 後台：帳號管理修改 /backstage/users/<user_id> -->
 <script>
    import { push } from 'svelte-spa-router'
    import axios from 'axios';
	import { onMount } from 'svelte';
    import Button from './../../components/Input/Button.svelte';
    import SveltyPicker from 'svelty-picker'
    import ImageChange from "./../../components/Lightbox/_imageChange.svelte";
    import { personImg } from './../../components/store';
    import MessageError from "./../../components/Lightbox/_messageError.svelte";
    import MessageSuccess from "./../../components/Lightbox/_messageSuccess.svelte";
    import Black from "./../../components/Lightbox/_black.svelte";

    // export const params = {} 必須留才能接到 params.user_id 資訊
    export let params = {}
    let userLineId;
    let userPermission = {
		orders: "",
		business_records: "",
		shifts: "",
		clients: "",
		holidays: "",
		dayoff: "",
		roles: "",
		users: "",
	};
    let message ='';
    let itemsSelectAll = false;
    let roles = [];
    let items = [];
    let pic = 'https://siehe.com.tw:1443/static/images/users/people.png';
    let picHint = false;

    $: users.mobile !== '' && users.mobile.length > 4  ? addDash() : addDash()
    $: users.ice_mobile !== '' && users.ice_mobile.length > 4  ? addDashIceMobile() : addDashIceMobile()

    let users = 
        {
            name: "",
            gender: "男",
            role_id: "",
            role_name: '',
            dob: "",
            id_card: "",
            photo: "",  
            address: "",
            mobile: "",
            tel: "",                         
            email: "",
            bank: '',
            bank_account: "",             
            item_ids: "",
            level: "",
            ice_name: "",                          
            ice_mobile: "",                  
            note: ""
        }
    
    onMount(async () => {
        userLineId = getCookie("userLineId");
        if( userLineId == null || userLineId == '' ){
            push('/backstage/orders/calendar')
        }else{
            if(getCookie("userPermission") !== null && getCookie("userPermission") !== ''){
                userPermission = JSON.parse(getCookie("userPermission"));
                if(userPermission.users !== 2){
                    push('/backstage/orders/calendar')  
                }
                if(getCookie("userRole") !== 'admin'){
                    push('/backstage/orders/calendar')  
                }
                personImg.set(pic)

                window.setTimeout(function(){
                    document.querySelector('.form__item--dob input').style.width = 'calc(100% - 110px)'
                    document.querySelector('.form__item--dob input').style.margin = '10px'  
                },0)

            }else{
                push('/backstage/orders/calendar')  
            }
        }
        
        //角色列表
        await axios({
            method: 'get',
            url: env.BASE_URL + `api/v1/roles`,
            }).then(function (res) {
                roles = res.data;
            }).catch(function (err) {
                console.dir(err);
                // push('/error')
            }
        );

        //服務列表
        await axios({
            method: 'get',
            url: env.BASE_URL + `api/v1/items`,
            }).then(function (res) {
                items = res.data;
            }).catch(function (err) {
                console.dir(err);
                // push('/error')
            }
        );
    });    

    function getCookie(name) {
		var cookieArr = document.cookie.split(";");
		for(var i = 0; i < cookieArr.length; i++) {
			var cookiePair = cookieArr[i].split("=");
			if(name == cookiePair[0].trim()) {
				return decodeURIComponent(cookiePair[1]);
			}
		}
		return null;
	}


    function save(){
        if(users.name.replaceAll(' ','') == ''){
            alert('姓名為必填！')
        }else if(users.mobile.replaceAll(' ','') == ''){
            alert('手機號碼為必填！')
        }else if(!(/09\d{2}-\d{3}\d{3}/.test(users.mobile))){
            alert('手機號碼格式不對！')
        }else if(users.email.replaceAll(' ','') == ''){
            alert('電子郵件為必填！')
        }else if(users.dob == '' && users.dob == null){
            alert('出生日期為必填！') 
        }else if(users.id_card.replaceAll(' ','') == ''){
            alert('身份證為必填！') 
        }else if(users.address.replaceAll(' ','') == ''){
            alert('地址為必填！') 
        }else if(users.item_ids.toString() == ''){
            alert('服務項目為必填！') 
        }else if(users.level.replaceAll(' ','') == ''){
            alert('等級為必填！') 
        }else if(users.role_name == ''){
            alert('角色為必填！') 
        }else{
            for(let r=0 ; r<roles.length ; r++){
                if(roles[r].name == users.role_name){
                    users.role_id = roles[r].id
                }
            }
            //帳號明細
            axios({
                    method: 'post',
                    url: env.BASE_URL + `api/v1/users/`,
                    data:{
                        id: users.user_id.replaceAll(' ',''),
                        name: users.name.replaceAll(' ',''),
                        gender: users.gender,
                        dob: users.dob,
                        role_id: users.role_id,
                        id_card: users.id_card.replaceAll(' ',''),
                        photo: 'https://siehe.com.tw:1443/static/images/users/people.png',  
                        address: users.address.replaceAll(' ',''),
                        mobile: users.mobile.replaceAll(' ',''),
                        tel: users.tel.replaceAll(' ',''),                         
                        email: users.email.replaceAll(' ',''),
                        bank: users.bank.replaceAll(' ',''),
                        bank_account: users.bank_account.replaceAll(' ',''),             
                        item_ids: users.item_ids.toString(),
                        level: users.level.replaceAll(' ',''),
                        ice_name: users.ice_name.replaceAll(' ',''),
                        ice_mobile: users.ice_mobile.replaceAll(' ',''),
                        note: users.note.replaceAll(' ',''),
                    }
                    }).then(function (res) {
                        if(res.data.status == 'successful'){
                            message = '新增成功！'
                            success();
                        }else{
                            message = res.data.message
                            fail();
                        }
                    }).catch(function (err) {
                        console.dir(err);
                        // push('/error')
                    }
                );
            }
        }

    
        function success(){
            document.querySelector('.black').style.display = 'block'
            document.querySelector('.lightbox--messageSuccess').style.display = 'inline-block'
            setTimeout( function(){
                document.querySelector('.lightbox--messageSuccess').style.display = 'none'
                document.querySelector('.black').style.display = 'none'
                push('/backstage/users')
            },2000)
        }

        function fail(){
            document.querySelector('.lightbox--messageError').style.display = 'inline-block'
            document.querySelector('.black').style.display = 'block'
        }

        function itemsSelectAllClick(event){
        if (!itemsSelectAll) {
            users.item_ids = [];
            for(let s = 0; s < items.length; s++){
                users.item_ids.push(items[s].item_id)
            }
        }else{
            users.item_ids = [];
        }
        itemsSelectAll = !itemsSelectAll;
        setTimeout(() => event.target.checked = itemsSelectAll, 0);
    }

    function itemsSelectchange(){
        setTimeout(function(){
            if(users.item_ids.length == items.length){
                itemsSelectAll = true;
                document.querySelector('#itemsSelectAll').checked = itemsSelectAll
            }else{
                itemsSelectAll = false;
                document.querySelector('#itemsSelectAll').checked = itemsSelectAll
            }
        }, 0);
    }

    function changePic(){ 
        picHint = true;
    }


    function closeLightbox(){
        document.querySelector('.lightbox--messageError').style.display = 'none'
        document.querySelector('.lightbox--image').style.display = 'none'
        document.querySelector('.black').style.display = 'none'
    }

    function afterSave(){
        document.querySelector('.lightbox--image').style.display = 'none'
        document.querySelector('.black').style.display = 'none'
        personImg.subscribe(value => {
            users.photo =  env.BASE_URL + value + `#${picNum}`
            picNum++;
        });
    }
        
    function addDash(){
        if(users.mobile.replaceAll(' ','') !== '' && users.mobile.replaceAll(' ','').length > 5){
            if(users.mobile.indexOf('-')==-1){
                let mobile2 = users.mobile.substring(4,users.mobile.length)
                users.mobile = users.mobile.replaceAll(' ','').substring(0,4) + '-' + mobile2
            }
        }else{
            users.mobile.replaceAll('-','')
        }
    }

    function addDashIceMobile(){
        if(users.ice_mobile.replaceAll(' ','') !== '' && users.ice_mobile.replaceAll(' ','').length > 5){
            if(users.ice_mobile.indexOf('-')==-1){
                let ice_mobile2 = users.ice_mobile.substring(4,users.ice_mobile.length)
                users.ice_mobile = users.ice_mobile.replaceAll(' ','').substring(0,4) + '-' + ice_mobile2
            }
        }else{
            users.ice_mobile.replaceAll('-','')
        }
    }



</script>

<div class="title">
    <h2>帳號明細</h2>
</div>

<div class="form">
    <div class="form__con">
        <div class="form__item form__item--pic">
            <img src={ pic } alt="" on:click="{ changePic }">
            {#if picHint}
                <span>* 帳號建立後才可更換圖片</span>
            {/if}
        </div>
        <div class="form__item">
            <p><span>*</span> Line ID ：</p><input type="text" bind:value="{users.user_id}" >
        </div>
        <div class="form__item">
            <p><span>*</span> 姓名：</p><input type="text" bind:value="{users.name}" >
        </div>
        <div class="form__item form__item--gender">  
            <p><span>*</span> 性別：</p>
            <label for="man">男
                <input type="radio" value='男' bind:group={users.gender} id="man" name="gender">
            </label>
            &nbsp;  &nbsp;  &nbsp;
            <label for="woman">女
                <input type="radio"  value='女' bind:group={users.gender} id="woman" name="gender" >
            </label>
        </div>
        <div class="form__item form__item--dob">
            <p><span>*</span> 出生日期：</p>
            <SveltyPicker format="yyyy-mm-dd" bind:value={users.dob}></SveltyPicker>
        </div>
        <div class="form__item">
            <p><span>*</span> 角色：</p>
            <select name="role" id="role" bind:value={users.role_name} >
                {#each roles as role, i}
                    <option  value="{role.name}" id="{role.id}">&nbsp;{role.name}</option>
                {/each}
            </select>
        </div>
        <div class="form__item">
            <p><span>*</span> 身份證：</p><input type="text" bind:value="{users.id_card}" >
        </div>
        <div class="form__item">
            <p>所屬銀行：</p><input type="text" bind:value="{users.bank}" >
        </div>
        <div class="form__item">
            <p>銀行帳戶：</p><input type="text" bind:value="{users.bank_account}" >
        </div>
        <div class="form__item">
            <p>市話：</p><input type="tel" bind:value="{users.tel}" >
        </div>
        <div class="form__item">
            <p><span>*</span> 手機：</p><input type="tel" bind:value="{users.mobile}" >
        </div>
        <div class="form__item">
            <p><span>*</span> 地址：</p><input type="text" bind:value="{users.address}" >
        </div>
        <div class="form__item form__item--email">
            <p><span>*</span> 電子郵件：</p><input type="text" bind:value="{users.email}" >
        </div>
        <div class="form__item">
            <p>緊急聯絡人：</p><input type="text" bind:value="{users.ice_name}" >
        </div>
        <div class="form__item">
            <p>緊急聯絡人電話：</p><input type="tel" bind:value="{users.ice_mobile}" >
        </div>
        <div class="form__item">
            <p><span>*</span> 等級：</p><input type="text" bind:value="{users.level}" >
        </div>
        <div class="form__item form__item--serve">
            <p><span>*</span> 服務項目：</p>
            <div>
                <div>
                    <input type="checkbox" name="itemsSelectAll" on:click={itemsSelectAllClick} id="itemsSelectAll"><label for="itemsSelectAll">&nbsp;全選</label>&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                {#each items as item, i}
                    {#if item.item_type == '服務'}
                        <div>
                            <input type="checkbox" name="item" value="{item.item_id}" id="{item.item}" bind:group={users.item_ids} on:click={itemsSelectchange}><label for="{item.item}">&nbsp;{item.item}</label>&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                    {/if}
                {/each}
            </div>
        </div>
        <div class="form__item form__item--textarea">
            <p>備註：</p><textarea rows="5" cols="50" bind:value="{users.note}" />
        </div>
    </div>
</div>

<div class="btn">
    <div class="btn__con">
        <Button
        style="margin: 10px 0 10px 0;"
        hoverColor="#58b957"
        props=''
        bgColor = '#58b957'
        color= "#fff"
        hoverTextColor="#fff"
        on:click={save}   
    >
    儲存
    </Button>
    </div>
</div>

<ImageChange user_id={params.user_id} on:close={closeLightbox} on:save={afterSave}/>

<MessageSuccess {message} />
<MessageError {message} on:close={closeLightbox}/>
<Black on:black={closeLightbox}/>



<style lang="scss">
    @import './../../components/scss/_reset.scss';
    @import './../../components/scss/_font.scss';


    .title{
        display: inline-block;
        h2{
            font-size: 25px;
            font-weight: 500;
            letter-spacing: 1px;
            color: #425271;
            position: relative;
            margin: auto 0 15px 0;
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: #960E1E;
                bottom: -3px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    .form{
        max-width: 1000px;
        margin: auto;
        &__con{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
        &__item{
            width: 48%;
            display: flex;
            align-items: center;
            margin: 5px 1%;
            position: relative;
            input[type=text],input[type=tel], select{
                width: calc(100% - 100px);
                margin: 10px;
            }
            select{
                padding: 10px 0;
            }
            p{
                margin: 0;
                width: 150px;
                span{
                    color: #f00;
                }
            }
            textarea{
                resize: none;
                width: calc(100% - 115px);
            }
            &--textarea{
                width: 100%;
                p{
                    width: 145px;
                }
            }
            &--status{
                width: calc(48%);
            }
            &--serve{
                width: calc(100%);
                > div{
                    margin-left: 30px;
                    display: inline-flex;
                    flex-wrap: wrap;
                    > div{
                        margin: 5px 0;
                        display: flex;
                        align-items: center;
                    }
                }
            }
            &--pic{
                width: 100%;
                text-align: center;
                margin-bottom: 30px;
                flex-wrap: wrap;
                span{
                    width: 100%;
                    color: #f00;
                    margin-top: 10px;
                }
                img{
                    max-width: 200px;
                    width: auto;
                    height: auto;
                    max-height: 270px;
                    margin: auto;
                    border-radius: 50%;
                    border: 8px solid #425271;
                }
            }
            &--email{
                width: calc(48%);
                p{
                    width: 150px;
                }
            }
            &--gender{
                label{
                    margin: 0 5px 0 10px;
                }
                input{
                    margin-left: 5px;
                }
            }
        }
    }

    .btn{
        max-width: 1000px;
        padding: 0 20px 0 0;
        margin: 20px auto 0px;
        width: 100%;
        display: block;
        &__con{
            display: flex;
            justify-content: flex-end;
        }
    }

  
</style>