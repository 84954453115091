import App from "./App.svelte";
// import './bootstrap.min.css';

const app = new App({
  // props: {
  // }
  target: document.body,
});

export default app;
