<script>
    import Button from "./../Input/Button.svelte";
    import { createEventDispatcher } from "svelte";
    const dispatch = createEventDispatcher();
    function clickBlack() {
        dispatch("black");
    }
    function yes() {
        dispatch("yes");
    }
    function no() {
        dispatch("no");
    }
</script>

<div class="lightbox lightbox--frontYesOrNo">
    <div class="lightbox__con">
        <img
            src="./image/close.png"
            alt=""
            class="close"
            on:click={clickBlack}
        />
        <h2>確定取消預約？</h2>
        <div>
            <div class="lightbox__item">
                <Button
                    color="#333"
                    style="margin: 10px 10px;"
                    hoverColor="#E6E6E6"
                    props=""
                    hoverTextColor="#333"
                    bgColor="#E6E6E6"
                    on:click={yes}
                >
                    是
                </Button>
            </div>
            <div class="lightbox__item">
                <Button
                    color="#333"
                    style="margin: 10px 10px;"
                    hoverColor="#E6E6E6"
                    props=""
                    hoverTextColor="#333"
                    bgColor="#E6E6E6"
                    on:click={no}
                >
                    否
                </Button>
            </div>
        </div>
    </div>
</div>

<style lang="scss">
    @import "./../../components/scss/_font.scss";
    @import "./../../components/scss/_reset.scss";

    .lightbox {
        &--frontYesOrNo {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 111;
            display: none;
            position: fixed;
            text-align: center;
            justify-content: center;
            width: 100%;
        }
        h2 {
            font-size: 25px;
            font-weight: 500;
            letter-spacing: 1px;
            color: #425271;
            position: relative;
            margin: auto 0 15px 0;
            text-align: center;
        }
        &__con {
            width: 90%;
            max-width: 500px;
            background-color: #fff;
            padding: 50px 30px 30px;
            margin: auto;
            position: relative;
        }
        &__item {
            margin: 0px;
            display: inline-flex;
            input {
                width: 100%;
                padding: 5px 10px;
                margin: 5px 0 0;
                line-height: 30px;
            }
            span {
                color: #f00;
            }
            &--button {
                margin-top: 10px;
                text-align: right;
            }
        }
        .close {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 30px;
            cursor: pointer;
            transition: 0.3s;
            &:hover {
                opacity: 0.6;
            }
        }
    }
</style>
