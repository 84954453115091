<!-- 排班明細<div>Backstage_Shifts_year_month_userId</div> -->
<script>
    import { onMount } from "svelte";
    import axios from "axios";
    import SveltyPicker from "svelty-picker";
    import Button from "./../../components/Input/Button.svelte";
    import { config } from "svelty-picker";
    import { yearMonths } from "./../../components/shiftDate";

    import { link, push } from "svelte-spa-router";
    import MessageError from "./../../components/Lightbox/_messageError.svelte";
    import MessageSuccess from "./../../components/Lightbox/_messageSuccess.svelte";
    import Black from "./../../components/Lightbox/_black.svelte";

    export let params = {};

    let message = "";
    let warning = "";
    let userLineId;
    let userPermission = {
        orders: "",
        business_records: "",
        shifts: "",
        clients: "",
        holidays: "",
        dayoff: "",
        roles: "",
        users: "",
    };
    let initYear = [];
    let initListMonth = [];
    let initListDate = [];
    let initListYear = [];
    let initListYearPlus1 = [];
    let initListMonthPlus1 = [];
    let chooseRange = "";
    let today;

    // 師傅
    let masterItems = [];
    let masterSelect = [];

    // 分店
    let storeItems = [];
    let storeSelect = [];

    // 假日種類
    let holidayItem = "";

    //users
    let users = [];
    let userName = "";

    //shift
    let shifts = [];
    let restId = [];
    let newShift = [];
    let user = [];
    let userId = [];
    let day = [];
    let lastDay = [];
    let oneDay = [];

    onMount(async () => {
        userLineId = getCookie("userLineId");
        if (userLineId == null || userLineId == "") {
            push("/backstage/orders/calendar");
        } else {
            if (
                getCookie("userPermission") !== null &&
                getCookie("userPermission") !== ""
            ) {
                userPermission = JSON.parse(getCookie("userPermission"));
                if (userPermission.shifts == 0) {
                    push("/backstage/orders/calendar");
                }
            } else {
                push("/backstage/orders/calendar");
            }
        }
        let date = new Date();
        initYear = `${date.getFullYear().toString()}-0${(
            date.getMonth() + 1
        ).toString()}`;
        initListMonth = `${(date.getMonth() + 1).toString()}`;
        // initListMonth = '01';
        // initListMonth = '12';
        initListYearPlus1 = `${(date.getFullYear() + 1).toString()}`;
        if (initListMonth >= 9) {
            initListDate = `${date.getFullYear().toString()}-${(
                date.getMonth() + 1
            ).toString()}`;
            initYear = `${date.getFullYear().toString()}-${(
            date.getMonth() + 1).toString()}`;
            initListMonthPlus1 = `${parseInt(initListMonth) + 1}`;
            // initListMonthPlus1 = `${(date.getMonth()+2).toString()}`
        } else {
            initListDate = `${date.getFullYear().toString()}-0${(
                date.getMonth() + 1
            ).toString()}`;
            initListMonthPlus1 = `0${parseInt(initListMonth) + 1}`;
        }
        initListYear = `${date.getFullYear().toString()}-`;
        chooseRange = initYear;
        today = date.getDate();

        if (getCookie("userRole") == "admin") {
            //師傅列表
            await axios({
                method: "get",
                url: env.BASE_URL + "api/v1/users",
            })
                .then(function (res) {
                    // console.log(res)
                    masterItems = res.data;
                    if (masterSelect == "") {
                        masterSelect = userLineId;
                    }
                })
                .catch(function (err) {
                    console.dir(err);
                    // push('/error')
                });
            await axios({
                method: "get",
                url:
                    env.BASE_URL +
                    `api/v1/shifts/${chooseRange.split("-")[0]}/${
                        chooseRange.split("-")[1]
                    }/${masterSelect}`,
            })
                .then(function (res) {
                    shifts = res.data;
                    shifts = Object.keys(shifts).map(function (_) {
                        return shifts[_];
                    });
                    lastDay = shifts.length;
                })
                .catch(function (err) {
                    console.dir(err);
                    // push('/error')
                });
        } else if (getCookie("userRole") == "therapist") {
            await axios({
                method: "get",
                url:
                    env.BASE_URL +
                    `api/v1/shifts/${chooseRange.split("-")[0]}/${
                        chooseRange.split("-")[1]
                    }/${userLineId}`,
            })
                .then(function (res) {
                    shifts = res.data;
                    shifts = Object.keys(shifts).map(function (_) {
                        return shifts[_];
                    });
                    lastDay = shifts.length;
                })
                .catch(function (err) {
                    console.dir(err);
                    // push('/error')
                });
        }

        //帳號列表
        await axios({
            method: "get",
            url: env.BASE_URL + "api/v1/users",
        })
            .then(function (res) {
                users = res.data;
                // console.log(users)
                for (let u = 0; u < users.length; u++) {
                    if (users[u].id == userLineId) {
                        userName = users[u].name;
                        userId = users[u].id;
                        // console.log(users[u])
                    }
                }
            })
            .catch(function (err) {
                console.dir(err);
                // push('/error')
            });

        //店家列表
        await axios({
            method: "get",
            url: env.BASE_URL + "api/v1/branches",
        })
            .then(function (res) {
                storeItems = res.data;
                storeSelect = storeItems[0].branch;
            })
            .catch(function (err) {
                console.dir(err);
                // push('/error')
            });

        await axios({
            method: "get",
            url: env.BASE_URL + "api/v1/holidays/type",
        })
            .then(function (res) {
                holidayItem = res.data;
                // console.log(holidayItem)
                setTimeout(function () {
                    let active = document.querySelectorAll(".active");
                    for (let t = 0; t < active.length; t++) {
                        if (
                            holidayItem.includes(
                                active[t].innerHTML.replaceAll(" ", "")
                            )
                        ) {
                            let originClass = active[t].getAttribute("class");
                            active[t].setAttribute(
                                "class",
                                `${originClass} rest withColor`
                            );
                            active[t].classList.remove("active");
                        }
                    }
                    let td = document.querySelectorAll("td");
                    for (let d = 0; d < td.length; d++) {
                        if (
                            td[d].innerHTML == "星期六" ||
                            td[d].innerHTML == "星期日"
                        ) {
                            let originClass = td[d].getAttribute("class");
                            td[d].setAttribute(
                                "class",
                                `${originClass} weekend`
                            );
                        }
                    }
                }, 0);
            })
            .catch(function (err) {
                console.dir(err);
                // push('/error')
            });
    });

    function getCookie(name) {
        var cookieArr = document.cookie.split(";");
        for (var i = 0; i < cookieArr.length; i++) {
            var cookiePair = cookieArr[i].split("=");
            if (name == cookiePair[0].trim()) {
                return decodeURIComponent(cookiePair[1]);
            }
        }
        return null;
    }

    function change() {
        warning = "";
        shifts = [];
        if (getCookie("userRole") == "admin") {
            userLineId = masterSelect;
        } else if (getCookie("userRole") == "therapist") {
        }
        axios({
            method: "get",
            url:
                env.BASE_URL +
                `api/v1/shifts/${chooseRange.split("-")[0]}/${
                    chooseRange.split("-")[1]
                }/${userLineId}`,
        })
            .then(function (res) {
                shifts = res.data;
                shifts = Object.keys(shifts).map(function (_) {
                    return shifts[_];
                });
                lastDay = shifts.length;
                setTimeout(function () {
                    let active = document.querySelectorAll(".active");
                    for (let t = 0; t < active.length; t++) {
                        if (
                            holidayItem.includes(
                                active[t].innerHTML.replaceAll(" ", "")
                            )
                        ) {
                            let originClass = active[t].getAttribute("class");
                            active[t].setAttribute(
                                "class",
                                `${originClass} rest withColor`
                            );
                            active[t].classList.remove("active");
                        }
                    }
                    let td = document.querySelectorAll("td");
                    for (let d = 0; d < td.length; d++) {
                        // console.log(td[d].innerHTML)
                        if (
                            td[d].innerHTML == "星期六" ||
                            td[d].innerHTML == "星期日"
                        ) {
                            let originClass = td[d].getAttribute("class");
                            td[d].setAttribute(
                                "class",
                                `${originClass} weekend`
                            );
                        }
                    }
                }, 0);
            })
            .catch(function (err) {
                console.dir(err);
                // push('/error')
            });
    }

    //color1
    let bgColor = "#f1e7c6";
    let colorClass = "color1";

    function color(i) {
        return `color${i + 1}`;
    }

    function modifyShift(num, period, content) {
        if (chooseRange == initYear) {
            if (num >= today) {
                if (content !== []) {
                    let td = document.querySelectorAll("td");
                    for (let t = 0; t < td.length; t++) {
                        if (td[t].getAttribute("data-place") !== null) {
                            let num_ = td[t]
                                .getAttribute("data-place")
                                .split(",");
                            if (num == num_[0] && period == num_[1]) {
                                let originClass = td[t].getAttribute("class");
                                if (
                                    td[t].innerHTML.replaceAll(" ", "") ==
                                    storeSelect.replaceAll(" ", "")
                                ) {
                                    if (!originClass.includes("active")) {
                                        td[t].setAttribute(
                                            "class",
                                            `${originClass} active ${colorClass}`
                                        );
                                        td[t].innerHTML = storeSelect;
                                    } else {
                                        originClass = originClass.replace(
                                            "active",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color1",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color2",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color3",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color4",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color5",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color6",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color7",
                                            ""
                                        );
                                        td[t].setAttribute(
                                            "class",
                                            `${originClass}`
                                        );
                                        td[t].innerHTML = "";
                                    }
                                } else if (
                                    td[t].innerHTML.replaceAll(" ", "") == ""
                                ) {
                                    if (!originClass.includes("active")) {
                                        td[t].setAttribute(
                                            "class",
                                            `${originClass} active ${colorClass}`
                                        );
                                        td[t].innerHTML = storeSelect;
                                    } else {
                                        originClass = originClass.replace(
                                            "active",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color1",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color2",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color3",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color4",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color5",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color6",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color7",
                                            ""
                                        );
                                        td[t].setAttribute(
                                            "class",
                                            `${originClass} ${colorClass}`
                                        );
                                        td[t].innerHTML = "";
                                    }
                                } else {
                                    for (
                                        let storeColor = 0;
                                        storeColor < storeItems.length;
                                        storeColor++
                                    ) {
                                        if (
                                            storeSelect ==
                                            storeItems[storeColor].branch
                                        ) {
                                            colorClass = `color${
                                                storeColor + 1
                                            }`;
                                        }
                                    }
                                    if (originClass.includes("active")) {
                                        originClass = originClass.replace(
                                            "active",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color1",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color2",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color3",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color4",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color5",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color6",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color7",
                                            ""
                                        );
                                        td[t].setAttribute(
                                            "class",
                                            `${originClass} active ${colorClass}`
                                        );
                                        td[t].innerHTML = storeSelect;
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                alert("過去日期不可修改");
            }
        } else if (chooseRange > initYear) {
            if (holidayItem.includes(content[0])) {
                alert("不可修改此日期的排班"); //公休
            } else {
                if (content !== []) {
                    let td = document.querySelectorAll("td");
                    for (let t = 0; t < td.length; t++) {
                        if (td[t].getAttribute("data-place") !== null) {
                            let num_ = td[t]
                                .getAttribute("data-place")
                                .split(",");
                            if (num == num_[0] && period == num_[1]) {
                                let originClass = td[t].getAttribute("class");

                                if (
                                    td[t].innerHTML.replaceAll(" ", "") ==
                                    storeSelect.replaceAll(" ", "")
                                ) {
                                    if (!originClass.includes("active")) {
                                        td[t].setAttribute(
                                            "class",
                                            `${originClass} active ${colorClass}`
                                        );
                                        td[t].innerHTML = storeSelect;
                                    } else {
                                        originClass = originClass.replace(
                                            "active",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color1",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color2",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color3",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color4",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color5",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color6",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color7",
                                            ""
                                        );
                                        td[t].setAttribute(
                                            "class",
                                            `${originClass}`
                                        );
                                        td[t].innerHTML = "";
                                    }
                                } else if (
                                    td[t].innerHTML.replaceAll(" ", "") == ""
                                ) {
                                    if (!originClass.includes("active")) {
                                        td[t].setAttribute(
                                            "class",
                                            `${originClass} active ${colorClass}`
                                        );
                                        td[t].innerHTML = storeSelect;
                                    } else {
                                        originClass = originClass.replace(
                                            "active",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color1",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color2",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color3",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color4",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color5",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color6",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color7",
                                            ""
                                        );
                                        td[t].setAttribute(
                                            "class",
                                            `${originClass} ${colorClass}`
                                        );
                                        td[t].innerHTML = "";
                                    }
                                } else {
                                    for (
                                        let storeColor = 0;
                                        storeColor < storeItems.length;
                                        storeColor++
                                    ) {
                                        if (
                                            storeSelect ==
                                            storeItems[storeColor].branch
                                        ) {
                                            colorClass = `color${
                                                storeColor + 1
                                            }`;
                                        }
                                    }
                                    if (originClass.includes("active")) {
                                        originClass = originClass.replace(
                                            "active",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color1",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color2",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color3",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color4",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color5",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color6",
                                            ""
                                        );
                                        originClass = originClass.replace(
                                            "color7",
                                            ""
                                        );
                                        td[t].setAttribute(
                                            "class",
                                            `${originClass} active ${colorClass}`
                                        );
                                        td[t].innerHTML = storeSelect;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            alert("過去日期不可修改");
        }
    }

    function save() {
        newShift = [];
        let td = document.querySelectorAll("td");
        for (let s = 0; s < td.length; s = s + 5) {
            oneDay = `{
                "weekday": "${td[s + 1].innerHTML.replaceAll(" ", "")}",
                "morning":  "${td[s + 2].innerHTML.replaceAll(" ", "")}",
                "afternoon": "${td[s + 3].innerHTML.replaceAll(" ", "")}",
                "evening": "${td[s + 4].innerHTML.replaceAll(" ", "")}"
            }`;

            oneDay = oneDay.replaceAll("`", "");
            // console.log(oneDay)
            oneDay = JSON.parse(oneDay);
            // newShift.push( `${td[s]}`:{oneDay})
            newShift.push(oneDay);
            // console.log(oneDay)
        }

        if (lastDay == 31) {
            axios({
                method: "put",
                url:
                    env.BASE_URL +
                    `api/v1/shifts/${chooseRange.split("-")[0]}/${
                        chooseRange.split("-")[1]
                    }/login_user/${userLineId}`,
                data: {
                    user_id: userLineId,
                    "1": newShift[0],
                    "2": newShift[1],
                    "3": newShift[2],
                    "4": newShift[3],
                    "5": newShift[4],
                    "6": newShift[5],
                    "7": newShift[6],
                    "8": newShift[7],
                    "9": newShift[8],
                    "10": newShift[9],
                    "11": newShift[10],
                    "12": newShift[11],
                    "13": newShift[12],
                    "14": newShift[13],
                    "15": newShift[14],
                    "16": newShift[15],
                    "17": newShift[16],
                    "18": newShift[17],
                    "19": newShift[18],
                    "20": newShift[19],
                    "21": newShift[20],
                    "22": newShift[21],
                    "23": newShift[22],
                    "24": newShift[23],
                    "25": newShift[24],
                    "26": newShift[25],
                    "27": newShift[26],
                    "28": newShift[27],
                    "29": newShift[28],
                    "30": newShift[29],
                    "31": newShift[30],
                },
            })
                .then(function (res) {
                    // console.log(res)
                    if (res.data.status == "successful") {
                        if (res.data.warning.length !== 0) {
                            warning = res.data.warning;
                        } else {
                            warning = "";
                        }
                        success();
                    } else {
                        message = res.data.message;
                        fail();
                    }
                })
                .catch(function (err) {
                    console.dir(err);
                    // push('/error')
                });
        } else if (lastDay == 30) {
            axios({
                method: "put",
                url:
                    env.BASE_URL +
                    `api/v1/shifts/${chooseRange.split("-")[0]}/${
                        chooseRange.split("-")[1]
                    }/login_user/${userLineId}`,
                data: {
                    user_id: userLineId,
                    "1": newShift[0],
                    "2": newShift[1],
                    "3": newShift[2],
                    "4": newShift[3],
                    "5": newShift[4],
                    "6": newShift[5],
                    "7": newShift[6],
                    "8": newShift[7],
                    "9": newShift[8],
                    "10": newShift[9],
                    "11": newShift[10],
                    "12": newShift[11],
                    "13": newShift[12],
                    "14": newShift[13],
                    "15": newShift[14],
                    "16": newShift[15],
                    "17": newShift[16],
                    "18": newShift[17],
                    "19": newShift[18],
                    "20": newShift[19],
                    "21": newShift[20],
                    "22": newShift[21],
                    "23": newShift[22],
                    "24": newShift[23],
                    "25": newShift[24],
                    "26": newShift[25],
                    "27": newShift[26],
                    "28": newShift[27],
                    "29": newShift[28],
                    "30": newShift[29],
                },
            })
                .then(function (res) {
                    if (res.data.status == "successful") {
                        if (res.data.warning.length !== 0) {
                            warning = res.data.warning;
                        } else {
                            warning = "";
                        }
                        success();
                    } else {
                        message = res.data.message;
                        fail();
                    }
                })
                .catch(function (err) {
                    console.dir(err);
                    // push('/error')
                });
        } else if (lastDay == 29) {
            axios({
                method: "put",
                url:
                    env.BASE_URL +
                    `api/v1/shifts/${chooseRange.split("-")[0]}/${
                        chooseRange.split("-")[1]
                    }/login_user/${userLineId}`,
                data: {
                    user_id: userLineId,
                    "1": newShift[0],
                    "2": newShift[1],
                    "3": newShift[2],
                    "4": newShift[3],
                    "5": newShift[4],
                    "6": newShift[5],
                    "7": newShift[6],
                    "8": newShift[7],
                    "9": newShift[8],
                    "10": newShift[9],
                    "11": newShift[10],
                    "12": newShift[11],
                    "13": newShift[12],
                    "14": newShift[13],
                    "15": newShift[14],
                    "16": newShift[15],
                    "17": newShift[16],
                    "18": newShift[17],
                    "19": newShift[18],
                    "20": newShift[19],
                    "21": newShift[20],
                    "22": newShift[21],
                    "23": newShift[22],
                    "24": newShift[23],
                    "25": newShift[24],
                    "26": newShift[25],
                    "27": newShift[26],
                    "28": newShift[27],
                    "29": newShift[28],
                },
            })
                .then(function (res) {
                    if (res.data.status == "successful") {
                        if (res.data.warning.length !== 0) {
                            warning = res.data.warning;
                        } else {
                            warning = "";
                        }
                        success();
                    } else {
                        message = res.data.message;
                        fail();
                    }
                })
                .catch(function (err) {
                    console.dir(err);
                    // push('/error')
                });
        } else {
            axios({
                method: "put",
                url:
                    env.BASE_URL +
                    `api/v1/shifts/${chooseRange.split("-")[0]}/${
                        chooseRange.split("-")[1]
                    }/login_user/${userLineId}`,
                data: {
                    user_id: userLineId,
                    "1": newShift[0],
                    "2": newShift[1],
                    "3": newShift[2],
                    "4": newShift[3],
                    "5": newShift[4],
                    "6": newShift[5],
                    "7": newShift[6],
                    "8": newShift[7],
                    "9": newShift[8],
                    "10": newShift[9],
                    "11": newShift[10],
                    "12": newShift[11],
                    "13": newShift[12],
                    "14": newShift[13],
                    "15": newShift[14],
                    "16": newShift[15],
                    "17": newShift[16],
                    "18": newShift[17],
                    "19": newShift[18],
                    "20": newShift[19],
                    "21": newShift[20],
                    "22": newShift[21],
                    "23": newShift[22],
                    "24": newShift[23],
                    "25": newShift[24],
                    "26": newShift[25],
                    "27": newShift[26],
                    "28": newShift[27],
                },
            })
                .then(function (res) {
                    if (res.data.status == "successful") {
                        if (res.data.warning.length !== 0) {
                            warning = res.data.warning;
                        } else {
                            warning = "";
                        }
                        success();
                    } else {
                        message = res.data.message;
                        fail();
                    }
                })
                .catch(function (err) {
                    console.dir(err);
                    // push('/error')
                });
        }
    }

    function colorMaster() {
        warning = "";
        setTimeout(function () {
            for (
                let storeColor = 0;
                storeColor < storeItems.length;
                storeColor++
            ) {
                if (storeSelect == storeItems[storeColor].branch) {
                    colorClass = `color${storeColor + 1}`;
                }
            }
        }, 0);
    }

    function success() {
        message = "修改成功！";
        document.querySelector(".black").style.display = "block";
        document.querySelector(".lightbox--messageSuccess").style.display =
            "inline-block";

        setTimeout(function () {
            document.querySelector(".lightbox--messageSuccess").style.display =
                "none";
            document.querySelector(".black").style.display = "none";
            let td = document.querySelectorAll("td");
            for (let d = 0; d < td.length; d++) {
                td[d].classList.remove("color1");
                td[d].classList.remove("color2");
                td[d].classList.remove("color3");
                td[d].classList.remove("color4");
                td[d].classList.remove("color5");
                td[d].classList.remove("color6");
                td[d].classList.remove("color7");
            }
        }, 2000);
    }

    function fail() {
        document.querySelector(".lightbox--messageError").style.display =
            "inline-block";
        document.querySelector(".black").style.display = "block";
    }

    function closeLightbox() {
        document.querySelector(".lightbox--messageError").style.display =
            "none";
        document.querySelector(".black").style.display = "none";
    }

    function chooseAllDay() {
        if (chooseRange < initYear) {
            alert("過去日期不可修改");
        } else {
            let td = document.querySelectorAll("td");
            for (let t = 0; t < td.length; t++) {
                if (td[t].getAttribute("data-place") !== null) {
                    let num_ = td[t].getAttribute("data-place").split(",");
                    if (chooseRange == initYear) {
                        if (num_[0] >= today) {
                            if (!td[t].classList.contains("rest")) {
                                td[t].innerHTML = storeSelect;
                                let originClass = td[t].getAttribute("class");
                                originClass = originClass.replace("color1", "");
                                originClass = originClass.replace("color2", "");
                                originClass = originClass.replace("color3", "");
                                originClass = originClass.replace("color4", "");
                                originClass = originClass.replace("color5", "");
                                originClass = originClass.replace("color6", "");
                                originClass = originClass.replace("color7", "");
                                td[t].setAttribute(
                                    "class",
                                    `${originClass} active ${colorClass}`
                                );
                            }
                        }
                    } else if (chooseRange > initYear) {
                        if (
                            !holidayItem.includes(
                                td[t].innerHTML.replaceAll(" ", "")
                            )
                        ) {
                            if (!td[t].classList.contains("rest")) {
                                td[t].innerHTML = storeSelect;
                                let originClass = td[t].getAttribute("class");
                                originClass = originClass.replace("color1", "");
                                originClass = originClass.replace("color2", "");
                                originClass = originClass.replace("color3", "");
                                originClass = originClass.replace("color4", "");
                                originClass = originClass.replace("color5", "");
                                originClass = originClass.replace("color6", "");
                                originClass = originClass.replace("color7", "");
                                td[t].setAttribute(
                                    "class",
                                    `${originClass} active ${colorClass}`
                                );
                            }
                        }
                    }
                }
            }
        }
    }
</script>

<div class="title">
    <h2>排班</h2>
</div>

<div class="shift">
    <div class="shift__con shift__con--up">
        <div class="search">
            <div>
                <select
                    name="year"
                    id="year"
                    bind:value={chooseRange}
                    on:change={change}
                >
                    {#each yearMonths as yearMonth, i}
                        {#if initListDate >= yearMonth.format}
                            <option value={yearMonth.format} id={yearMonth.day}
                                >&nbsp;{yearMonth.show}</option
                            >
                        {/if}

                        {#if yearMonth.format == initListYear + initListMonthPlus1}
                            <option value={yearMonth.format} id={yearMonth.day}
                                >&nbsp;{yearMonth.show}</option
                            >
                        {/if}

                        {#if initListMonth == "12"}
                            {#if yearMonth.format == initListYearPlus1 + "-01"}
                                <option
                                    value={yearMonth.format}
                                    id={yearMonth.day}
                                    >&nbsp;{yearMonth.show}</option
                                >
                            {/if}
                        {/if}
                    {/each}
                </select>
            </div>

            {#if getCookie("userRole") == "therapist"}
                <div>
                    <p>師傅：{userName}</p>
                </div>
            {:else if getCookie("userRole") == "admin"}
                <select
                    name="master"
                    id="master"
                    bind:value={masterSelect}
                    on:change={change}
                >
                    {#each masterItems as masterItem, i}
                        <option value={masterItem.id} id={masterItem.name}
                            >&nbsp;{masterItem.name}</option
                        >
                    {/each}
                </select>
            {/if}
        </div>
        {#if userPermission !== null && userPermission.shifts == 2}
            <div class="shift shift--btnCon">
                <Button
                    style="margin: 20px 10px;"
                    hoverColor="#58b957"
                    props=""
                    bgColor="#58b957"
                    color="#fff"
                    hoverTextColor="#fff"
                    on:click={chooseAllDay}
                >
                    全選
                </Button>
                <Button
                    style="margin: 20px 0px;"
                    hoverColor="#58b957"
                    props=""
                    bgColor="#58b957"
                    color="#fff"
                    hoverTextColor="#fff"
                    on:click={save}
                >
                    儲存
                </Button>
            </div>
        {/if}
    </div>

    {#if warning !== ""}
        <div class="warning" style="width: 100%;">
            {#each warning as item, i}
                <a
                    use:link={{
                        href: `/backstage/orders/${item.order_id}`,
                        disabled: false,
                    }}
                >
                    <p>
                        *<span
                            >{item.ordered_at}&nbsp;&nbsp;&nbsp;&nbsp;{item.client_name}&nbsp;&nbsp;{item.client_mobile}</span
                        >
                    </p>
                </a>
            {/each}
        </div>
    {/if}

    {#if userPermission !== null && userPermission.shifts == 2}
        <div class="store">
            <div class="store__con">
                {#each storeItems as storeItem, i}
                    <label for={storeItem.branch}>
                        <input
                            type="radio"
                            bind:group={storeSelect}
                            value={storeItem.branch}
                            id={storeItem.branch}
                            name="store"
                            on:click={colorMaster}
                        />{storeItem.branch}
                        <div class={color(i)} />
                    </label>
                {/each}
            </div>
        </div>
    {/if}

    <div class="shift__con shift__con--down">
        <table>
            <tr>
                <th style="width: 7%;">日期</th>
                <th style="width: 15%;">星期</th>
                <th style="width: 26%; background: #44abf1">上午</th>
                <th style="width: 26%; background: #58b957">下午</th>
                <th style="width: 26%; background: #faa83b">晚上</th>
            </tr>
            {#each yearMonths as yearMonth, i}
                {#if yearMonth.format == chooseRange}
                    {#each { length: yearMonth.day } as _, i}
                        <tr>
                            <td class="td--center">
                                {i + 1}
                            </td>
                            <td class="td--center">
                                {#if (parseInt(yearMonth.week) + 8 + parseInt(i)) % 7 == 0}
                                    星期六
                                {:else if (parseInt(yearMonth.week) + 8 + parseInt(i)) % 7 == 1}
                                    星期日
                                {:else if (parseInt(yearMonth.week) + 8 + parseInt(i)) % 7 == 2}
                                    星期一
                                {:else if (parseInt(yearMonth.week) + 8 + parseInt(i)) % 7 == 3}
                                    星期二
                                {:else if (parseInt(yearMonth.week) + 8 + parseInt(i)) % 7 == 4}
                                    星期三
                                {:else if (parseInt(yearMonth.week) + 8 + parseInt(i)) % 7 == 5}
                                    星期四
                                {:else if (parseInt(yearMonth.week) + 8 + parseInt(i)) % 7 == 6}
                                    星期五
                                {/if}
                            </td>
                            {#if userPermission !== null && userPermission.shifts == 2}
                                {#each shifts as shift, j}
                                    {#if i == j}
                                        <td
                                            class={shift.morning.length == 0
                                                ? ""
                                                : "active"}
                                            on:click={(e) => {
                                                modifyShift(
                                                    i + 1,
                                                    1,
                                                    shift.morning
                                                );
                                            }}
                                            data-place="{i + 1},{1}"
                                        >
                                            {shift.morning}
                                        </td>
                                    {/if}
                                {/each}
                                {#each shifts as shift, j}
                                    {#if i == j}
                                        <td
                                            class={shift.afternoon.length == 0
                                                ? ""
                                                : "active"}
                                            on:click={(e) => {
                                                modifyShift(
                                                    i + 1,
                                                    2,
                                                    shift.afternoon
                                                );
                                            }}
                                            data-place="{i + 1},{2}"
                                        >
                                            {shift.afternoon}
                                        </td>
                                    {/if}
                                {/each}
                                {#each shifts as shift, j}
                                    {#if i == j}
                                        <td
                                            class={shift.evening.length == 0
                                                ? ""
                                                : "active"}
                                            on:click={(e) => {
                                                modifyShift(
                                                    i + 1,
                                                    3,
                                                    shift.evening
                                                );
                                            }}
                                            data-place="{i + 1},{3}"
                                        >
                                            {shift.evening}
                                        </td>
                                    {/if}
                                {/each}
                            {:else}
                                {#each shifts as shift, j}
                                    {#if i == j}
                                        <td
                                            class={shift.morning.length == 0
                                                ? ""
                                                : "active"}
                                            data-place="{i + 1},{1}"
                                        >
                                            {shift.morning}
                                        </td>
                                    {/if}
                                {/each}
                                {#each shifts as shift, j}
                                    {#if i == j}
                                        <td
                                            class={shift.afternoon.length == 0
                                                ? ""
                                                : "active"}
                                            data-place="{i + 1},{2}"
                                        >
                                            {shift.afternoon}
                                        </td>
                                    {/if}
                                {/each}
                                {#each shifts as shift, j}
                                    {#if i == j}
                                        <td
                                            class={shift.evening.length == 0
                                                ? ""
                                                : "active"}
                                            data-place="{i + 1},{3}"
                                        >
                                            {shift.evening}
                                        </td>
                                    {/if}
                                {/each}
                            {/if}
                        </tr>
                    {/each}
                {/if}
            {/each}
        </table>
    </div>
</div>

<MessageSuccess {message} />
<MessageError {message} on:close={closeLightbox} />
<Black on:black={closeLightbox} />

<style lang="scss">
    @import "./../../components/scss/_font.scss";
    @import "./../../components/scss/_reset.scss";

    .title {
        // display: inline-block;
        width: 100%;
        max-width: 1200px;
        margin: auto;
        h2 {
            font-size: 25px;
            font-weight: 500;
            letter-spacing: 1px;
            color: #425271;
            position: relative;
            margin: auto 0 10px 0px;
            display: inline-block;
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: #960e1e;
                bottom: -3px;
                left: 26px;
                transform: translateX(-50%);
            }
        }
    }

    .warning {
        p {
            color: #f00;
        }
    }

    .store {
        padding: 30px 10px;
        background-color: #f4f4f4;
        width: 100%;
        margin: 20px auto 40px;
        max-width: 1200px;
        &__con {
            display: flex;
            label {
                margin-left: 20px;
                position: relative;
                padding: 0px 5px 0px 0px;
            }
            input {
                position: relative;
                z-index: 1;
            }
        }
        .color {
            &1 {
                color: #fce8db;
                position: absolute;
                width: 100%;
                height: 5px;
                background-color: #fce8db;
                left: 0;
                bottom: -5px;
                z-index: 0;
            }
            &2 {
                color: #dce6f5;
                position: absolute;
                width: 100%;
                height: 5px;
                background-color: #dce6f5;
                left: 0;
                bottom: -5px;
            }
            &3 {
                color: #d6d9da;
                position: absolute;
                width: 100%;
                height: 5px;
                background-color: #d6d9da;
                left: 0;
                bottom: -5px;
            }
            &4 {
                color: #d5e6d2;
                position: absolute;
                width: 100%;
                height: 5px;
                background-color: #d5e6d2;
                left: 0;
                bottom: -5px;
            }
            &5 {
                color: #dad1eb;
                position: absolute;
                width: 100%;
                height: 5px;
                background-color: #dad1eb;
                left: 0;
                bottom: -5px;
            }
            &6 {
                color: #ebe9b0;
                position: absolute;
                width: 100%;
                height: 5px;
                background-color: #ebe9b0;
                left: 0;
                bottom: -5px;
            }
            &7 {
                color: #ead2e6;
                position: absolute;
                width: 100%;
                height: 5px;
                background-color: #ead2e6;
                left: 0;
                bottom: -5px;
            }
        }
    }

    .shift {
        display: flex;
        flex-wrap: wrap;
        &--btnCon {
            flex-wrap: nowrap;
        }
        &__con {
            width: 100%;
            max-width: 1200px;
            margin: auto;
            &--up {
                display: flex;
                justify-content: space-between;
            }
            &--down {
                display: flex;
                flex-wrap: nowrap;
                .down {
                    &--table {
                        width: 100%;
                    }
                    &--button {
                        width: 140px;
                    }
                }
            }
        }
    }
    .search {
        max-width: 1200px;
        width: 100%;
        margin: 20px auto;
        display: flex;
        align-items: center;
        select {
            width: 200px;
            margin-right: 20px;
        }
    }

    //表單
    table {
        max-width: 1200px;
        width: 100%;
        margin: auto;
    }
    table,
    th,
    td {
        border: 1px solid #ece9e9;
        border-collapse: collapse;
    }
    th {
        padding: 8px 0px 8px 0px;
        text-align: center;
    }
    th.sortable {
        cursor: pointer;
    }
    th.sortable span {
        padding-right: 16px;
        position: relative;
    }
    th.sortable span:before,
    th.sortable span:after {
        border: 4px solid transparent;
        content: "";
        display: block;
        height: 0;
        right: 0;
        top: 50%;
        position: absolute;
        width: 0;
    }
    th.sortable span:before {
        border-bottom-color: #e0e0e0;
        margin-top: -9px;
    }
    th.sortable span:after {
        border-top-color: #e0e0e0;
        margin-top: 1px;
    }
    th.sortable.asc:not(.desc) span:before {
        border-bottom-color: #9e9e9e;
    }
    th.sortable.desc:not(.asc) span:after {
        border-top-color: #9e9e9e;
    }

    td {
        text-align: left;
        padding: 5px 10px;
        word-break: break-all;
        line-height: 35px;
    }
    .weekend {
        background-color: rgb(255 157 139 / 81%);
    }
    .withColor.rest {
        background-color: rgb(242 212 39 / 81%);
        color: #333;
        text-align: center;
    }
    // td.holiday{
    //     background-color: #942431;
    //     color: #fff;
    //     text-align: center;
    // }
    .td--center {
        text-align: center;
    }

    .active.color {
        &1 {
            background-color: #fce8db;
        }
        &2 {
            background-color: #dce6f5;
        }
        &3 {
            background-color: #d6d9da;
        }
        &4 {
            background-color: #d5e6d2;
        }
        &5 {
            background-color: #dad1eb;
        }
        &6 {
            background-color: #ebe9b0;
        }
        &7 {
            background-color: #ead2e6;
        }
    }
</style>
