<script>
	import { onMount } from "svelte";
	import axios from "axios";
	import { link, push } from "svelte-spa-router";
	export let pageLocation;

	let menuOpen = false;
	let logoSrc = "./image/logo.png";
	let userPermission = {
		orders: "",
		business_records: "",
		shifts: "",
		clients: "",
		holidays: "",
		dayoff: "",
		roles: "",
		users: "",
	};
	let userLineId;
	$ : userLineId = getCookie("userLineId");

	onMount(async () => {
		userLineId = getCookie("userLineId")
		if( userLineId !== null || userLineId !== '' ){
			axios.defaults.headers.common["User-Id"] = userLineId;
        	axios.defaults.headers.common["stage"] = "backstage";
		}
		if(getCookie("userPermission") !== null && getCookie("userPermission") !== ''){
			userPermission = JSON.parse(getCookie("userPermission"));
		}
	}) 

	function getCookie(name) {
		var cookieArr = document.cookie.split(";");
		for(var i = 0; i < cookieArr.length; i++) {
			var cookiePair = cookieArr[i].split("=");
			if(name == cookiePair[0].trim()) {
				return decodeURIComponent(cookiePair[1]);
			}
		}
		return null;
	}
	
	const toggleMenu = () => {
		userLineId = getCookie("userLineId")
		if(getCookie("userPermission") !== null && getCookie("userPermission") !== ''){
			userPermission = JSON.parse(getCookie("userPermission"));
		}
		menuOpen = !menuOpen;
	};

	function logout() {
		var cookies = document.cookie.split(";");
		for (var i = 0; i < cookies.length; i++) {
			var cookie = cookies[i];
			var eqPos = cookie.indexOf("=");
			var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
		}
		axios.defaults.headers.common["User-Id"] = '';
		push(`/logout`);
	}

	function edit() {
		push(`/backstage/users/:user_id?self=true`);
	}

	function salary() {
		push(`/backstage/orders/salary`);
	}


</script>

<header class="header">
	<div class="header__con">
		<div class="header__item header__item--left">
			<div class="header--hamburger" on:click={toggleMenu}>
				<button class="hamburger hamburger-cancel mb__only" class:active={menuOpen}>
					<span class="icon" />
				</button>
			</div>
			<div class="header--name">
				<img src={logoSrc} alt="" class="header--logo" />
				<p>協禾詠春傳統國術館</p>
			</div>
		</div>
		<div class="header__item header__item--right">
			<div class="header--function">
				<ul>
					<li on:click={salary}>薪資報表</li>
					<li on:click={edit}>基本資料</li>
					<li on:click={logout}>登出</li>
				</ul>
			</div>
		</div>
	</div>
</header>

<nav class:sidebar-open={menuOpen} on:click={toggleMenu}>
	<ul class="sidebar">
		{#if userPermission !== null && userPermission.orders !== 0}
			<li class:active={pageLocation.includes("/backstage/orders")}>
				<a use:link={{ href: "/backstage/orders/calendar", disabled: false }}>預約管理</a>
			</li>
		{/if}
		{#if userPermission !== null && userPermission.business_records !== 0}
			<li class:active={pageLocation.includes("/backstage/revenue")}>				
				<a use:link={{ href: "/backstage/revenue/calendar", disabled: false }}>營業紀錄管理</a>
			</li>
		{/if}
		{#if userPermission !== null && userPermission.shifts !== 0}
			<li class:active={pageLocation.includes("/backstage/shifts")}>
				<a use:link={{ href: "/backstage/shifts", disabled: false }}>排班管理</a>
			</li>
		{/if}
		{#if userPermission !== null && userPermission.clients !== 0}
			<li class:active={pageLocation.includes("/backstage/clients")}>
				<a use:link={{ href: "/backstage/clients", disabled: false }}>客戶管理</a>
			</li>
		{/if}
		{#if userPermission !== null && userPermission.holidays !== 0 || userPermission.dayoff !==0 }
			<li class:active={pageLocation.includes("/backstage/holidays")}>
				<a use:link={{ href: "/backstage/holidays", disabled: false }}>假日/公休管理</a>
			</li>
		{/if}
		{#if userPermission !== null && userPermission.roles !== 0}
			<li class:active={pageLocation.includes("/backstage/roles")}>
				<a use:link={{ href: "/backstage/roles", disabled: false }}>角色管理</a>
			</li>
		{/if}
		{#if userPermission !== null && userPermission.users !== 0}
			<li class:active={pageLocation.includes("/backstage/users")}>
				<a use:link={{ href: "/backstage/users", disabled: false }}>帳號管理</a>
			</li>
		{/if}
	</ul>
</nav>

<style lang="scss">
	@import "./../../components/scss/_font.scss";
	@import "./../../components/scss/_reset.scss";
	@import "./../../components/scss/_hamburger.scss";

	// header
	.header {
		position: fixed;
		width: 100%;
		height: 60px;
		left: 0;
		top: 0;
		background-color: #f6f6f6;
		z-index: 3;
		font-size: 18px;
		letter-spacing: 1px;
		box-shadow: 0px 0px 5px #c5c5c5;
		&__con {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 60px;
		}
		&__item {
			display: flex;
			align-items: center;
		}
		&--logo {
			margin: auto;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 10px 0 10px;
			width: 40px;
			p {
				font-size: 22px;
				margin-left: 15px;
				letter-spacing: 1px;
				color: #333;
			}
		}
		&--name {
			display: flex;
			align-items: center;
		}
		&--function {
			margin-right: 20px;
			ul {
				display: flex;
				align-items: center;
				a {
					color: #2e3440;
				}
				li {
					color: #2e3440;
					padding: 20px;
					cursor: pointer;
					&:hover {
						opacity: 0.7;
					}
				}
			}
		}
	}
	// header end

	// sidebar
	nav {
		position: fixed;
		z-index: 2;
		left: -400px;
		top: 0;
		height: 100vh;
		width: 200px;
		background-color: #2e3440;
		box-shadow: 0px 0px 5px #c5c5c5;
		text-align: center;
		padding-top: 60px;
		transition: 0.5s;
		> ul {
			padding: 30px 0 0 0;
			> li {
				list-style: none;
				color: #fff;
				font-family: "Noto Sans TC", sans-serif;
				letter-spacing: 1px;
				font-size: 18px;
				line-height: 18px;
				position: relative;
				cursor: pointer;
				&:hover {
					background-color: #3c4350;
				}
				> a {
					color: #fff;
					padding: 20px;
					display: block;
					&:hover {
						opacity: 0.7;
					}
				}
				&.active {
					> a {
						color: #f9d942;
						&:hover {
							opacity: 1;
						}
					}
				}
				// subMenu
				&:hover {
					> ul {
						display: block;
					}
				}
				> ul {
					position: absolute;
					right: -200px;
					width: 200px;
					top: 0;
					display: none;
					background-color: #686c76;
					color: #fff;
					> li {
						> a {
							color: #fff;
							padding: 20px;
							display: block;
							&:hover {
								opacity: 0.7;
							}
						}
						+ li {
							border-top: 1px dashed #ffee9b;
						}
					}
				}
				// subMenu end
			}
		}
		&.sidebar-open {
			left: 0;
		}
	}
	// sidebar end
</style>
