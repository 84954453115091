<script>
    // 暫時用不到！！
    import { onMount } from "svelte";
    import axios from "axios";
  
    let logoSrc = "./image/logo.png";
    let branches = []
  
    onMount(async () => {
        await axios({
            method: "get",
            url: env.BASE_URL + "api/v1/branches",
            // headers:{},
        })
        .then(function (res) {
            branches = res.data;
            console.dir(branches)
        })
        .catch(function (err) {
            console.dir(err);
        });
    });
  </script>
  
  <div class="container">
    <div class="title">
      <h2>分店資訊</h2>
    </div>
  
    <div>
      {#if branches.length !== 0}
        {#each branches as branch}
          <div class="branch__item">
            <p>店名：{branch.branch}</p>
            <p>地址：{branch.branch_address}</p>
            <p>google地圖：</p>
            <p>電話：{branch.branch_tel}</p>
          </div>
        {/each}
      {/if}
      <br>

    </div>
  </div>

  

  <style lang="scss">
    @import "./../../components/scss/_reset.scss";
    @import "./../../components/scss/_font.scss";
  
    ::placeholder {
      color: #aaa;
      font-size: 14px;
    }
  
    .logo {
      margin: auto;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px;
      img {
        width: 40px;
      }
      p {
        font-size: 22px;
        margin-left: 15px;
        letter-spacing: 1px;
        color: #333;
      }
    }
  
    .title {
      display: inline-block;
      h2 {
        font-size: 25px;
        font-weight: 500;
        letter-spacing: 1px;
        color: #425271;
        position: relative;
        margin: auto 0 15px 0;
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: #960e1e;
          bottom: -3px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    .order {
    padding: 100px 0.5rem;
    width: 100%;
    &__con {
      display: inline-block;
      margin: auto;
      width: 100%;
      text-align: center;
    }
    &__item {
      max-width: 400px;
      margin: auto;
      background-color: #f9f9f9;
      padding: 30px;
      border-radius: 15px;
      box-shadow: 2px 2px 5px #ddd;
      color: #333;
      margin: 10px auto 30px;
      text-align: left;
      p {
        font-size: 19px;
        line-height: 33px;
      }
    }
  }
  </style>
  