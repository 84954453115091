<!-- 預約列表 https://0.0.0.0:8443/#/orders/U01190eb884e826dd079efb09b5d4dd16 -->
<script>
  import Button from "./../../components/Input/Button.svelte";
  import axios from "axios";
  import { onMount } from "svelte";
  import SveltyPicker from "svelty-picker";
  import { link } from "svelte-spa-router";
  import liff from "@line/liff";
  import YesOrNo from "./../../components/Lightbox/_frontCheckYesOrNo.svelte";
  import Black from "./../../components/Lightbox/_black.svelte";

  export let params = {};
  let clientLineId = "";
  let message = "";
  let logoSrc = "./image/logo.png";
  let newMember = false;
  let clients = { name: "", gender: "男", area: "", mobile: "", id: "" };

  let orders = [
    // {
    //   client_id: "",
    //   user_id: "",
    //   branch: "",
    //   tel: "",
    //   user: "",
    //   item: [''],
    //   branch_id: 1,
    //   ordered_at: "",
    //   total_price: "",
    //   item_id: "",
    //   note: "",
    // },
  ];

  let showTimeHour;
  let cancelId = "";

  onMount(async () => {
    clientLineId = getCookie("clientLineId");
    if (clientLineId == null || clientLineId == "") {
      const loginPromise = (async () => {
        let profile = {};
        try {
          await liff.init({
            liffId: "1656736039-RxGp3xE8",
          });
          if (!liff.isLoggedIn()) {
            liff.login({ redirectUri: location.href });
          }
          profile = await liff.getProfile();
          clientLineId = profile.userId; // 取得客戶Line ID

          //取得id想打api看看註冊過了嗎
          axios({
            method: "get",
            url: env.BASE_URL + `api/v1/clients/${clientLineId}`,
          })
            .then(function (res) {
              // console.log(res);
              if (res.data.id == null) {
                newMember = true;
              } else {
                newMember = false;
                clients = res.data;
                cookieSetup("clientLineId", clientLineId);

                axios.defaults.headers.common["Client-Id"] = clientLineId;
                axios.defaults.headers.common["stage"] = "forestage";
                axios({
                  method: "get",
                  url: env.BASE_URL + `api/v1/orders/clients/${clientLineId}`,
                })
                .then(function (res) {
                  // console.log(res)
                  orders = res.data;
                })
                .catch(function (err) {
                  console.dir(err);
                  // push('/error')
                });
              }
            })
            .catch(function (err) {
              console.dir(err);
              // push('/error')
            });
        } catch (err) {
          console.log(err);
        }
        return profile;
      })();
    } else {
      //取得id想打api看看註冊過了嗎
      axios({
        method: "get",
        url: env.BASE_URL + `api/v1/clients/${clientLineId}`,
      })
        .then(function (res) {
          // console.log(res);
          if (res.data.id == null) {
            newMember = true;
          } else {
            newMember = false;
            clients = res.data;
            cookieSetup("clientLineId", clientLineId);
            axios.defaults.headers.common["Client-Id"] = clientLineId;
            axios.defaults.headers.common["stage"] = "forestage";
            axios({
              method: "get",
              url: env.BASE_URL + `api/v1/orders/clients/${clientLineId}`,
            })
              .then(function (res) {
                // console.log(res)
                orders = res.data;
              })
              .catch(function (err) {
                console.dir(err);
                // push('/error')
              });
          }
        })
        .catch(function (err) {
          console.dir(err);
          // push('/error')
        });
    }
  });

  function getCookie(name) {
    var cookieArr = document.cookie.split(";");
    for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");
      if (name == cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
  }

  function cookieSetup(name, value) {
    var expires = new Date();
    expires.setTime(expires.getTime() + 1 * 60 * 60 * 1000);
    document.cookie =
      name + "=" + escape(value) + ";path=/;expires=" + expires.toGMTString();
  }

  function cancelBooking(id) {
    cancelId = id;
    document.querySelector(".lightbox--frontYesOrNo").style.display = "block";
    document.querySelector(".black").style.display = "block";
  }

  function closeLightbox() {
    document.querySelector(".message").style.display = "none";
    document.querySelector(".lightbox--frontYesOrNo").style.display = "none";
    document.querySelector(".black").style.display = "none";
  }

  function yes() {
    axios({
      method: "patch",
      url:
        env.BASE_URL +
        `api/v1/orders/${cancelId}/取消預約/login_user/${clientLineId}/f`,
    })
      .then(function (res) {
        // console.log(res);
        if (res.data.status == "successful") {
          message = "訂單已取消！";
          document.querySelector(".lightbox--frontYesOrNo").style.display =
            "none";
          document.querySelector(".message__con").style.background = "#58b957";

          setTimeout(function () {
            document.querySelector(".message").style.display = "flex";
          }, 500);
          cancelId = "";

          axios({
            method: "get",
            url: env.BASE_URL + `api/v1/orders/clients/${clientLineId}`,
          })
            .then(function (res) {
              orders = res.data;
              setTimeout(function () {
                document.querySelector(".message").style.display = "none";
                document.querySelector(".black").style.display = "none";
              }, 2000);
              // console.log(res);
            })
            .catch(function (err) {
              console.dir(err);
              // push('/error')
            });
        } else {
          message = res.data.message;
          document.querySelector(".message__con").style.background = "#f66354";
          document.querySelector(".black").style.display = "black";
          setTimeout(function () {
            document.querySelector(".message").style.display = "flex";
          }, 500);
          // setTimeout(function () {
          //   document.querySelector(".message").style.display = "none";
          // }, 3000);
        }
      })
      .catch(function (err) {
        console.dir(err);
        // push('/error')
      });
  }

  function no() {
    document.querySelector(".lightbox--frontYesOrNo").style.display = "none";
    document.querySelector(".black").style.display = "none";
  }
</script>

<!-- 前台：預約查詢 /orders/<client_id> -->

<div class="header--fixed">
  <div class="header--logo">
    <img src={logoSrc} alt="" class="header--logo" />
    <p>協禾詠春傳統國術館</p>
  </div>
</div>

<div class="order">
  <div class="title">
    <h2>預約資訊</h2>
  </div>

  <div class={newMember ? "order__con blur" : "order__con"}>
    {#if orders.length !== 0}
      {#each orders as order, i}
        <div class="order__item">
          <p>日期：{order.ordered_at}</p>
          <p>地點：{order.branch}</p>
          <p>電話：{order.tel}</p>
          <p>師傅：{order.user}</p>
          <p>服務：{order.items}
              <!-- {#each order.item as detail , i}
                    {#if order.item.length == i + 1}
                        {detail[i] + ' ' }
                    {:else}
                        {detail[i] + ' ' + ' , '}
                    {/if} 
              {/each} -->
            </p>
          <!-- <p>費用：{order.total_price}</p> -->
          <div class="btn">
            <div class="btn__con">
              <div class="btn__item">
                <Button
                  color="#fff"
                  style="margin: 10px 0px;"
                  hoverColor="#f66354"
                  props=""
                  bgColor="#f66354"
                  hoverTextColor="#fff"
                  on:click={(e) => {
                    cancelBooking(order.order_id);
                  }}
                >
                  取消預約
                </Button>
              </div>
            </div>
          </div>
        </div>
      {/each}
    {:else}
      <div>目前無預約</div>
    {/if}
  </div>
</div>
<div class="message">
  <div class="message__con">
    <img
      src="./image/close.png"
      alt=""
      class="close"
      on:click={closeLightbox}
    />
    <p>{message}</p>
  </div>
</div>

{#if newMember}
  <div class="cover">
    <p>請先註冊！</p>
  </div>
{/if}

<Black on:black={closeLightbox} />
<YesOrNo on:yes={yes} on:no={no} on:black={closeLightbox} />

<style lang="scss">
  @import "./../../components/scss/_font.scss";
  @import "./../../components/scss/_reset.scss";

  .header {
    &--fixed {
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      padding: 15px 10px;
      background-color: #425271;
      box-shadow: 0px 0px 8px #b4b3b3;
      z-index: 1;
    }
    &--logo {
      margin: auto;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px 0 10px;
      width: 100%;
      img {
        width: 40px;
      }
      p {
        font-size: 22px;
        margin-left: 5px;
        letter-spacing: 1px;
        color: #f2efef;
      }
    }
  }

  .title {
    display: inline-flex;
    padding: 0.5rem;
    margin: auto;
    width: 100%;
    justify-content: center;
    h2 {
      font-size: 25px;
      font-weight: 500;
      letter-spacing: 1px;
      color: #425271;
      position: relative;
      margin: auto 0 15px 0;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #960e1e;
        bottom: -3px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .order {
    padding: 100px 0.5rem;
    width: 100%;
    &__con {
      display: inline-block;
      margin: auto;
      width: 100%;
      text-align: center;
    }
    &__item {
      max-width: 400px;
      margin: auto;
      background-color: #f9f9f9;
      padding: 30px;
      border-radius: 15px;
      box-shadow: 2px 2px 5px #ddd;
      color: #333;
      margin: 10px auto 30px;
      text-align: left;
      p {
        font-size: 19px;
        line-height: 33px;
      }
    }
  }

  .btn {
    display: flex;
    width: 100%;
    &__con {
      display: flex;
      width: 100%;
      justify-content: center;
    }
    &__item {
      margin: 10px 10px 0;
    }
  }

  .message {
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: none;
    top: 0;
    left: 0;
    z-index: 101;
    align-items: center;
    justify-content: center;
    &__con {
      width: 90%;
      max-width: 500px;
      height: auto;
      text-align: left;
      padding: 50px;
      p {
        font-size: 30px;
        font-weight: 900;
        color: #fff;
      }
      .close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 30px;
        cursor: pointer;
        height: 30px;
        transition: 0.3s;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }

  .cover {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    p {
      color: #000;
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 1px;
    }
  }

  .blur {
    filter: blur(3px);
  }
</style>
