<!-- 後台：營業紀錄列表 /backstage/revenue #61-->

<script>
    import Button from './../../components/Input/Button.svelte';
    import axios from 'axios';
	import { onMount } from 'svelte';
    import SveltyPicker from 'svelty-picker'
    import { Datatable, ColumnFilterInputs } from 'svelte-simple-datatables'
    import { link , push } from 'svelte-spa-router'
    import { chooseCalenderDate_r , chooseCalenderMaster_r , chooseCalenderStore_r } from './../../components/store';
    import { dayjs } from "svelte-time";

  
    let userLineId;
    let userPermission = {
		orders: "",
		business_records: "",
		shifts: "",
		clients: "",
		holidays: "",
		dayoff: "",
		roles: "",
		users: "",
	};

    let searchParams = '';
    let init = true;
    let gross_revenue = 0;
    let gross_balance = 0;

    // 時間
    let dateStart = '';
    let dateEnd = '';
    let date = new Date()

    // 分店
    let storeItems = []
    let storeSelect = [];
    let storeSelectAll = false;

    // 師傅
    let masterItems = [];
    let masterSelect = [];
    let masterSelectAll = false;

    // 狀態
    let statusItems = [ 
    { "id": 1, "status": "預約中" },
    { "id": 2, "status": "取消" },
    { "id": 3, "status": "完成" },
    { "id": 4, "status": "完成未付清" },
    ]
    let statusSelect = ['完成','完成未付清'];
    let statusSelectAll = false;

	// 表單
    let orders = [];
    let rows;
    const settings = { 
        columnFilter: false,  
        rowsPerPage: 10,
        scrollY: false,
        sortable: true,
        css: true,
        blocks: {
            searchInput: false, 
            paginationButtons: true,
            paginationRowCount: false,
        },
        labels: {
            previous: '上一頁',
            next: '下一頁',       
        } 
    }
    

    onMount(async () => {
        userLineId = getCookie("userLineId");
        if( userLineId == null || userLineId == '' ){
            push('/backstage/orders/calendar')
        }else{
            if(getCookie("userPermission") !== null && getCookie("userPermission") !== ''){
                userPermission = JSON.parse(getCookie("userPermission"));
                if(userPermission.business_records == 0){
                    push('/backstage/orders/calendar')  
                }
            }else{
                push('/backstage/orders/calendar')  
            }
        }

        // let calenderDay;
        chooseCalenderDate_r.subscribe(value => {
            // calenderDay = value;
            if(value !== ''){
                dateStart = dayjs(value).format('YYYY-MM-DD')
                dateEnd = dayjs(value).format('YYYY-MM-DD')
                // statusSelect = ['預約中','取消','完成','完成未付清'];
                statusSelect = ['完成','完成未付清'];
            }
        });
        chooseCalenderMaster_r.subscribe(value => {
            if(value!==''){
                masterSelect = value
                statusSelect = ['完成','完成未付清'];
            }else{
                masterSelect = [];
                if( getCookie("userRole") == 'admin'){
                    for(let u = 0; u < masterItems.length; u++){
                        masterSelect.push(masterItems[u].id)
                    }
                }else if( getCookie("userRole") == 'therapist'){
                    for(let u = 0; u < masterItems.length; u++){
                        if(masterItems[u].id == getCookie("userLineId")){
                            masterSelect.push(masterItems[u].id)
                        }
                    }
                }
                masterSelectAll = true;
                document.querySelector('#masterSelectAll').checked = masterSelectAll
            }
        });
        chooseCalenderStore_r.subscribe(value => {
            // calenderDay = value;
            if(value !== ''){
                storeSelect = value
                statusSelect = ['完成','完成未付清'];
            }else{
                storeSelect = [];
                for(let s = 0; s < storeItems.length; s++){
                    storeSelect.push(storeItems[s].branch_id)
                }
                storeSelectAll  = true;
                document.querySelector('#storeSelectAll').checked = storeSelectAll
            }
        });

    
        //店家列表
        await axios({
            method: 'get',
            url: env.BASE_URL + 'api/v1/branches',
            }).then(function (res) {
                storeItems = res.data;
                if(storeSelect == ''){
                    for(let s = 0; s < storeItems.length; s++){
                        storeSelect.push(storeItems[s].branch_id)
                    }
                }
                // console.log(storeSelect);
            }).catch(function (err) {
          console.dir(err);
            push('/error')
            }
        );
        //師傅列表
        await axios({
            method: 'get',
            url: env.BASE_URL + 'api/v1/users',
            }).then(function (res) {
                masterItems = res.data;
                if(masterSelect == ''){
                    if( getCookie("userRole") == 'admin'){
                        for(let u = 0; u < masterItems.length; u++){
                            masterSelect.push(masterItems[u].id)
                        }
                    }else if( getCookie("userRole") == 'therapist'){
                        for(let u = 0; u < masterItems.length; u++){
                            if(masterItems[u].id == getCookie("userLineId")){
                                masterSelect.push(masterItems[u].id)
                            }
                        }
                    }
                }
                // console.log(res);
            }).catch(function (err) {
                console.dir(err);
                // push('/error')
            }
        );


        //預約列表
         await axios({
            method: 'get',
            url: env.BASE_URL + 'api/v1/orders/',
            params:
                {
                    s: `${dateStart} 00:00`,
                    e: `${dateEnd} 23:59`,
                    u: masterSelect.join(','),
                    b: storeSelect.join(','),
                    t: statusSelect.join(','),
                }
            }).then(function (res) {
                // console.log(res);
                orders = res.data.results;
                gross_revenue = res.data.gross_revenue
                gross_balance = res.data.gross_balance
            }).catch(function (err) {
                console.dir(err);
                // push('/error')
            }
        );
    });

    //調整table樣式
    window.setTimeout(function(){
        document.querySelector('.dt-pagination-buttons').style.margin = '0px';
        document.querySelector('.dt-pagination').style.paddingTop = '20px';
        document.querySelector('.dt-table > table').style.marginBottom = '20px';
    },200)
    //調整table樣式 end

    function getCookie(name) {
		var cookieArr = document.cookie.split(";");
		for(var i = 0; i < cookieArr.length; i++) {
			var cookiePair = cookieArr[i].split("=");
			if(name == cookiePair[0].trim()) {
				return decodeURIComponent(cookiePair[1]);
			}
		}
		return null;
	}


    function search(){

        axios({
            method: 'get',
            url: env.BASE_URL + 'api/v1/orders/',
            params:
                {
                    s: `${dateStart} 00:00`,
                    e: `${dateEnd} 23:59`,
                    u: masterSelect.join(','),
                    b: storeSelect.join(','),
                    t: statusSelect.join(','),
                }
            }).then(function (res) {
                orders = res.data.results;
                gross_revenue = res.data.gross_revenue
                gross_balance = res.data.gross_balance
                // console.log(res);
                //讓table搜尋完先回到第一頁
                window.setTimeout(function(){
                    document.querySelector('.dt-pagination-buttons:nth-child(2) button').click()
                },0)
                // console.log(res);
            }).catch(function (err) {
                console.dir(err);
                // push('/error')
            }
        );
    }

    function separator(numb) {
        var str = numb.toString().split(".");
        str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return str.join(".");
    }


    function storeSelectAllClick(event){
        if (!storeSelectAll) {
            storeSelect = [];
            for(let s = 0; s < storeItems.length; s++){
                storeSelect.push(storeItems[s].branch_id)
            }
        }else{
            storeSelect = [];
        }
        // console.log(storeSelect)
        storeSelectAll = !storeSelectAll;
        setTimeout(() => event.target.checked = storeSelectAll, 0);
    }

    function masterSelectAllClick(event){
        if (!masterSelectAll) {
            masterSelect = [];
            if( getCookie("userRole") == 'admin'){
                for(let u = 0; u < masterItems.length; u++){
                    masterSelect.push(masterItems[u].id)
                }
            }else if( getCookie("userRole") == 'therapist'){
                for(let u = 0; u < masterItems.length; u++){
                    if(masterItems[u].id == getCookie("userLineId")){
                        masterSelect.push(masterItems[u].id)
                    }
                }
            }
        }else{
            masterSelect = [];
        }
        masterSelectAll = !masterSelectAll;
        setTimeout(() => event.target.checked = masterSelectAll, 0);
    }

    function statusSelectAllClick(event){
        if (!statusSelectAll) {
            statusSelect = ['預約中','取消','完成','完成未付清'];
        }else{
            statusSelect = [];
        }
        statusSelectAll = !statusSelectAll;
        setTimeout(() => event.target.checked = statusSelectAll, 0);
    }

    function handleInput(event) {
        name = event.target.value;
    }


    function masterSelectchange(){
        setTimeout(function(){
            if(masterSelect.length == masterItems.length){
            masterSelectAll = true;
            document.querySelector('#masterSelectAll').checked = masterSelectAll
            }else{
                masterSelectAll = false;
                document.querySelector('#masterSelectAll').checked = masterSelectAll
            }
        }, 0);
    }

    function storeSelectchange(){
        setTimeout(function(){
            if(storeSelect.length == storeItems.length){
                storeSelectAll = true;
                document.querySelector('#storeSelectAll').checked = storeSelectAll
            }else{
                storeSelectAll = false;
                document.querySelector('#storeSelectAll').checked = storeSelectAll
            }
                // console.log("storeSelect: " + storeSelect.length)
                // console.log("storeItems: " + storeItems.length)
        }, 0);
    }

    function statusSelectchange(){
        setTimeout(function(){
            // console.log(statusSelect)
            if(statusSelect.length == 4){
            statusSelectAll = true;
            document.querySelector('#statusSelectAll').checked = statusSelectAll
            }else{
                statusSelectAll = false;
                document.querySelector('#statusSelectAll').checked = statusSelectAll
            }
        }, 0);
    }


</script>


<div class="title">
    <h2>營業紀錄管理</h2>
</div>

<div class="search">
    <div class="search__con">
        <div class="search__item search--time">
            <div class="time--item"> 
                <p>開始日期：</p>
                <SveltyPicker format="yyyy-mm-dd" bind:value={dateStart}></SveltyPicker>
            </div>
            <div class="time--item">
                <p>結束日期：</p>
                <SveltyPicker format="yyyy-mm-dd" bind:value={dateEnd}></SveltyPicker>
            </div>
        </div>
        <div class="search__item search--store">
            <p>分店：</p>
            <div>
                <div>
                    <input type="checkbox" name="storeSelectAll" on:click|preventDefault={storeSelectAllClick} id="storeSelectAll"><label for="storeSelectAll">&nbsp;全選</label>&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                {#each storeItems as storeItem, i}
                    <div>
                        <input type="checkbox" name="shop" value="{storeItem.branch_id}" id="{storeItem.branch}" bind:group={storeSelect} on:click={storeSelectchange}><label for="{storeItem.branch}">&nbsp;{storeItem.branch}</label>&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                {/each}
            </div>
        </div>
        <div class="search__item search--people">
           <p>師傅：</p>
            <div>
                <div>
                    <input type="checkbox" name="masterSelectAll"  on:click|preventDefault={masterSelectAllClick} id="masterSelectAll" ><label for="masterSelectAll">&nbsp;全選</label>&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                {#if getCookie('userRole') == 'admin'}
                    {#each masterItems as masterItem, i}
                        <div>
                            <input type="checkbox" name="shop" value="{masterItem.id}" id="{masterItem.name}" bind:group={masterSelect} on:click={masterSelectchange}><label for="{masterItem.name}">&nbsp;{masterItem.name}</label>&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                    {/each}
                {:else if getCookie('userRole') == 'therapist'}
                    {#each masterItems as masterItem, i}
                        {#if masterItem.id == getCookie('userLineId')}
                            <div>
                                <input type="checkbox" name="shop" value="{masterItem.id}" id="{masterItem.name}" bind:group={masterSelect} on:click={masterSelectchange}><label for="{masterItem.name}">&nbsp;{masterItem.name}</label>&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                        {/if} 
                    {/each}
                {/if}
            </div>
        </div>
        <div class="search__item search--status">
           <p>狀態：</p>
            <div>
                <div>
                    <input type="checkbox" name="statusSelectAll" on:click|preventDefault={statusSelectAllClick} id="statusSelectAll"><label for="statusSelectAll">&nbsp;全選</label>&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                {#each statusItems as statusItem, i}
                    <div>
                        <input type="checkbox" name="shop" value="{statusItem.status}" id="{statusItem.status}" bind:group={statusSelect} on:click={statusSelectchange}><label for="{statusItem.status}">&nbsp;{statusItem.status}</label>&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                {/each}
            </div>
        </div>
        <div class="search__item search--button">
           <Button
                color="#fff"
                style="margin: 10px 10px;"
                hoverColor="#7386ed"
                props=''
                bgColor = '#7386ed'
                hoverTextColor="#fff"
                on:click={search}
                >
                查詢
            </Button>
        </div>
        <hr>
        <div class="search__item search__item--revenue">
            <div>
                <div><p>完成未付清合計：<span>{separator(gross_balance)}</span></p></div>
                <div><p>總計：<span>{separator(gross_revenue)}</span></p></div>
            </div>
        </div>
    </div>
</div>
<div class="table">
    <div class="table__con">
        <div class="table__item">
            <Datatable settings={settings} data={orders} bind:dataRows={rows}>
                <thead>
                    <th class="sortable asc desc" data-key="branch">分店<span/></th>
                    <th class="sortable asc desc" data-key="user">師傅<span/></th>
                    <th class="sortable asc desc" data-key="ordered_at">時間<span/></th>
                    <th class="" data-key="details">服務<span/></th>
                    <th class="sortable asc desc" data-key="client">客戶<span/></th>
                    <th class="sortable asc desc" data-key="client_mobile">電話<span/></th>
                    <th class="sortable asc desc" data-key="total_price">總金額<span/></th>
                    <th class="sortable asc desc" data-key="status">狀態<span/></th>
                </thead>
                <tbody>
                {#if rows}
                    {#each $rows as row}
                    <tr>
                        <td>{row.branch}</td>
                        <td>{row.user}</td>
                        <td>{(row.ui_daytime)}</td>
                        <td class="td__serve">
                            <ul>
                            {#each row.details as detail , i}
                                <li>
                                    {#if row.details.length == i + 1}
                                        {detail.item}
                                    {:else}
                                        {detail.item + ' , '}
                                    {/if} 
                                </li>
                            {/each}
                            </ul>
                        </td>
                        <td>
                            <a use:link={{href: `/backstage/clients/${row.client_id}`, disabled: false}}>
                                {row.client}
                            </a>
                        </td>
                        <td>{row.client_mobile}</td>
                        <td style="text-align: right; padding-right: 40px">
                            {separator(row.total_price)}
                        </td>
                        <td>{row.status}</td>
                    </tr>
                    {/each}
                {/if}
                </tbody>
            </Datatable>
        </div>
    </div>
</div> 


<style lang="scss">
	@import './../../components/scss/_font.scss';
	@import './../../components/scss/_reset.scss';

    .title{
        display: inline-block;
        padding: 0.5rem;
        h2{
            font-size: 25px;
            font-weight: 500;
            letter-spacing: 1px;
            color: #425271;
            position: relative;
            margin: auto 0 15px 0;
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: #960E1E;
                bottom: -3px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    .search{
        padding: 0.5rem;
        &__item{
            width: 100%;
            display: flex;
            // align-items: center;
            margin: 20px 0;
            > div{
                display: flex;
                flex-wrap: wrap;
                > div{
                    margin: 5px 0;
                    display: flex;
                    align-items: center;
                }
            }
            > p{
               white-space: nowrap;
               margin: 5px 0;
            }
            .time{
                &--item{
                    margin: 10px 20px 10px 0;
                    display: flex;
                    align-items: center;
                }
            }
            &.search--time{
                margin: 0;
            }
            &--revenue{
            display: flex;
            justify-content: flex-end;
            div{
                font-size: 20px;
                p{
                    margin-left: 20px;
                    span{
                        color: #960E1E;
                    }
                }
            }
        }
        }
        &--button{
            display: flex;
            justify-content: flex-end;
        }
    }


    //表單
    .table{
        padding: 0;
    }
    table, th, td {
        border-collapse: collapse;
        border-bottom: 1px solid #ece9e9;
    }
    th {
        padding: 8px 0px 8px 0px;
        text-align: center;
        border-bottom: 1px solid #b9b3b3;
        background: #fff;
    }
    th.sortable {
        cursor: pointer;
    }
    th.sortable span {
        padding-right: 16px;
        position: relative;
    }
    th.sortable span:before,
    th.sortable span:after {
        border: 4px solid transparent;
        content: '';
        display: block;
        height: 0;
        right: 0;
        top: 50%;
        position: absolute;
        width: 0;
    }
    th.sortable span:before {
        border-bottom-color: #e0e0e0;
        margin-top: -9px;
    }
    th.sortable span:after {
        border-top-color: #e0e0e0;
        margin-top: 1px;
    }
    th.sortable.asc:not(.desc) span:before {
        border-bottom-color: #9e9e9e;
    }
    th.sortable.desc:not(.asc) span:after {
        border-top-color: #9e9e9e;
    }

    td{
        line-height: 70px;
        text-align: center;
        padding: 4px 0; 
        word-break: break-all;
    }

    td a{
        color: #000;
        transition: 0.3s;
        display: inline-flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        line-height: 70px;
        &:hover{
            color: rgb(143, 142, 142);
        }
    }

    td.td__serve{
        line-height: inherit;
        padding-bottom: 25px;
    }

</style>
