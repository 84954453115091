<!-- 後台：預約管理新增 /backstage/orders/new -->
<script>
    import { onMount } from "svelte";
    import Button from "./../../components/Input/Button.svelte";
    import axios from "axios";
    import SveltyPicker from "svelty-picker";
    import { link, push } from "svelte-spa-router";
    import MessageError from "./../../components/Lightbox/_messageError.svelte";
    import MessageSuccess from "./../../components/Lightbox/_messageSuccess.svelte";
    import Black from "./../../components/Lightbox/_black.svelte";

    let userLineId;
    let warning = "";
    let userPermission = {
        orders: "",
        business_records: "",
        shifts: "",
        clients: "",
        holidays: "",
        dayoff: "",
        roles: "",
        users: "",
    };
    let message = "";
    let orders = {
        client_id: "",
        user_id: "",
        branch_id: "",
        ordered_at: "",
        item_id: "",
        note: "",
    };
    let showTimeHour;

    // 時間
    let dateStart = "";
    let date = new Date();

    // 分店
    let storeItems = [];
    let storeSelect = [];

    // 師傅
    let masterItems = [];
    let masterSelect = [];

    //服務
    let items = [];
    let itemSelect = [];

    //搜尋
    let tel = "";
    $: tel !== "" && tel.length > 4 ? addDash() : addDash();
    let clientNames = [];

    //時間
    let time__hour, time__min, time__period;

    onMount(async () => {
        userLineId = getCookie("userLineId");
        if (userLineId == null || userLineId == "") {
            push("/backstage/orders/calendar");
        } else {
            if (
                getCookie("userPermission") !== null &&
                getCookie("userPermission") !== ""
            ) {
                userPermission = JSON.parse(getCookie("userPermission"));
                if (userPermission.orders !== 2) {
                    push("/backstage/orders/calendar");
                }
            } else {
                push("/backstage/orders/calendar");
            }
        }

        //店家列表
        await axios({
            method: "get",
            url: env.BASE_URL + "api/v1/branches",
        })
            .then(function (res) {
                storeItems = res.data;
                // console.log(res);
            })
            .catch(function (err) {
                console.dir(err);
                // push('/error')
            });
        //師傅列表
        await axios({
            method: "get",
            url: env.BASE_URL + "api/v1/users",
        })
            .then(function (res) {
                masterItems = res.data;
                // console.log(res);
            })
            .catch(function (err) {
                console.dir(err);
                // push('/error')
            });

        //服務列表
        await axios({
            method: "get",
            url: env.BASE_URL + "api/v1/items",
        })
            .then(function (res) {
                items = res.data;
                // console.log(res);
            })
            .catch(function (err) {
                console.dir(err);
                // push('/error')
            });
    });

    function getCookie(name) {
        var cookieArr = document.cookie.split(";");
        for (var i = 0; i < cookieArr.length; i++) {
            var cookiePair = cookieArr[i].split("=");
            if (name == cookiePair[0].trim()) {
                return decodeURIComponent(cookiePair[1]);
            }
        }
        return null;
    }

    function search() {
        axios({
            method: "get",
            url: env.BASE_URL + `api/v1/clients/mobile/${tel}`,
        })
            .then(function (res) {
                // names.name = res.data[0].name;
                clientNames = res.data;
                clientNames = Object.keys(clientNames).map(function (_) {
                    return clientNames[_];
                });
                orders.name = res.data[0].name;
                orders.client_id = res.data[0].id;

                if (!res.data[0].active) {
                    warning = "帳號已停用";
                } else {
                    warning = "";
                }
                // console.log(orders.name)
            })
            .catch(function (err) {
                console.dir(err);
                // push('/error')
            });
    }

    function enter(event) {
        if (event.key === "Enter") {
            axios({
                method: "get",
                url: env.BASE_URL + `api/v1/clients/mobile/${tel}`,
            })
                .then(function (res) {
                    clientNames = res.data;
                    clientNames = Object.keys(clientNames).map(function (_) {
                        return clientNames[_];
                    });
                    orders.name = res.data[0].name;
                    orders.client_id = res.data[0].id;
                    // console.log(clientNames)
                    if (!res.data[0].active) {
                        warning = "帳號已停用";
                    } else {
                        warning = "";
                    }
                })
                .catch(function (err) {
                    console.dir(err);
                    // push('/error')
                });
        }
    }

    function changeClient() {
        for (let c = 0; c < clientNames.length; c++) {
            if (clientNames[c].name == orders.name) {
                orders.client_id = clientNames[c].id;
                if (clientNames[c].active) {
                    warning = "帳號已停用";
                } else {
                    warning = "";
                }
            }
        }
    }

    function save() {
        showTimeHour = 0;
        if (time__period == "PM") {
            showTimeHour = parseInt(time__hour) + 12;
            orders.ordered_at = `${
                dateStart + " " + showTimeHour + ":" + time__min + ":00"
            }`;
        } else {
            orders.ordered_at = `${
                dateStart + " " + time__hour + ":" + time__min + ":00"
            }`;
        }

        if (orders.client_id == "") {
            alert("客戶為必填！");
        } else if (orders.branch_id == "") {
            alert("分店為必填！");
        } else if (orders.item_id == "") {
            alert("服務為必填！");
        } else if (orders.user_id == "") {
            alert("師傅為必填！");
        } else if (dateStart == "") {
            alert("日期為必填！");
        } else if (dateStart == null) {
            alert("日期為必填！");
        } else {
            axios({
                method: "post",
                url: env.BASE_URL + `api/v1/orders/b`,
                data: {
                    client_id: orders.client_id,
                    user_id: orders.user_id,
                    branch_id: orders.branch_id,
                    item_id: orders.item_id,
                    ordered_at: orders.ordered_at,
                    note: orders.note.replaceAll(" ", ""),
                },
            })
                .then(function (res) {
                    // console.log(res)
                    if (res.data.status == "successful") {
                        message = res.data.message;
                        success(res.data.order_id);
                    } else {
                        message = res.data.message;
                        fail();
                    }
                })
                .catch(function (err) {
                    console.dir(err);
                    // push('/error')
                });
        }
    }

    function success(orderId) {
        // message = '修改成功！'
        document.querySelector(".black").style.display = "block";
        document.querySelector(".lightbox--messageSuccess").style.display =
            "inline-block";
        setTimeout(function () {
            document.querySelector(".lightbox--messageSuccess").style.display =
                "none";
            document.querySelector(".black").style.display = "none";
            push(`/backstage/orders/${orderId}`);
        }, 2000);
    }

    function fail() {
        document.querySelector(".lightbox--messageError").style.display =
            "inline-block";
        document.querySelector(".black").style.display = "block";
    }

    function closeLightbox() {
        document.querySelector(".lightbox--messageError").style.display =
            "none";
        document.querySelector(".black").style.display = "none";
    }

    function addDash() {
        if (
            tel.replaceAll(" ", "") !== "" &&
            tel.replaceAll(" ", "").length > 5
        ) {
            if (tel.indexOf("-") == -1) {
                let tel2 = tel.substring(4, tel.length);
                tel = tel.replaceAll(" ", "").substring(0, 4) + "-" + tel2;
            }
        } else {
            tel.replaceAll("-", "");
        }
    }
</script>

<svelte:window on:keydown={enter} />

<div class="search">
    <div class="search__con">
        <div class="search__item search--title">
            <h2>選擇客戶</h2>
        </div>
        <div class="search__item search--tel">
            <p>電話：</p>
            <!--placeholder="0939-259438" -->
            <input type="tel" bind:value={tel} maxlength="13" />
        </div>
        <div class="search__item search--name">
            <p>姓名：</p>
            <select
                name="name"
                id="name"
                bind:value={orders.name}
                on:change={changeClient}
            >
                {#each clientNames as clientName, i}
                    <option value={clientName.name} id={clientName.id}
                        >&nbsp;{clientName.name}</option
                    >
                {/each}
            </select>
            {#if warning !== ""}
                <div class="search--warning">
                    <p>* {warning}</p>
                </div>
            {/if}
        </div>
        <div class="search__item search--button">
            <Button
                color="#fff"
                style="margin: 10px 0px;"
                hoverColor="#7386ed"
                props=""
                bgColor="#7386ed"
                hoverTextColor="#fff"
                on:click={search}
            >
                查詢
            </Button>
        </div>
    </div>
</div>

<hr />

<div class="new">
    <div class="new__con">
        <div class="new__item">
            <h2>預約資訊</h2>
        </div>
        <div class="new__item new--store">
            <p>分店：</p>
            <div>
                {#each storeItems as storeItem, i}
                    <div>
                        <input
                            type="radio"
                            name="shop"
                            value={storeItem.branch_id}
                            id={storeItem.branch}
                            bind:group={orders.branch_id}
                        /><label for={storeItem.branch}
                            >&nbsp;{storeItem.branch}</label
                        >&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                {/each}
            </div>
        </div>
        <div class="new__item new--items">
            <p>服務：</p>
            <div>
                {#each items as item, i}
                    <div>
                        <input
                            type="radio"
                            name="item"
                            value={item.item_id}
                            id={item.item}
                            bind:group={orders.item_id}
                            class={item.item_type == "服務" ? "" : "typeItem"}
                        /><label
                            for={item.item}
                            class={item.item_type == "服務" ? "" : "typeItem"}
                            >&nbsp;{item.item}</label
                        >&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                {/each}
            </div>
        </div>
        <span class="new--ps">* 選取商品不發送簡訊</span>
        <div class="new__item new--people">
            <p>師傅：</p>
            <div>
                {#each masterItems as masterItem, i}
                    <div>
                        <input
                            type="radio"
                            name="master"
                            value={masterItem.id}
                            id={masterItem.name}
                            bind:group={orders.user_id}
                        /><label for={masterItem.name}
                            >&nbsp;{masterItem.name}</label
                        >&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                {/each}
            </div>
        </div>
        <div class="new__item new--time">
            <p>預約時段：</p>
            <div class="time--item">
                <p>日期：</p>
                <SveltyPicker format="yyyy-mm-dd" bind:value={dateStart} />
            </div>
            <div class="time--item time--time">
                <p>時間：</p>
                <select
                    name="time__hour"
                    id="time__hour"
                    bind:value={time__hour}
                >
                    <option value="01" id="1">&nbsp; 1</option>
                    <option value="02" id="2">&nbsp; 2</option>
                    <option value="03" id="3">&nbsp; 3</option>
                    <option value="04" id="4">&nbsp; 4</option>
                    <option value="05" id="5">&nbsp; 5</option>
                    <option value="06" id="6">&nbsp; 6</option>
                    <option value="07" id="7">&nbsp; 7</option>
                    <option value="08" id="8">&nbsp; 8</option>
                    <option value="09" id="9">&nbsp; 9</option>
                    <option value="10" id="10">&nbsp; 10</option>
                    <option value="11" id="11">&nbsp; 11</option>
                    <option value="12" id="12">&nbsp; 12</option>
                </select>

                <select name="time__min" id="time__min" bind:value={time__min}>
                    <option value="00" id="1">&nbsp; 00</option>
                    <option value="10" id="2">&nbsp; 10</option>
                    <option value="20" id="3">&nbsp; 20</option>
                    <option value="30" id="4">&nbsp; 30</option>
                    <option value="40" id="5">&nbsp; 40</option>
                    <option value="50" id="6">&nbsp; 50</option>
                </select>

                <select
                    name="time__period"
                    id="time__period"
                    bind:value={time__period}
                >
                    <option value="AM" id="1">&nbsp; AM</option>
                    <option value="PM" id="2">&nbsp; PM</option>
                </select>
            </div>
        </div>
        <div class="new__item new--note">
            <p>備註：</p>
            <textarea
                name=""
                id=""
                rows="5"
                cols="50"
                bind:value={orders.note}
            />
        </div>

        <div class="new__item new--button">
            <Button
                style="margin: 10px 0px;"
                hoverColor="#44abf1"
                props=""
                color="#fff"
                bgColor="#44abf1"
                hoverTextColor="#fff"
                on:click={save}
            >
                儲存
            </Button>
        </div>
    </div>
</div>

<MessageSuccess {message} />
<MessageError {message} on:close={closeLightbox} />
<Black on:black={closeLightbox} />

<style lang="scss">
    @import "./../../components/scss/_font.scss";
    @import "./../../components/scss/_reset.scss";

    .search {
        padding: 0.5rem;
        &__con {
            display: flex;
            flex-wrap: wrap;
        }
        &__item {
            display: flex;
            align-items: center;
            margin: 20px 0;
            > div {
                display: flex;
                flex-wrap: wrap;
            }
            input {
                width: 200px;
                margin-right: 20px;
            }
            > p {
                white-space: nowrap;
            }
            h2 {
                font-size: 25px;
                font-weight: 500;
                letter-spacing: 1px;
                color: #425271;
                position: relative;
                margin: auto 0 15px 0;
                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background-color: #960e1e;
                    bottom: -3px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            select {
                width: 190px;
                height: 38.78px;
            }
        }
        &--title {
            width: 100%;
            margin: 0;
        }
        &--warning {
            top: -15px;
            position: absolute;
            left: 50px;
            right: unset;
            border: 50%;
            transform: translateY(0%);
            @media screen and(min-width: 1100px) {
                position: absolute;
                right: -110px;
                left: unset;
                top: 50%;
                transform: translateY(-50%);
            }
            p {
                color: #f00;
            }
        }
        &--button {
            display: flex;
            margin-left: 20px;
            @media screen and(min-width: 1100px) {
                justify-content: flex-end;
                margin-left: 0px;
                width: calc(100% - 510px);
            }
        }
    }

    .new {
        padding: 0.5rem;
        &--ps {
            color: #f00;
        }
        .typeItem {
            color: #f00;
            accent-color: #f00;
        }
        &__item {
            width: 100%;
            display: flex;
            margin: 20px 0;
            > div {
                display: flex;
                flex-wrap: wrap;
                > div {
                    margin: 5px 0;
                    display: flex;
                    align-items: center;
                    input {
                        margin: 0;
                    }
                }
            }
            > p {
                white-space: nowrap;
                margin: 5px 0;
            }
            h2 {
                font-size: 25px;
                font-weight: 500;
                letter-spacing: 1px;
                color: #425271;
                position: relative;
                margin: auto 0 15px 0;
                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background-color: #960e1e;
                    bottom: -3px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            .time {
                &--item {
                    justify-content: center;
                    display: flex;
                    align-items: center;
                    margin: 10px 0 10px 20px;
                    p {
                        color: #444;
                        font-size: 15px;
                    }
                    select {
                        width: 190px;
                        height: 40px;
                    }
                    input {
                        height: 40px;
                        margin-right: 20px;
                    }
                }
                &--time {
                    width: auto;
                    > select {
                        margin-right: 10px;
                        width: 100px;
                    }
                }
            }
            &.order--time {
                margin: 0;
            }
        }
        &--button {
            width: fit-content;
            display: flex;
            margin: 20px 0 20px auto;
        }
        &--option {
            align-items: center;
        }
        &--time {
            display: flex;
            align-items: center;
        }
        &--title {
            width: 100%;
            margin: 0;
        }
        &--note {
            textarea {
                width: 100%;
                resize: none;
            }
        }
        &--balance {
            width: 450px;
            margin: 0;
            margin-left: 20px;
            align-items: center;
        }
        &--others {
            display: block;
            h2 {
                display: inline-block;
            }
            > div {
                > div {
                    width: 100%;
                }
            }
            select {
                width: 200px;
            }
        }
    }
</style>
