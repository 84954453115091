<!-- 排班列表<div>Backstage_Shifts</div> -->
<script>
    import { onMount } from 'svelte';
    import { link, push } from "svelte-spa-router";
    import axios from 'axios';
    import SveltyPicker from 'svelty-picker'
    import Button from './../../components/Input/Button.svelte';
    import { config } from 'svelty-picker';
    import { yearMonths } from './../../components/shiftDate';

    let userLineId;
    let userPermission = {
		orders: "",
		business_records: "",
		shifts: "",
		clients: "",
		holidays: "",
		dayoff: "",
		roles: "",
		users: "",
	};

    let initYear = [];
    let initListMonth =[];
    let initListDate = [];
    let initListYear=[];
    let initListYearPlus1 = [];
    let initListMonthPlus1 =[];
    let chooseRange = ''  //todo::

    // 分店
    let storeItems = []
    let storeSelect = 1;

    //shift
    let shifts = [];
        
    // 假日種類
    let holidayItem  = '';


    onMount(async () => {
        userLineId = getCookie("userLineId");
        if( userLineId == null || userLineId == '' ){
            push('/backstage/orders/calendar')
        }else{
            if(getCookie("userPermission") !== null && getCookie("userPermission") !== ''){
                userPermission = JSON.parse(getCookie("userPermission"));
                if(userPermission.shifts == 0){
                    push('/backstage/orders/calendar')  
                }
            }else{
                push('/backstage/orders/calendar')  
            }
        }

        // 初始化年度為當年
        let date = new Date;
        initYear =  `${date.getFullYear().toString()}-0${(date.getMonth()+1).toString()}`;
        initListMonth =  `${(date.getMonth()+1).toString()}`
        // initListMonth = '01';
        initListYearPlus1 =  `${(date.getFullYear()+1).toString()}`
        if(initListMonth >= 9){
            initListDate =  `${date.getFullYear().toString()}-${(date.getMonth()+1).toString()}`;
            initListMonthPlus1 = `${parseInt(initListMonth) + 1}`
            initYear = `${date.getFullYear().toString()}-${(
            date.getMonth() + 1).toString()}`;
            // initListMonthPlus1 = `${(date.getMonth()+2).toString()}`
        }else{
            initListDate =  `${date.getFullYear().toString()}-0${(date.getMonth()+1).toString()}`;
            initListMonthPlus1 = `0${parseInt(initListMonth) + 1}`
        }
        initListYear =  `${date.getFullYear().toString()}-`
        chooseRange = initYear

        await axios({
            method: 'get',
            url: env.BASE_URL + `api/v1/shifts/${chooseRange.split('-')[0]}/${chooseRange.split('-')[1]}/${storeSelect}`,
            }).then(function (res) {
                shifts = res.data
                shifts = Object.keys(shifts).map(function(_) { return shifts[_]; });
                // console.log(`api/v1/shifts/${chooseRange.split('-')[0]}/${chooseRange.split('-')[1]}/${storeSelect}`)
            }).catch(function (err) {
                // console.dir(err);
            }
        );

        //店家列表
        await axios({
            method: 'get',
            url: env.BASE_URL + 'api/v1/branches',
            }).then(function (res) {
                storeItems = res.data;
                // console.log(res);
            }).catch(function (err) {
                // console.dir(err);
            }
        );

         //holiday名稱列表
         await axios({
            method: 'get',
            url: env.BASE_URL + 'api/v1/holidays/type',
            }).then(function (res) {
                holidayItem = res.data;
                // console.log(holidayItem)

                setTimeout( function(){
                    let td = document.querySelectorAll('td')
                    for(let t=0;t<td.length;t++){
                        td[t].style.backgroundColor = '';
                        td[t].style.textAlign = 'inherit';
                        // console.log(td[t].firstChild.innerHTML)
                        if(td[t].hasChildNodes()){
                            if(td[t].childNodes.length == 1){
                                if(holidayItem.includes(td[t].firstChild.innerHTML)){
                                    // console.log(td[t].getAttribute('data-place'))
                                    let originClass = td[t].getAttribute('class')
                                    // td[t].setAttribute('class',`${originClass} rest`)
                                    td[t].style.backgroundColor = 'rgb(242 212 39 / 81%)';
                                    td[t].style.textAlign = 'center';
                                    td[t].firstChild.style.backgroundColor = 'rgb(242 212 39 / 0%)';
                                }else{
                                    td[t].style.textAlign = 'left';
                                }
                            }
                            if(td[t].innerHTML == '星期六' || td[t].innerHTML == '星期日'){
                                let originClass = td[t].getAttribute('class')
                                td[t].setAttribute('class',`${originClass} weekend`)
                                td[t].style.backgroundColor = 'rgb(255 157 139 / 81%)';
                            }
                        }
                    }
                },0)
            }).catch(function (err) {
                // console.dir(err);
            }
        );
    });

    function getCookie(name) {
		var cookieArr = document.cookie.split(";");
		for(var i = 0; i < cookieArr.length; i++) {
			var cookiePair = cookieArr[i].split("=");
			if(name == cookiePair[0].trim()) {
				return decodeURIComponent(cookiePair[1]);
			}
		}
		return null;
	}

    function change(){
        axios({
            method: 'get',
            url: env.BASE_URL + `api/v1/shifts/${chooseRange.split('-')[0]}/${chooseRange.split('-')[1]}/${storeSelect}`,
            }).then(function (res) {
                shifts = res.data
                shifts = Object.keys(shifts).map(function(_) { return shifts[_]; });

                setTimeout( function(){
                    let td = document.querySelectorAll('td')
                    for(let t=0;t<td.length;t++){
                        td[t].style.backgroundColor = '';
                        td[t].style.textAlign = 'inherit';
                        // console.log(td[t].firstChild.innerHTML)
                        if(td[t].hasChildNodes()){
                            if(td[t].childNodes.length == 1){
                                if(holidayItem.includes(td[t].firstChild.innerHTML)){
                                    // console.log(td[t].getAttribute('data-place'))
                                    let originClass = td[t].getAttribute('class')
                                    // td[t].setAttribute('class',`${originClass} rest`)
                                    td[t].style.backgroundColor = 'rgb(242 212 39 / 81%)';
                                    td[t].style.textAlign = 'center';
                                    td[t].firstChild.style.backgroundColor = 'rgb(242 212 39 / 0%)';
                                }else{
                                    td[t].style.textAlign = 'left';
                                }
                            }
                            if(td[t].innerHTML == '星期六' || td[t].innerHTML == '星期日'){
                                let originClass = td[t].getAttribute('class')
                                td[t].setAttribute('class',`${originClass} weekend`)
                                td[t].style.backgroundColor = 'rgb(255 157 139 / 81%)';
                            }
                        }
                    }
                },0)
            }).catch(function (err) {
                // console.dir(err);
            }
        );
    }


</script>

<div class="title">
    <h2>排班管理</h2>
</div>

<div class="shift">
    <div class="shift__con shift__con--up">
        <div class="search">
            <select name="year" id="year" bind:value={chooseRange} on:change={change}>
                {#each yearMonths as yearMonth, i}
                {#if initListDate >= yearMonth.format}
                    <option  value="{yearMonth.format}" id="{yearMonth.day}" >&nbsp;{yearMonth.show}</option>
                {/if}

                {#if yearMonth.format == initListYear + initListMonthPlus1}
                    <option  value="{yearMonth.format}" id="{yearMonth.day}" >&nbsp;{yearMonth.show}</option>
                {/if}

                {#if initListMonth == '12'}
                    {#if yearMonth.format == initListYearPlus1 + '-01' }
                    <option  value="{yearMonth.format}" id="{yearMonth.day}" >&nbsp;{yearMonth.show}</option>
                    {/if}
                {/if}
            {/each}
            </select>
            
            <select name="store" id="store"  bind:value={storeSelect} on:change={change}>
                {#each storeItems as storeItem, i}
                    <option  value="{storeItem.branch_id}" id="{storeItem.branch}">&nbsp;{storeItem.branch}</option>
                {/each}
            </select>
        </div>
        {#if userPermission !== null && userPermission.shifts == 2}
            <div class="shift">
                <a use:link={{href: `/backstage/shifts/${chooseRange.split('-')[0]}/${chooseRange.split('-')[1]}/${userLineId}`, disabled: false}}>
                    <Button
                    style="margin: 20px 0px;"
                    hoverColor="#58b957"
                    props=''
                    bgColor = '#58b957'
                    color= "#fff"
                    hoverTextColor="#fff"
                    >
                    排班
                    </Button>
                </a>
            </div>
        {/if}
    </div>
    <div class="shift__con shift__con--down">
        <table>
        <tr>
          <th style="width: 7%;">日期</th>
          <th style="width: 15%;">星期</th>
          <th style="width: 26%; background: #44abf1">上午</th>
          <th style="width: 26%; background: #58b957">下午</th>
          <th style="width: 26%; background: #faa83b">晚上</th>
        </tr>
        {#each yearMonths as yearMonth,i}
            {#if yearMonth.format == chooseRange}
            {#each  { length: yearMonth.day } as _, i}
            <tr>
                <td class="td--center">    
                    {i+1}
                </td>
                <td class="td--center">    
                    {#if (parseInt(yearMonth.week) + 8 + parseInt(i)) % 7 == 0 }
                    星期六
                    {:else if (parseInt(yearMonth.week)+ 8 + parseInt(i)) % 7 == 1}
                    星期日
                    {:else if (parseInt(yearMonth.week) + 8 + parseInt(i)) % 7 == 2}
                    星期一
                    {:else if (parseInt(yearMonth.week) + 8 + parseInt(i)) % 7 == 3}
                    星期二
                    {:else if (parseInt(yearMonth.week) + 8 + parseInt(i)) % 7 == 4}
                    星期三
                    {:else if (parseInt(yearMonth.week) + 8 + parseInt(i)) % 7 == 5}
                    星期四
                    {:else if (parseInt(yearMonth.week) + 8 + parseInt(i)) % 7 == 6}
                    星期五
                    {/if}
                </td>
                    {#each shifts as shift,j}
                        {#if (i) == j}
                            <td>
                                {#each shift.morning as morning , j}
                                    <span>{morning}</span>
                                {/each}
                                <!-- { shift.morning } -->
                            </td>
                        {/if} 
                    {/each}
                    {#each shifts as shift,j}
                        {#if (i) == j}
                            <td>
                                {#each shift.afternoon as afternoon , j}
                                    <span>{afternoon}</span>
                                {/each}
                                <!-- { shift.afternoon } -->
                            </td>
                        {/if} 
                    {/each}
                    {#each shifts as shift,j}
                        {#if (i) == j}
                            <td>
                                {#each shift.evening as evening , j}
                                    <span>{evening}</span>
                                {/each}
                                <!-- { shift.evening } -->
                            </td>
                        {/if} 
                    {/each}
            </tr>
            {/each}
            {/if}
        {/each}
      </table>
    </div>
</div>




<style lang="scss">
    @import './../../components/scss/_font.scss';
	@import './../../components/scss/_reset.scss';

    .title{
        // display: inline-block;
        width: 100%;
        max-width: 1200px;
        margin: auto;
        h2{
            font-size: 25px;
            font-weight: 500;
            letter-spacing: 1px;
            color: #425271;
            position: relative;
            margin: auto 0 10px 0px;
            display: inline-block;
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: #960E1E;
                bottom: -3px;
                left: 52px;
                transform: translateX(-50%);
            }
        }
    }


    .shift{
        display: flex;
        flex-wrap: wrap;
        &__con{
            width: 100%;
            max-width: 1200px;
            margin: auto;
            &--up{
                display: flex;
                justify-content: space-between;

            }
        }
    }
    .search{
        max-width: 1200px;
        width: 100%;
        margin: 20px auto; 
        display: flex;
        select{
            width: 200px;
            margin-right: 20px;
        }
    }



    //表單
    table{
        max-width: 1200px;
        width: 100%;
        margin: auto;
    }
    table, th, td {
        border: 1px solid #ece9e9;
        border-collapse: collapse;
    }
    th {
        padding: 8px 0px 8px 0px;
        text-align: center;
    }
    th.sortable {
        cursor: pointer;
    }
    th.sortable span {
        padding-right: 16px;
        position: relative;
    }
    th.sortable span:before,
    th.sortable span:after {
        border: 4px solid transparent;
        content: '';
        display: block;
        height: 0;
        right: 0;
        top: 50%;
        position: absolute;
        width: 0;
    }
    th.sortable span:before {
        border-bottom-color: #e0e0e0;
        margin-top: -9px;
    }
    th.sortable span:after {
        border-top-color: #e0e0e0;
        margin-top: 1px;
    }
    th.sortable.asc:not(.desc) span:before {
        border-bottom-color: #9e9e9e;
    }
    th.sortable.desc:not(.asc) span:after {
        border-top-color: #9e9e9e;
    }

    td{
        text-align: left;
        padding: 10px; 
        line-height: 35px; 
    }
    td span{
        background-color: #eae8e8;
        border-radius: 20px;
        margin: 5px 5px 5px;
        padding: 2px 10px;
        display: inline-table;
    }
    .rest{
        background-color: rgb(242 212 39 / 81%);
        color: #333;
        text-align: center;
    }
    .td--center{
        text-align: center !important;
    }
    .weekend{
        background-color: rgb(255 157 139 / 81%);
    }
</style>