<!-- 後台：客戶管理列表  /backstage/clients -->
<script>
    import axios from 'axios';
    import { onMount } from 'svelte';
    import { Datatable, ColumnFilterInputs } from 'svelte-simple-datatables'
    import {link,push} from 'svelte-spa-router'
    import Button from './../../components/Input/Button.svelte';

    let userLineId;
    let userPermission = {
		orders: "",
		business_records: "",
		shifts: "",
		clients: "",
		holidays: "",
		dayoff: "",
		roles: "",
		users: "",
	};

    //表單
    let clients = [];
    let tel = '';
    let names = [];
    let rows;
    const settings = { 
        columnFilter: false,  
        rowsPerPage: 20,
        scrollY: false,
        sortable: true,
        css: true,
        blocks: {
            searchInput: false, 
            paginationButtons: true,
            paginationRowCount: false,
        },
        labels: {
            previous: '上一頁',
            next: '下一頁',       
        } 
    }
    
    $: tel !== '' && tel.length > 4  ? addDash() : addDash()

    onMount(async () => {
        userLineId = getCookie("userLineId");
        if( userLineId == null || userLineId == '' ){
            push('/backstage/orders/calendar')
        }else{
            if(getCookie("userPermission") !== null && getCookie("userPermission") !== ''){
                userPermission = JSON.parse(getCookie("userPermission"));
                if(userPermission.clients == 0){
                    push('/backstage/orders/calendar')  
                }
                //帳號列表
                await axios({
                    method: 'get',
                    url: env.BASE_URL + 'api/v1/clients',
                    }).then(function (res) {
                        clients = res.data;
                        // console.log(res);
                    }).catch(function (err) {
                        console.dir(err);
                        // push('/error')
                    }
                );
                //調整table樣式
                window.setTimeout(function(){
                    document.querySelector('.dt-pagination-buttons').style.margin = '0px';
                    document.querySelector('.dt-pagination').style.paddingTop = '20px';
                    document.querySelector('.dt-table > table').style.marginBottom = '20px';
                },200)
                //調整table樣式 end
            }else{
                push('/backstage/orders/calendar')  
            }
        }
        
    });

    function changeStatus(id) {
        if(userPermission !== null && userPermission.orders == 2){
            axios({
                method: 'patch',
                url: env.BASE_URL + `api/v1/clients/${id}/toggle`,
                }).then(function (res) {
                    // console.log(res);
                    axios({
                        method: 'get',
                        url: env.BASE_URL + 'api/v1/clients',
                        }).then(function (res) {
                            clients = res.data;
                            // console.log(res);
                        }).catch(function (err) {
                            console.dir(err);
                            // push('/error')
                        }
                    );
                }).catch(function (err) {
                    console.dir(err);
                    // push('/error')
                }
            );
        }
	}

    function getCookie(name) {
		var cookieArr = document.cookie.split(";");
		for(var i = 0; i < cookieArr.length; i++) {
			var cookiePair = cookieArr[i].split("=");
			if(name == cookiePair[0].trim()) {
				return decodeURIComponent(cookiePair[1]);
			}
		}
		return null;
	}


    function addDash(){
        if(tel.replaceAll(' ','') !== '' && tel.replaceAll(' ','').length > 5){
            if(tel.indexOf('-')==-1){
                let tel2 = tel.substring(4,tel.length)
                tel = tel.replaceAll(' ','').substring(0,4) + '-' + tel2
            }
        }else{
            tel.replaceAll('-','')
        }
    }

    function searchName(tel){
        axios({
            method: 'get',
            url: env.BASE_URL + `api/v1/clients/mobile/${tel}`,
            }).then(function (res) {
                names = res.data;
                // console.log(res);
            }).catch(function (err) {
                console.dir(err);
                // push('/error')
            }
        );
    }

    function search(){
        axios({
            method: 'get',
            url: env.BASE_URL + `api/v1/clients/mobile/${tel}`,
            }).then(function (res) {
                if(res.data.id !== null){
                    clients = res.data;
                }
            }).catch(function (err) {
                console.dir(err);
                // push('/error')
            }
        );
    }

    function enter(event){
        if (event.key === 'Enter') {
            axios({
                method: 'get',
                url: env.BASE_URL + `api/v1/clients/mobile/${tel}`,
                }).then(function (res) {
                    if(res.data.id !== null){
                        clients = res.data;
                    }
                }).catch(function (err) {
                    console.dir(err);
                    // push('/error')
                }
            );
        }
    }

</script>

<svelte:window on:keydown={enter}/>

<div class="title">
    <h2>客戶管理</h2>
</div>

<div class="new">
    {#if userPermission !== null && userPermission.clients == 2}
        <a use:link={{href: `/backstage/clients/new`, disabled: false}}>
            <Button
                style=""
                hoverColor="#58b957"
                props=''
                bgColor = '#58b957'
                color= "#fff"
                hoverTextColor="#fff"
                >
                新增
            </Button>
        </a>
    {/if}
</div>

<div class="search">
    <div class="search__con">
        <div class="search__item">
            電話：<input type="tel"  placeholder="" on:input={(e)=>(searchName(tel))} bind:value={tel}>
        </div>
        <div class="search__item search--button">
            <Button
                style=""
                hoverColor="#7386ed"
                props=''
                bgColor = '#7386ed'
                color= "#fff"
                hoverTextColor="#fff"
                on:click={search}
                >
                查詢
            </Button>
        </div>
    </div>
</div>



<div class="table">
    <div class="table__con">
        <div class="table__item">
            <Datatable settings={settings} data={clients} bind:dataRows={rows}>
                <thead>
                    <th class="sortable asc desc" data-key="name">姓名<span/></th>
                    <th class="sortable asc desc" data-key="mobile">電話<span/></th>
                    <th class="sortable asc desc" data-key="gender">性別<span/></th>
                    <th class="sortable asc desc" data-key="area">地區<span/></th>
                    <th class="sortable asc desc" data-key="note">備註<span/></th>
                    <th class="sortable asc desc" data-key="active">狀態<span/></th>
                </thead>
                <tbody>
                {#if rows}
                    {#each $rows as row}
                    <tr>
                        <td>
                            <a use:link={{href: `/backstage/clients/${row.id}`, disabled: false}}>
                                {row.name}
                            </a>
                        </td>
                        <td> 
                            <a use:link={{href: `/backstage/clients/${row.id}`, disabled: false}}>
                                {row.mobile}
                            </a>
                        </td>
                        <td> 
                            <a use:link={{href: `/backstage/clients/${row.id}`, disabled: false}}>
                                {row.gender}
                            </a>
                        </td>
                        <td> 
                            <a use:link={{href: `/backstage/clients/${row.id}`, disabled: false}}>
                                {row.area}
                            </a>
                        </td>
                        <td> 
                            <a use:link={{href: `/backstage/clients/${row.id}`, disabled: false}}>
                                {row.note}
                            </a>
                        </td>
                        <td>
                            {#if row.active}
                                <Button
                                    style=""
                                    hoverColor="#58b957"
                                    props= ''
                                    bgColor = '#58b957'
                                    color= "#FFFFFF"
                                    hoverTextColor="#fff"
                                    on:click={changeStatus(row.id)}
                                    >
                                    啟用
                                </Button>
                                {:else}
                                <Button
                                    style=""
                                    hoverColor="#f66354"
                                    props=''
                                    bgColor = '#f66354'
                                    color= "#FFFFFF"
                                    hoverTextColor="#fff"
                                    on:click={changeStatus(row.id)}   
                                    >
                                    停用
                                </Button>
                            {/if}  
                        </td>
                    </tr>
                    {/each}
                {/if}
                </tbody>
            </Datatable>
        </div>
    </div>
</div> 
 

<style lang="scss">
    @import './../../components/scss/_font.scss';
    @import './../../components/scss/_reset.scss';
    @import './../../components/scss/_datatablesCss.scss';

    .title{
        display: inline-block;
        padding: 0.5rem;
        h2{
            font-size: 25px;
            font-weight: 500;
            letter-spacing: 1px;
            color: #425271;
            position: relative;
            margin: auto 0 15px 0;
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: #960E1E;
                bottom: -3px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    .new{
        display: inline-flex;
        justify-content: flex-end;
        width: calc(100% - 125px);
    }

    .search{
        padding: 0.5rem;
        &__con{
            display: inline-flex;
            align-items: center;
        }
        &__item{
            margin: 20px 0;
            > div{
                display: flex;
                flex-wrap: nowrap;
            }
            > p{
               white-space: nowrap;
            }
            input{
                width: 200px;
                margin-right: 20px;
            }
        }
        &--button{
            display: flex;
        }
    }
    
    //表單
    .table{
        padding: 0;
    }
    table, th, td {
        border-collapse: collapse;
        border-bottom: 1px solid #ece9e9;
    }
    th {
        padding: 8px 0px 8px 0px;
        text-align: center;
        border-bottom: 1px solid #b9b3b3;
        background: #fff;
    }
    th.sortable {
        cursor: pointer;
    }
    th.sortable span {
        padding-right: 16px;
        position: relative;
    }
    th.sortable span:before,
    th.sortable span:after {
        border: 4px solid transparent;
        content: '';
        display: block;
        height: 0;
        right: 0;
        top: 50%;
        position: absolute;
        width: 0;
    }
    th.sortable span:before {
        border-bottom-color: #e0e0e0;
        margin-top: -9px;
    }
    th.sortable span:after {
        border-top-color: #e0e0e0;
        margin-top: 1px;
    }
    th.sortable.asc:not(.desc) span:before {
        border-bottom-color: #9e9e9e;
    }
    th.sortable.desc:not(.asc) span:after {
        border-top-color: #9e9e9e;
    }

    td{text-align:center;padding:4px 0; word-break: break-all; }

    td a{
        color: #000;
        transition: 0.3s;
        display: inline-flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        line-height: 70px;
        &:hover{
            color: rgb(143, 142, 142);
        }
    }
</style>
