<script>
    import { onMount } from "svelte";
    import axios from "axios";
    export let params = {};
    import SveltyPicker from "svelty-picker";
    import Button from "./../../components/Input/Button.svelte";
    import { config } from "svelty-picker";
    import MessageError from "./../../components/Lightbox/_messageError.svelte";
    import MessageSuccess from "./../../components/Lightbox/_messageSuccess.svelte";
    import Black from "./../../components/Lightbox/_black.svelte";
    import { link, push } from "svelte-spa-router";

    config.todayBtn = false;
    config.clearBtn = false;

    let userLineId;
    let userPermission = {
        orders: "",
        business_records: "",
        shifts: "",
        clients: "",
        holidays: "",
        dayoff: "",
        roles: "",
        users: "",
    };

    let message = "";
    let key1 = [];

    // 分店
    let storeItems = [];
    let storeSelect = 1;

    let weekDays = [
        { id: 1, day: "週一", name: "週一" },
        { id: 2, day: "週二", name: "週二" },
        { id: 3, day: "週三", name: "週三" },
        { id: 4, day: "週四", name: "週四" },
        { id: 5, day: "週五", name: "週五" },
        { id: 6, day: "週六", name: "週六" },
        { id: 7, day: "週日", name: "週日" },
    ];

    let chooseWeekDays = [];

    let years = [
        { id: 1, year: "2022" },
        { id: 2, year: "2023" },
        { id: 3, year: "2024" },
        { id: 4, year: "2025" },
        { id: 5, year: "2026" },
        { id: 6, year: "2027" },
        { id: 7, year: "2028" },
        { id: 8, year: "2029" },
        { id: 9, year: "2030" },
        { id: 10, year: "2031" },
        { id: 11, year: "2032" },
        { id: 12, year: "2033" },
        { id: 13, year: "2034" },
        { id: 14, year: "2035" },
        { id: 15, year: "2036" },
        { id: 16, year: "2037" },
        { id: 17, year: "2038" },
        { id: 18, year: "2039" },
        { id: 19, year: "2040" },
        { id: 20, year: "2041" },
    ];

    let chooseYear = [];
    let chooseYear_week = [];
    let initYear = [];

    let yearDayOffs = [];

    let times = [
        { id: 1, period: "全天" },
        { id: 2, period: "上午" },
        { id: 3, period: "下午" },
        { id: 4, period: "晚上" },
    ];

    $: startDate = `${chooseYear}-01-01`;
    $: endDate = `${chooseYear}-12-31`;

    onMount(async () => {
        userLineId = getCookie("userLineId");
        if (userLineId == null || userLineId == "") {
            push("/backstage/orders/calendar");
        } else {
            if (
                getCookie("userPermission") !== null &&
                getCookie("userPermission") !== ""
            ) {
                userPermission = JSON.parse(getCookie("userPermission"));
                if (
                    userPermission.holidays == 0 &&
                    userPermission.dayoff == 0
                ) {
                    push("/backstage/orders/calendar");
                }
            } else {
                push("/backstage/orders/calendar");
            }
        }

        // 初始化年度為當年
        let date = new Date();
        chooseYear = date.getFullYear().toString();
        chooseYear_week = date.getFullYear().toString();
        initYear = date.getFullYear().toString();

        await axios({
            method: "get",
            url: env.BASE_URL + "api/v1/dayoff",
        })
            .then(function (res) {
                chooseWeekDays = res.data.dayoff;
                key1 = Object.entries(chooseWeekDays);
            })
            .catch(function (err) {
                console.dir(err);
            });

        //店家列表
        await axios({
            method: "get",
            url: env.BASE_URL + "api/v1/branches",
            // headers:{},
        })
            .then(function (res) {
                storeItems = res.data;
                for (let l = 0; l < storeItems.length; l++) {
                    storeItems[l].weekDay = key1[l][1];
                }
            })
            .catch(function (err) {
                console.dir(err);
            });

        axios({
            method: "get",
            url: env.BASE_URL + `api/v1/holidays/${chooseYear}/${storeSelect}`,
        })
            .then(function (res) {
                // console.log(res.data)
                if (res.data.length !== 0) {
                    yearDayOffs = res.data;
                    for (let l = 0; l < yearDayOffs.length; l++) {
                        yearDayOffs[l].id = l + 1;
                    }
                } else {
                    yearDayOffs = blank;
                }
            })
            .catch(function (err) {
                console.dir(err);
            });
    });

    function getCookie(name) {
        var cookieArr = document.cookie.split(";");
        for (var i = 0; i < cookieArr.length; i++) {
            var cookiePair = cookieArr[i].split("=");
            if (name == cookiePair[0].trim()) {
                return decodeURIComponent(cookiePair[1]);
            }
        }
        return null;
    }

    function itemDelete(n) {
        for (let i = 0; i < yearDayOffs.length; i++) {
            if (yearDayOffs[i].id == n) {
                yearDayOffs = [
                    ...yearDayOffs.slice(0, i),
                    ...yearDayOffs.slice(i + 1),
                ];
            }
        }
    }

    function saveWeekDayOff(store, select) {
        axios({
            method: "put",
            url: env.BASE_URL + `api/v1/dayoff?b=${store}&y=${chooseYear_week}`,
            data: {
                dayoff: select,
            },
        })
            .then(function (res) {
                console.log(res)
                if (res.data.status == "successful") {
                    message = res.data.message;
                    success();
                } else {
                    message = res.data.message;
                    fail();
                }
            })
            .catch(function (err) {
                console.dir(err);
            });
    }

    function change() {
        axios({
            method: "get",
            url: env.BASE_URL + `api/v1/holidays/${chooseYear}/${storeSelect}`,
        })
            .then(function (res) {
                if (res.data.length !== 0) {
                    yearDayOffs = res.data;
                } else {
                    yearDayOffs = [];
                    for (let i = 0; i < 5; i++) {
                        if (yearDayOffs.length == 0) {
                            yearDayOffs.push({
                                id: 1,
                                day: `${chooseYear}-01-01`,
                                branch_id: storeSelect,
                                period: "全天",
                                off_type: "國定假日",
                                note: "",
                            });
                        } else {
                            yearDayOffs.push({
                                id: yearDayOffs.length + 1,
                                day: `${chooseYear}-01-01`,
                                branch_id: storeSelect,
                                period: "全天",
                                off_type: "國定假日",
                                note: "",
                            });
                        }
                    }
                    yearDayOffs = [...yearDayOffs];
                }
            })
            .catch(function (err) {
                console.dir(err);
            });
    }

    function saveHolidays() {
        let err = [];
        let arr = new Set();
        try {
            for (let l = 0; l < yearDayOffs.length; l++) {
                // if (yearDayOffs[l].off_type.replaceAll(" ", "") == "") {
                //     err.push(l);
                // }
                yearDayOffs.forEach((a) => arr.add(yearDayOffs[l].day));
            }
        } finally {
            if (err.length > 0) {
                message = "名稱不得為空！";
                fail();
            } else if (arr.size !== yearDayOffs.length) {
                message = "日期不得重複！";
                fail();
            } else {
                axios({
                    method: "put",
                    url:
                        env.BASE_URL +
                        `api/v1/holidays/${chooseYear}/${storeSelect}`,
                    data: {
                        holidays: yearDayOffs,
                    },
                })
                    .then(function (res) {
                        if (res.data.status == "successful") {
                            message = "修改成功！";
                            success();
                        } else {
                            message = res.data.message;
                            fail();
                        }
                    })
                    .catch(function (err) {
                        console.dir(err);
                    });
            }
        }
    }

    function newHoliday() {
        if (yearDayOffs.length == 0) {
            yearDayOffs.push({
                id: 1,
                day: `${chooseYear}-01-01`,
                branch_id: storeSelect,
                period: "全天",
                off_type: "國定假日",
                note: "",
            });
        } else {
            yearDayOffs.push({
                id: yearDayOffs[yearDayOffs.length - 1].id + 1,
                day: `${chooseYear}-01-01`,
                branch_id: storeSelect,
                period: "全天",
                off_type: "國定假日",
                note: "",
            });
        }
        yearDayOffs = [...yearDayOffs];
        // console.log(yearDayOffs)
    }

    function success() {
        document.querySelector(".black").style.display = "block";
        document.querySelector(".lightbox--messageSuccess").style.display =
            "inline-block";
        setTimeout(function () {
            document.querySelector(".lightbox--messageSuccess").style.display =
                "none";
            document.querySelector(".black").style.display = "none";
        }, 2000);
    }

    function fail() {
        document.querySelector(".lightbox--messageError").style.display =
            "inline-block";
        document.querySelector(".black").style.display = "block";
    }

    function closeLightbox() {
        document.querySelector(".lightbox--messageError").style.display =
            "none";
        document.querySelector(".black").style.display = "none";
    }
</script>

<div class="title">
    <h2>假日/公休管理</h2>
</div>

{#if userPermission !== null && userPermission.dayoff !== 0}
    <div class="weekDayOff">
        <p>每週公休：</p>
        <div style="margin: 15px 0;">
            <p style="width: 48px">年度：</p>
            <select name="year" id="year" bind:value={chooseYear_week}>
                {#each years as year, i}
                    {#if year.year <= parseInt(initYear) + 1}
                        <option value={year.year} id={year.day}
                            >&nbsp;{year.year}</option
                        >
                    {/if}
                {/each}
            </select>
        </div>
        <div>
            {#each storeItems as item, j}
                <div>
                    <p>{storeItems[j].branch} ：</p>
                    <div>
                        {#each weekDays as weekDay, i}
                            <input
                                type="checkbox"
                                name={`day_${j}`}
                                value={weekDay.day}
                                id={`${weekDay.day}${j}`}
                                bind:group={storeItems[j].weekDay}
                            /><label for={`${weekDay.day}${j}`}
                                >&nbsp;{weekDay.name}</label
                            >&nbsp;&nbsp;&nbsp;&nbsp;
                        {/each}
                        {#if userPermission.dayoff == 2}
                            <Button
                                hoverColor="#44abf1"
                                props=""
                                color="#fff"
                                bgColor="#44abf1"
                                hoverTextColor="#fff"
                                on:click={saveWeekDayOff(
                                    storeItems[j].branch_id,
                                    storeItems[j].weekDay
                                )}
                            >
                                儲存
                            </Button>
                        {/if}
                    </div>
                </div>
            {/each}
        </div>
    </div>
    <hr />
{/if}

{#if userPermission !== null && userPermission.holidays !== 0}
    <div class="yearDayOff">
        <div>
            <p>年度：</p>
            <select
                name="year"
                id="year"
                bind:value={chooseYear}
                on:change={change}
            >
                {#each years as year, i}
                    {#if year.year <= parseInt(initYear) + 1}
                        <option value={year.year} id={year.day}
                            >&nbsp;{year.year}</option
                        >
                    {/if}
                {/each}
            </select>
        </div>
        <br />
        <div>
            <p>分店：</p>
            <select
                name="store"
                id="store"
                bind:value={storeSelect}
                on:change={change}
            >
                {#each storeItems as storeItem, i}
                    <option value={storeItem.branch_id} id={storeItem.branch}
                        >&nbsp;{storeItem.branch}</option
                    >
                {/each}
            </select>
        </div>
    </div>
    <table>
        <tr>
            <th>日期</th>
            <th>時段</th>
            <!-- <th>名稱</th> -->
            <th>備註（休假說明，例如：元旦）</th>
            <th />
        </tr>
        {#each yearDayOffs as yearDayOff}
            <tr>
                <td style="width: 200px;"
                    ><SveltyPicker
                        format="yyyy-mm-dd"
                        bind:value={yearDayOff.day}
                        {startDate}
                        {endDate}
                    /></td
                >
                <td>
                    <select
                        name="time"
                        id="time"
                        bind:value={yearDayOff.period}
                    >
                        {#each times as time, i}
                            <option value={time.period} id={time.period}
                                >&nbsp;{time.period}</option
                            >
                        {/each}
                    </select>
                </td>
                <!-- <td><input type="text" bind:value={yearDayOff.off_type} /></td> -->
                <td><input type="text" bind:value={yearDayOff.note} /></td>
                <td style="text-align: center;">
                    <Button
                        style=""
                        hoverColor="#f66354"
                        props=""
                        bgColor="#f66354"
                        color="#FFFFFF"
                        hoverTextColor="#fff"
                        on:click={itemDelete(yearDayOff.id)}
                    >
                        刪除
                    </Button>
                </td>
            </tr>
        {/each}
    </table>
    <div class="btn btn--add">
        <Button
            style="margin:10px 0"
            hoverColor="#58b957"
            props=""
            bgColor="#58b957"
            color="#FFFFFF"
            hoverTextColor="#fff"
            on:click={newHoliday}
        >
            ＋
        </Button>
    </div>

    <hr />

    {#if userPermission !== null && userPermission.holidays == 2}
        <div class="btn btn--save">
            <Button
                hoverColor="#44abf1"
                props=""
                color="#fff"
                bgColor="#44abf1"
                hoverTextColor="#fff"
                on:click={saveHolidays}
            >
                儲存
            </Button>
        </div>
    {/if}
{/if}

<MessageSuccess {message} />
<MessageError {message} on:close={closeLightbox} />
<Black on:black={closeLightbox} />

<style lang="scss">
    @import "./../../components/scss/_font.scss";
    @import "./../../components/scss/_reset.scss";

    .title {
        max-width: 1100px;
        h2 {
            font-size: 25px;
            font-weight: 500;
            letter-spacing: 1px;
            color: #425271;
            position: relative;
            margin: auto 0 15px 0;
            display: inline-block;
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                background-color: #960e1e;
                bottom: -3px;
                left: 80px;
                transform: translateX(-50%);
            }
        }
    }
    hr {
        margin: 20px 0 20px;
        opacity: 0.6;
    }
    .weekDayOff {
        display: flex;
        max-width: 1100px;
        margin: 0px 0 20px;
        align-items: center;
        flex-wrap: wrap;
        > p {
            margin-right: 10px;
            display: inline-flex;
            margin-top: 30px;
            margin-bottom: 10px;
            font-weight: 600;
        }
        > div {
            display: inline-flex;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            input {
                margin: 0 0 0 0;
            }
            select {
                width: 200px;
                height: 35.8px;
            }
            p {
                margin-right: 10px;
                display: inline-flex;
                width: 120px;
            }
            div {
                width: 100%;
                display: inline-flex;
                align-items: center;
            }
        }
    }
    .yearDayOff {
        display: flex;
        margin: 40px 0 60px;
        max-width: 1100px;
        > div {
            display: flex;
            align-items: center;
            margin-right: 20px;
            select {
                width: 200px;
                height: 35.8px;
                margin-left: 10px;
            }
        }
    }
    table {
        width: 100%;
        margin: auto;
        select {
            height: 35.8px;
            width: 100%;
            min-width: 80px;
        }
        th {
            padding: 10px 5px;
            font-weight: 600;
            border-bottom: 1px solid #aaa;
        }
        td {
            padding: 0 5px;
        }
        input {
            width: 100%;
        }
    }

    .btn {
        width: 100%;
        &--add {
            display: flex;
        }
        &--save {
            display: flex;
            justify-content: flex-end;
            padding: 0 15px;
        }
    }
</style>
